const dictionaryPath = 'expenses.addEditExpense.info.orderDetails';

const extraFieldsConfig = {
    fields: [
        {
            name: 'vendorNotes',
            inputType: 'text',
            title: `${dictionaryPath}.vendorNotes`,
            fullWidth: true
        },
        {
            name: 'internalNotes',
            inputType: 'text',
            title: `${dictionaryPath}.internalNotes`,
            fullWidth: true
        },
        {
            name: 'status',
            inputType: 'select',
            title: `${dictionaryPath}.status`,
            // inputOptionsVar: 'status'
        }
    ]
};

const expenseOrderItemConfig = {
    fields: [
        {
            name: 'classification',
            inputType: 'text',
            title: `${dictionaryPath}.classification`,
            size: 'medium'
        },
        {
            name: 'details',
            inputType: 'text',
            title: `${dictionaryPath}.details`,
            size: 'medium'
        },
        {
            name: 'quantity',
            inputType: 'number',
            valueType: 'number',
            title: `${dictionaryPath}.quantity`,
            size: 'small'
        },
        {
            name: 'pricePerUnit',
            inputType: 'number',
            valueType: 'number',
            title: `${dictionaryPath}.pricePerUnit`,
            size: 'large'
        }
    ]
};

const expenseAddEditOrderConfig = {
    extraFieldsConfig,
    expenseOrderItemConfig,
    VAT: 17
};

export default expenseAddEditOrderConfig;
