<div class="authorized-driver-body-container">
  <div class="authorized-driver-body-header-container">
    <span class="u-text-line u-text-line_border-off authorized-driver-body-title ctrl-section-title">
      {{dictionaryPath + '.title' | translate}}
    </span>
    <span class="u-btn-simple add-new-btn"
      (click)="addNewAuthorizedDriver()">{{dictionaryPath + '.addNew' | translate}}</span>
  </div>
  <div class="authorized-driver-control"
       *ngFor="let authorizedDriverForm of formObject.controls; let i=index;">
    <u-group class="authorized-driver-field" *ngIf="authorizedDriverForm"
             [name]="dictionaryPath + '.name' | translate">
      <u-select-s [items]="drivers" [formControl]="authorizedDriverForm" [valid]="authorizedDriverForm.valid"
                  [emptyName]="'general.select' | translate"
                  [emptyHidden]="true"
                  [emptyValue]="null"
                  [emptyRemove]="null"
                  [messages]="'uSelectS' | translate"
                  [placement]="localizationService.isRtl() ? ['bottom-right', 'bottom-left' , 'top-right' , 'top-left' ] :['bottom-left', 'bottom-right' , 'top-left' , 'top-right' ]">
      </u-select-s>
      <div class="authorized-driver__actions">
        <button class="u-btn-simple u-btn-small icon-btn" (click)="removeAuthorizedDriver(i)">
          <span class="icon delete-icon"></span>
        </button>
      </div>
    </u-group>
  </div>
</div>
