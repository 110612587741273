import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { LocalizationService } from '../../../shared/services';
import { FormControl } from '@angular/forms';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-customer-role-switch',
    templateUrl: './customer-role-switch.component.html',
    styleUrls: ['./customer-role-switch.component.scss', './customer-role-switch.component.rtl.scss']
})

export class CustomerRoleSwitchComponent implements OnInit {
    @select(['auth', 'user'])
    readonly userInfoObservable: Observable<any>;

    @Output() action = new EventEmitter();

    userInfo: any;
    isRtl: boolean;
    customersList: any[];
    rolesList: any[] = [];
    switchUserLists: any[];
    selectedRole: FormControl;
    selectedCustomer: FormControl;

    constructor(
    public bsModalRef: BsModalRef,
    public localizationService: LocalizationService,
  ) {
        this.isRtl = this.localizationService.isRtl();
    }

    ngOnInit(): void {
        this.initForms();
        this.fetchUserInfo();
        this.initCustomers();
        this.onSelectCustomer();
    }

    initForms(): void {
        this.selectedRole = new FormControl(null);
        this.selectedCustomer = new FormControl(null);
    }

    fetchUserInfo(): void {
        this.userInfoObservable.subscribe(user => this.userInfo = user);
    }

    initCustomers(): void {
        this.customersList = this.switchUserLists.map(userInfo => userInfo.customer);
        this.selectedCustomer.patchValue(this.customersList.find(customer => customer.name === this.userInfo.customerName).value);

        this.setCurrentCustomer();
    }

    onSelectCustomer(): void {
        this.selectedCustomer.valueChanges.subscribe(() => this.setCurrentCustomer());
    }

    setCurrentCustomer(): void {
        const currentCustomer = this.switchUserLists.find(userInfo => userInfo.customer.value === this.selectedCustomer.value);
        this.initRoles(currentCustomer);
    }

    initRoles(currentCustomer): void {
        this.rolesList = currentCustomer.roles.map(role => ({
            name: role,
            value: role
        }));
        this.selectedRole.patchValue((this.rolesList.find(role => role.value.substring(role.value.indexOf('.') + 1) === this.userInfo.role) || this.rolesList[0]).value);
    }

    setRole(): void {
        this.bsModalRef.hide();
        this.action.emit({result: 'Ok', customerId: this.selectedCustomer.value, role: this.selectedRole.value});
    }

    closeForm(): void {
        this.bsModalRef.hide();
        this.action.emit({result: 'Cancel'});
    }
}
