import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {

    constructor() {}

  // Workaround for angular bug of key-value pipe wrong order
    asIsOrder(): number {
        return 1;
    }
}
