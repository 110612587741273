<div class="order-details-container">
  <div class="order-details-table-container">
    <div class="order-details-table-main">
      <div class="order-details-table-header">
        <div class="order-details-table-title title-{{field['size']}}"
          *ngFor="let field of expenseOrderItemConfig?.fields">
          <div class="order-details-table-title-content">
            <span>
              {{field['title']| translate}}
            </span>
          </div>
        </div>
        <div class="order-details-table-title title-large">
          <div class="order-details-table-title-content">
            <span>
              {{'expenses.addEditExpense.info.orderDetails.total'| translate}}
            </span>
          </div>
        </div>
      </div>
      <div class="order-details-table-items">
        <expense-order-item class="order-details-table-item"
          *ngFor="let item of orderItemListForm?.controls;let i=index;" [orderItemForm]="orderItemListForm?.controls[i]"
          (removeItemEvent)="removeOrderItem(i)" (addItemEvent)="addOrderItem()"></expense-order-item>
      </div>
    </div>
    <expense-order-summary class="order-details-table-summary"></expense-order-summary>
  </div>
  <div class="order-details-fields">
    <div class="order-details-field" *ngFor="let field of expenseAddEditOrderConfig.extraFieldsConfig['fields'];">
      <dynamic-field [formObject]="formObject" [field]="field"></dynamic-field>
    </div>
  </div>
</div>
