<div class="header-icons-block">
    <div class="logo logo_extra header__logo">
        <span class="logo__name">Ctrl</span>
    </div>
    <div class="header-icons-block-left">
        <div class="header-icons-block-left__item">
            <app-add-btn></app-add-btn>
            <button type="button" class="header-feed-btn" *ngIf="!isAdminUser && showFeed" (click)="navigateToAlerts()">
            </button>
        </div>
        <div class="header-search" *ngIf="showSearch">
            <input type="text" [(ngModel)]="searchQuery" placeholder="{{'header.search' | translate }}"
                class="header-search__input" (keyup)="onSearchKeyUp()">
        </div>
    </div>
    <div class="header-icons-block-right header-icons-block-date-time">
        <div class="header-icons-block-right-item">
            <p class="header-time">
                {{currentTime}}
            </p>
        </div>
        <div class="header-icons-block-left-item">
            <p class="header-date">
                {{currentDate | translate}}
            </p>
        </div>
    </div>
    <div class="header-icons-block-accessibility">
        <div class="header-icons-block-accessibility-item header-icons-block-accessibility-item-zoom"
            (clickOutside)="showAccessibility = false">
            <button type="button" (click)="showAccessibility = !showAccessibility" class="header-icons-block-zoom">
            </button>
            <div class="header-icons-block-zoom-popup popup-icon-menu"
                [ngClass]="{'show-header-icons-block-zoom-popup': showAccessibility}">
                <ul class="header-icons-block-zoom-popup-list">
                    <li class="header-icons-block-zoom-popup-list-item zoom-in-container"
                        [ngClass]="{'zoom-disabled': !scalingService.canZoomIn()}"
                        (click)="scalingService.zoomInInit()">
                        <span class="zoom-text zoom-in-text">
                            {{'header.enlarge' | translate }}
                        </span>
                        <span class="zoom-icon zoom-in-icon"></span>
                    </li>
                    <li class="header-icons-block-zoom-popup-list-item zoom-out-container"
                        [ngClass]="{'zoom-disabled': !scalingService.canZoomOut()}"
                        (click)="scalingService.zoomOutInit()">
                        <span class="zoom-text zoom-out-text">
                            {{'header.shrink' | translate }}
                        </span>
                        <span class="zoom-icon zoom-out-icon"></span>
                    </li>
                    <li class="header-icons-block-zoom-popup-list-item reset-zoom-container"
                        (click)="scalingService.defaultSizeInit()">
                        <span class="zoom-text reset-zoom-text">
                            {{'header.reset' | translate }}
                        </span>
                        <span class="zoom-icon reset-zoom-icon"></span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="header-icons-block-accessibility-item header-icons-block-accessibility-language"
            (clickOutside)="showLanguage = false">
            <button type="button" (click)="showLanguage = !showLanguage" class="header-icons-block-language">
            </button>
            <div class="header-icons-block-language-popup popup-icon-menu"
                [ngClass]="{'show-header-icons-block-language-popup': showLanguage}">
                <ul class="header-icons-block-language-popup-list">
                    <li class="header-icons-block-language-popup-list-item"
                        *ngFor="let lang of localizationService.languages | async">
                        <button type="button" (click)="changeLang(lang.code);showLanguage = false" [value]="lang.code"
                            class="header-icons-block-language-popup-list-item-btn"> {{ lang.name }}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<ng-progress [spinner]="false"
             [thick]="true"
             [fixed]="true"
             [color]="'rgba(52,58,64,0.65)'"
             [direction]="isRtl ? 'rtl+' : 'ltr+'" id="progressBar"></ng-progress>
