const dictionaryPath = 'general.duration';

const durationConfig = {
    years: {
        singleValue: `${dictionaryPath}.year`,
        pluralValue: `${dictionaryPath}.years`
    },
    months: {
        singleValue: `${dictionaryPath}.month`,
        pluralValue: `${dictionaryPath}.months`
    },
    days: {
        singleValue: `${dictionaryPath}.day`,
        pluralValue: `${dictionaryPath}.days`
    }
};

export default durationConfig;
