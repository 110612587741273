<div class="sidebar" [ngClass]="{'show-sidebar': isMenuCollapsed}">
    <div class="header-left-block" [ngClass]="{'open-sidebar': !isMenuCollapsed}">
        <div class="header-left-block-user">
            <button type="button"
                    class="toggle-menu-button"
                    (click)="toggleSidebar()">
            </button>
            <p class="user">{{customerName | async}}</p>
            <br>
        </div>
        <div class="header-left-block-category" (clickOutside)="showLogout = false">
            <div (click)="showLogout = !showLogout"
                 class="header-left-block-category__toggle">
                <div class="header-left-block-category-img">
                    <img src="../../../../assets/images/icons/app-side-menu/arrow-down-light.svg">
                </div>
                <p class="header-left-block-category-text">{{fullName | async}}</p>
            </div>
            <div class="sidebar-bottom-block-logout-popup"
                 [ngClass]="{'show-sidebar-bottom-popup': showLogout}">
                <ul>
                    <li>
                        <a (click)="showCustomerRoleSwitch($event)">{{'sidebar.switchRole' | translate }}</a>
                    </li>
                    <li>
                        <a (click)="logout()">{{'sidebar.logout' | translate }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <u-sidebar-menu class="sidebar-menu u-sidebar-menu"
                    [items]="menuItems"
                    [customNavigate]="true"
                    (navigate)="onNavigate($event)"
                    [collapsed]="isMenuCollapsed"
                    (menuToggle)="onMenuToggle($event)">
    </u-sidebar-menu>

    <div *ngIf="!isMenuCollapsed" class="sidebar-bottom-block">
        <div class="sidebar-bottom-block-btn" *ngIf="showSettings">
            <button type="button" class="sidebar-bottom-block-settings" (click)="openSettings()">
            </button>
        </div>
        <div class="sidebar-bottom-block-btn">
            <button type="button" class="sidebar-bottom-block-headphones sidebar-disabled-item">
            </button>
        </div>
        <div class="sidebar-bottom-block-btn">
            <a class="sidebar-bottom-block-help sidebar-disabled-item" [href]="helpLink" target="_blank"></a>
        </div>
        <div class="sidebar-bottom-block-btn">
            <a class="sidebar-bottom-block-remote sidebar-disabled-item" target="_blank"
               [href]="remoteLink"></a>
        </div>
    </div>
</div>
