const dictionaryPath = 'alerts.alertsFeed';

const alertsFeedConfig = {
    title: `${dictionaryPath}.title`,
    totalTasksLabel: `${dictionaryPath}.totalTasksLabel`,
    feedItems:
    {
        vehicle: `${dictionaryPath}.vehicle.title`,
        driver: `${dictionaryPath}.driver.title`
    }
};

export default alertsFeedConfig;
