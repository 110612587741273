import { Component, OnInit } from '@angular/core';
import { CustomersService } from '../../../shared/services';
import customersPageConfig from './customers-page.component.config';
import { BaseService } from 'src/app/shared/services/api/base.service';

@Component({
    selector: 'app-customers',
    templateUrl: './customers-page.component.html',
    styleUrls: ['./customers-page.component.scss', './customers-page.component.rtl.scss']
})
export class CustomersPageComponent implements OnInit {
    rows: any[] = [];
    editData: any = null;
    customersPageConfig = customersPageConfig;

    constructor(
      private customersService: CustomersService,
      private baseService: BaseService
    ) {
    }

    ngOnInit(): void {
        this.baseService.setPaths('customers');
        this.initCustomersList();
    }

    initCustomersList(): void {
        this.fetchCustomers();
        this.customersService.customersList.subscribe(customers => this.rows = customers);
    }

    fetchCustomers(): void {
        this.baseService.getAll().subscribe(customers => this.customersService.setCustomersList(customers));
    }
}
