<div class="table-meta-data" *ngIf="rows">
  <span class="num-rows" *ngIf="alertsPageConfig.tableConfig['counterLabel']">{{rows.length}} {{alertsPageConfig.tableConfig['counterLabel'] | translate}}</span>
  <span class="num-selected-rows"
        *ngIf="rows.length > 0">{{selectedRows.length}} {{'table.selected' | translate}}</span>
</div>
<u-split [disabled]="true" [useTransition]="true" [dir]="localizationService.getDirection()" direction="horizontal">
  <u-split-area class="u-split-area-layout alerts-updates-area" [size]="19" [minSize]="200" [order]="0" *ngIf="alertsFeedVisible"
                panelName="alertsFeed" titleText="{{alertsPageConfig.alertsFeed.title| translate}}" showTitle="true" (panelClose)="alertsFeedVisible=false">
    <alerts-feed></alerts-feed>
  </u-split-area>
  <u-split-area [size]="81" [minSize]="400" panelName="alertsTablePanel" class="alerts-table-area" [order]="1">
    <table-page class="alerts-table" [pageConfig]="alertsPageConfig" [rows]="rows" [displayTableMetaData]="false"></table-page>
  </u-split-area>
</u-split>
