<div class="vehicle-suppliers-container">
  <div class="vehicle-suppliers-header-container" *ngIf="vehicleAddEditSupplierConfig['title']">
    <span class="u-text-line u-text-line_border-off vehicle-suppliers-title">
      {{vehicleAddEditSupplierConfig['title'] | translate}} ({{this.formObject.controls.length}})
    </span>
    <span class="u-btn-simple add-new-btn"
      (click)="addNewSupplier()">{{'vehicles.addEditVehicle.generalDetails.suppliers.addNew' | translate}}</span>
  </div>

  <div class="vehicle-suppliers-table-container">
    <u-grid #suppliersTable
        class="u-grid_app-table"
            [rows]="tableRows"
            [columns]="tableColumns"
            [columnMode]="'force'"
            [headerHeight]="45"
            [headerOffset]="10"
            [footerHeight]="0"
            [rowHeight]="65"
            [isRtl]="localizationService.isRtl()"
            [reorderable]="true"
            [scrollbarV]="true"
            [scrollbarH]="false"
            [showColumnsFilter]="false"
            [messages]="'uGrid' | translate">
    </u-grid>
  </div>
</div>

<!-- ****** Cell Templates ****** -->
<ng-template #supplierNameCell ngx-datatable-cell-template let-cell="value" let-column="column">
  <u-select-s class="vehicle-suppliers-field"
              [items]="suppliers"
              [container]="'body'"
              [formControl]="cell" [valid]="cell.valid"
              [emptyName]="'general.select' | translate"
              [emptyHidden]="true"
              [emptyValue]="null"
              [emptyRemove]="null"
              [messages]="'uSelectS' | translate"
              [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']">
  </u-select-s>
</ng-template>

<ng-template #supplierTypeCell ngx-datatable-cell-template let-cell="value" let-column="column">
  <label class="supplier-type">{{cell.value}}</label>
</ng-template>

<ng-template #supplierDeleteCell ngx-datatable-cell-template let-rowIndex="rowIndex">
  <div class="table-row-actions">
    <button class="u-btn-simple u-btn-small icon-btn" (click)="removeSupplier(rowIndex)"
            title="{{'general.delete' | translate}}">
      <span class="icon delete-icon"></span>
    </button>
  </div>
</ng-template>
