import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseService } from 'src/app/shared/services/api/base.service';
import expenseAddEditModalConfig from './expense-add-edit.component.config';
import { AddEditModalService } from '../../../shared/modules/add-edit-modal/services/add-edit-modal.service';
import { MetadataService } from '../../../shared/services/metadata.service';
import { CtrlFormsService } from '../../../shared/services/ctrl-forms.service';

@Component({
    selector: 'expense-add-edit',
    templateUrl: './expense-add-edit.component.html',
    styleUrls: ['./expense-add-edit.component.scss', './expense-add-edit.component.rtl.scss']
})
export class ExpenseAddEditComponent implements OnInit {
    @Output() action = new EventEmitter();

    editData: any;
    addEditForm: FormGroup;
    modalConfig: any = expenseAddEditModalConfig;

    constructor(
      private baseService: BaseService,
      private ctrlFormsService: CtrlFormsService,
      private addEditModalService: AddEditModalService,
      private metadataService: MetadataService
    ) {}

    ngOnInit(): void {
        this.initAddEditForm();
        this.metadataService.setMetadataObs(this.modalConfig);
        this.baseService.setPaths('expenses');
        if (this.editData) {
            //TODO remove when api ready
            if (!expenseAddEditModalConfig.apiNotReady) {
                this.ctrlFormsService.patchValueNested(this.addEditForm, this.editData, this.modalConfig.fields);
            }
        }
    }

    initAddEditForm(): void {
        this.addEditForm = this.ctrlFormsService.generateModalTabsForm(this.modalConfig);
    }

    deleteExpense(): void {
        this.addEditModalService.deleteEntity('expenses.addEditExpense.messages.deleteConfirm', this.editData, this.action);
    }

    submitForm = (): void => {
        if (this.addEditModalService.canSubmit(this.addEditForm)) {
            this.addEditModalService.submitForm(this.addEditForm.value, this.action, this.editData);
        }
    };
}
