import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { LocalizationService } from '../../../shared/services';

@Component({
    selector: 'file-expiration-input',
    templateUrl: './file-expiration-input.component.html',
    styleUrls: ['./file-expiration-input.component.scss', './file-expiration-input.component.rtl.scss']
})
export class FileExpirationInputComponent {
    @Input() public fileType: string;
    @Input() public innerDelete: boolean;
    @Input() public outsideDelete: boolean | 'forced';
    @Input() public formControlObject: FormGroup;
    @Output() public removeEvent = new EventEmitter();

    public isRtl: boolean;

    constructor(private localizationService: LocalizationService) {
        this.isRtl = this.localizationService.isRtl();
    }

    isFileExpired(): boolean {
        const expirationDateValue = this.getFileExpirationDateFormControl().value;
        return expirationDateValue ? new Date(expirationDateValue) < new Date() : false;
    }

    getFileExpirationDateFormControl(): FormControl {
        return this.formControlObject.get('expirationDate') as FormControl;
    }

    removeFile(): void {
        this.removeEvent.emit(this.formControlObject);
    }
}
