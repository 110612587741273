import { Component, OnInit } from '@angular/core';
import vehiclesPageConfig from './vehicles-page.component.config';
import { BaseService } from '../../../shared/services/api/base.service';

@Component({
    selector: 'vehicles-page',
    templateUrl: './vehicles-page.component.html',
    styleUrls: ['./vehicles-page.component.scss', './vehicles-page.component.rtl.scss']
})
export class VehiclesPageComponent implements OnInit {
    rows:  any[] = [];
    vehiclesPageConfig: any = vehiclesPageConfig;

    constructor(private baseService: BaseService) { }

    ngOnInit(): void {
        this.baseService.setPaths('vehicles');
        this.fetchVehicles();
    }

    fetchVehicles(): void {
        this.baseService.getAll().subscribe(vehicles => this.rows = vehicles);
    }
}
