import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { AppSideMenuComponent } from './app-side-menu/app-side-menu.component';
import { AppTopMenuComponent } from './app-top-menu/app-top-menu.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UPopoverModule, USidebarMenuModule } from '@shift/ulib';
import { ScalingService } from './services/scaling.service';
import { ClickOutsideModule } from 'ng-click-outside';
import { AppSideMenuService } from './app-side-menu/app-side-menu.service';
import { AppAddBtnComponent } from './app-top-menu/app-add-btn/app-add-btn.component';
import { FormsModule } from '@angular/forms';
import { NgProgressModule } from '@ngx-progressbar/core';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        USidebarMenuModule,
        ClickOutsideModule,
        UPopoverModule,
        FormsModule,
        NgProgressModule
    ],
    declarations: [
        AppLayoutComponent,
        AppSideMenuComponent,
        AppTopMenuComponent,
        AppAddBtnComponent
    ],
    providers: [
        ScalingService,
        AppSideMenuService
    ]
})
export class AppLayoutModule { }
