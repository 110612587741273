const dictionaryPath = 'inspections.addEditInspection.summary';

const inspectionSummaryConfig = {
    totalPassed: {
        name: 'totalPassed',
        title: `${dictionaryPath}.totalPassed`
    },
    totalFailed: {
        name: 'totalFailed',
        title: `${dictionaryPath}.totalFailed`
    }
};

export default inspectionSummaryConfig;
