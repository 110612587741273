import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoginPageComponent } from './login-page/login-page.component';
import { UCommonModule } from '@shift/ulib';
import { AuthService } from './auth.service';
import { TokenService } from './token.service';
import { CustomerRoleSwitchComponent } from './customer-role-switch/customer-role-switch.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        UCommonModule
    ],
    declarations: [
        LoginPageComponent,
        CustomerRoleSwitchComponent
    ],
    entryComponents: [
        CustomerRoleSwitchComponent
    ],
    providers: [
        AuthService,
        TokenService
    ]
})

export class AuthModule {
}
