const dictionaryPath = 'settings.customer';

const fields = {
    documents: {
        name: 'documents',
        noParent: true,
        inputType: 'alert',
        arrayField: true
    }
};

const settingsAlertsCustomerConfig = {
    fields,
    title: `${dictionaryPath}.pageTitle`,
    columns: {
        documents: {
            isArray: true,
            showCounter: true,
            title: `${dictionaryPath}.documentAlerts`,
            fields: [fields.documents]
        }
    }
};

export default settingsAlertsCustomerConfig;
