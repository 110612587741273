import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsPageComponent } from './reports-page/reports-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UGridModule, UCommonModule } from '@shift/ulib';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MonthlySummaryChartComponent } from './monthly-summary-chart/monthly-summary-chart.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        NgxChartsModule,
        UGridModule,
        SharedModule,
        UCommonModule,
        ReactiveFormsModule,
        ChartsModule,
        NgxDatatableModule
    ],
    declarations: [
        ReportsPageComponent,
        MonthlySummaryChartComponent
    ]
})
export class ReportsModule {
}
