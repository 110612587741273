const dictionaryPath = 'general.files';

const filesConfig = {
    image: {
        maxSizeMB: 10,
        allowedFormats: '.png, .jpg, .jpeg',
        attachLabel: `${dictionaryPath}.attachPicture`
    },
    other: {
        maxSizeMB: 10,
        allowedFormats: '.rtf, .doc, .docx, .pdf, .xls, .xlsx, .png, .jpg, .jpeg',
        attachLabel: `${dictionaryPath}.attachFile`
    },
    invalidSizeError: `${dictionaryPath}.invalidSize`,
    invalidFormatError: `${dictionaryPath}.invalidFormat`
};

export default filesConfig;
