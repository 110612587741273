import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FileInputService } from '../file-input/file-input.service';
import { LocalizationService } from '../../../shared/services';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import filesConfig from '../file-input/file-input.component.config';
import { UPopupService } from '@shift/ulib';
import { ModalDirective } from 'ngx-bootstrap';
import { BaseService } from '../../../shared/services/api/base.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';

@Component({
    selector: 'files-images-input',
    templateUrl: './files-images-input.component.html',
    styleUrls: ['./files-images-input.component.scss', 'files-images-input.component.rtl.scss'],
    providers: [FileInputService]
})
export class FilesImagesInputComponent implements OnInit {
    @Input() public readonly: boolean;
    @Input() public imagesForm: FormArray;
    @Input() public documentsForm: FormArray;
    @ViewChild('imageFileInput') imageFileInput: ElementRef;
    @ViewChild('documentFileInput') documentFileInput: ElementRef;
    @ViewChild('expandImageModal') public expandImageModal: ModalDirective;

    expandImageObject;
    fileConfig = filesConfig;

    private MAX_FILES = 5;
    private progressRef: NgProgressRef;

    constructor(public fileInputService: FileInputService, private localizationService: LocalizationService,
                private popupService: UPopupService, private baseService: BaseService, private sanitizer: DomSanitizer, private progress: NgProgress) {
        this.progressRef = this.progress.ref('progressBar');
    }

    ngOnInit(): void {
        this.fileInputService.setFileConfig();
    }

    isRtl(): boolean {
        return this.localizationService.isRtl();
    }

    fileChangeEvent(fileInput: any, fileType: 'images' | 'documents', form: FormArray): void {
        const files = fileInput.target.files;
        if (files.length + form.value.length > this.MAX_FILES) {
            this.tooManyFilesError(fileType);
        } else if (files) {
            this.fileInputService.loadMultipleFiles(Array.from(files), form, fileType)
              .then(() => this.setFormControlValue(form));
        }
    }

    tooManyFilesError(fileType: 'images' | 'documents'): void {
        this.popupService.showErrors({
            title: 'general.error',
            ok: 'general.close',
            errors: [{message: 'general.files.tooManyFiles'}]
        }, () => {
            if (fileType === 'images') {
                this.uploadImage();
            } else {
                this.uploadDocument();
            }
        });
    }

    removeFile(form: FormArray, index: number): void {
        form.removeAt(index);
        this.setFormControlValue(form);
    }

    setFormControlValue(form: FormArray): void {
        form.markAsDirty();
        form.updateValueAndValidity();
    }

    uploadImage(): void {
        this.clearFileInput(this.imageFileInput);
        this.fileInputService.setFileConfig('image');
        this.imageFileInput.nativeElement.click();
    }

    uploadDocument(): void {
        this.clearFileInput(this.documentFileInput);
        this.fileInputService.setFileConfig();
        this.documentFileInput.nativeElement.click();
    }

    clearFileInput(fileInput: ElementRef): void {
        fileInput.nativeElement.value = '';

        if (!/safari/i.test(navigator.userAgent)) {
            fileInput.nativeElement.type = '';
            fileInput.nativeElement.type = 'file';
        }
    }

    downloadFile(file: any): void {
        this.fileInputService.downloadFile(file.fileId, file.fileName);
    }

    downloadImage(image: any = this.expandImageObject): void {
        saveAs(image.file, image.fileName);
    }

    expandImage(image: any): void {
        this.progressRef.start();
        this.expandImageObject = image;
        this.baseService.downloadFile(image.fileId).subscribe(file => {
            this.expandImageObject.file = file;
            this.expandImageObject.fileSrc = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
            this.expandImageModal.show();
            this.progressRef.complete();
        },
          () => this.progressRef.complete());
    }

    fetchThumbnails(): void {
        this.imagesForm.controls.forEach((image) => {
            if (!image.value.fileSrc) {
                this.baseService.downloadThumbnail(image.value.fileId)
                  .subscribe(thumbnail => {
                      (image as FormGroup).setControl('fileSrc', new FormControl(this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(thumbnail))));
                  });
            }
        });
    }
}
