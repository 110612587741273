import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { LocalizationService } from 'src/app/shared';
import { MetadataService } from '../../../../shared/services/metadata.service';

@Component({
    selector: 'vehicle-add-edit-authorized-drivers',
    templateUrl: './vehicle-add-edit-authorized-drivers.component.html',
    styleUrls: ['./vehicle-add-edit-authorized-drivers.component.scss', './vehicle-add-edit-authorized-drivers.component.rtl.scss']
})
export class VehicleAddEditAuthorizedDriversComponent implements OnInit {
    formObject: FormArray;
    drivers: { name: string, value: number }[] = [];
    dictionaryPath = 'vehicles.addEditVehicle.generalDetails.generalDetails.authorizedDrivers';

    constructor(public localizationService: LocalizationService, private metadataService: MetadataService) { }

    ngOnInit(): void {
        this.setDrivers();
    }

    private setDrivers(): void {
        if (this.drivers.length === 0) {
            this.metadataService.metadataObsObj['drivers'].subscribe(drivers => this.drivers = drivers);
        }
    }

    removeAuthorizedDriver(index: number): void {
        this.formObject.removeAt(index);
    }

    addNewAuthorizedDriver(): void {
        this.formObject.push(new FormControl(''));
    }
}
