const dictionaryPath = 'contactDetails';

const contactGroupsInputConfig = {
    dictionaryPath,
    title: `${dictionaryPath}.title`,
    buttons: {
        addNewContact: `${dictionaryPath}.addNewContact`,
    },
    fields: {
        contactInfo: {
            name: 'contact',
            title: `${dictionaryPath}.contact`,
            inputs: [
                {
                    name: 'name',
                    inputType: 'text',
                    required: true,
                    placeholder: `${dictionaryPath}.name`
                },
                {
                    name: 'role',
                    inputType: 'text',
                    required: true,
                    placeholder: `${dictionaryPath}.role`
                }
            ]
        }
    }
};

export default contactGroupsInputConfig;
