import { Component, OnInit } from '@angular/core';
import { GlobalSearchService, LocalizationService } from '../../../shared/services';
import { ScalingService } from '../services/scaling.service';
import { interval, Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import appTopMenuConfig from './app-top-menu.component.config';
import appConfig from '../../../app.config';
import { select } from '@angular-redux/store';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-top-menu',
    templateUrl: './app-top-menu.component.html',
    styleUrls: ['./app-top-menu.component.scss', './app-top-menu.component.rtl.scss']
})
export class AppTopMenuComponent implements OnInit {
    @select(['auth', 'user', 'role'])
    public readonly userRole: Observable<any>;

    isRtl: boolean;
    showFeed: boolean;
    currentTime: string;
    currentDate: string;
    searchQuery: string;
    isAdminUser: boolean;
    showLanguage: boolean;
    showSearch = true;
    showAccessibility: boolean;

    private searchSubject: Subject<string> = new Subject();

    constructor(private router: Router,
        public scalingService: ScalingService,
        private activatedRoute: ActivatedRoute,
        public localizationService: LocalizationService,
        private globalSearchService: GlobalSearchService) {
    }

    ngOnInit(): void {
        this.isRtl = this.localizationService.isRtl();

        this.onRouteChange();
        this.setCurrentTime();
        this.setCurrentDate();
        this.setUserRole();
        this.subscribeForSearchQuery();
    }

    onRouteChange(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => this.toggleItemsVisibility());
    }

    toggleItemsVisibility(): void {
        let child = this.activatedRoute;
        while (child.firstChild) {
            child = child.firstChild;
        }
        this.searchQuery = '';
        this.globalSearchService.searchQuery.next(this.searchQuery);
        this.showSearch = child.snapshot.data.showSearch !== false;
        this.showFeed = child.snapshot.data.showFeed !== false;
    }

    setUserRole(): void {
        this.userRole.subscribe(userRole => this.isAdminUser = userRole && userRole.toLowerCase() === appConfig.userRoles.admin.toLowerCase());
    }

    setCurrentTime(): void {
        interval(appTopMenuConfig.timerInterval).subscribe(() =>
            this.currentTime = moment().format(appConfig.timeFormat));
    }

    setCurrentDate(): void {
        this.currentDate = moment().format(appTopMenuConfig.headerDateFormat);
    }

    onSearchKeyUp(): void {
        this.searchSubject.next(this.searchQuery);
    }

    subscribeForSearchQuery(): void {
        this.searchSubject.debounceTime(125).subscribe((searchQuery: string) =>
          this.globalSearchService.updateFilter(searchQuery && searchQuery.length >= 3 ? searchQuery : ''));
    }

    changeLang(lang): void {
        this.localizationService.setLanguage(lang);
    }

    navigateToAlerts(): void {
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl(appTopMenuConfig.alertsUrl);
    }
}
