import { LanguagesActions } from './languages.actions';
import { Languages } from './languages.model';

const INITIAL_STATE: Languages = {
    items: []
};

export function createLanguagesAPIReducer() {
    return function authReducer(state: Languages = INITIAL_STATE, action: any): Languages {
        if (action.type === LanguagesActions.LANGUAGES_INIT) {
            return {
                ...state,
                items: action.items
            };
        }

        return state;
    };
}
