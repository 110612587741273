import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationService } from '../../../shared/services';
import reportsPageComponentConfig from './reports-page.component.config';
import { ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import * as Chart from 'chart.js';
import { BaseService } from '../../../shared/services/api/base.service';
import { Observable } from 'rxjs';
import { CommonService } from '../../../shared/services/common.service';
import cloneDeep from 'lodash/cloneDeep'

@Component({
    selector: 'app-reports',
    templateUrl: './reports-page.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./reports-page.component.scss', './reports-page.component.rtl.scss']
})
export class ReportsPageComponent implements OnInit {
    @ViewChild('normalCell') public normalCell: TemplateRef<any>;
    @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;

    isLoading = true;
    reportsPageComponentConfig = reportsPageComponentConfig;
    chartsConfig = cloneDeep(reportsPageComponentConfig.pieChart.charts);

    pieChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            position: this.localizationService.isRtl() ? 'right' : 'left',
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    const chartKey = ctx.chart.canvas.getAttribute('chartKey');
                    if (chartKey && this.chartsConfig[chartKey].percentageMode) {
                        return this.chartsConfig[chartKey].dataPercentages[ctx.dataIndex] + '%';
                    }
                    return value;
                },
                color: 'white',
                align: this.localizationService.isRtl() ? 'right' : 'center'
            }
        }
    };

    constructor(private baseService: BaseService, public translate: TranslateService, public localizationService: LocalizationService, public commonService: CommonService) {}

    ngOnInit(): void {
        this.baseService.setPaths('reports');
        this.fetchChartsData();
        this.onLangChange();
    }

    fetchChartsData(): void {
        Observable.forkJoin(
          this.baseService.getItems('vehicleByOwnershipType'),
          this.baseService.getItems('driversByDepartment'),
          this.baseService.getItems('vehicleByDepartment'))
          .subscribe(res => {
              this.setChartConfig('vehicleByOwnershipType', res[0]);
              this.setChartConfig('driversByDepartment', res[1]);
              this.setChartConfig('vehicleByDepartment', res[2]);
              this.isLoading = false;
              this.calcDataPercentages();
              this.drawPieChartCenterText();
          })
    }

    setChartConfig(chartType: string, data: any): void {
        this.chartsConfig[chartType].total = data.total;
        this.chartsConfig[chartType].centerText = data.total;

        data.items.forEach(item => {
            this.chartsConfig[chartType].data.push(item.count);
            this.chartsConfig[chartType].labels.push(item.name);
        });
    }

    drawPieChartCenterText(): void {
        Chart.pluginService.register({
            beforeDraw: (chart: Chart) => {
                const ctx = chart.ctx;

                ctx.restore();
                const fontSize = (chart.height / 114).toFixed(2);
                ctx.font = fontSize + 'em sans-serif';
                ctx.textBaseline = 'middle';
                const text = this.chartsConfig[chart.canvas.getAttribute('chartKey')].centerText;
                const measuredText = ctx.measureText(text).width;
                let textX = chart.chartArea.left + Math.round((chart.width - chart.chartArea.left) / 2) - measuredText / 2;
                const textY = chart.height / 2;

                if (this.localizationService.isRtl()) {
                    textX = textX - 32.5;
                }

                ctx.fillText(text, textX, textY);
                ctx.save();
            }
        });
    }

    onLangChange(): void {
        this.translate.onLangChange.subscribe(lang =>
          this.charts['_results'].forEach(chart => {
              if (chart.chart) {
                  chart.chart.options.plugins.datalabels.align = lang.lang === 'he' ? 'right' : 'center';
              }
          })
        );
    }

    chartClicked(chartKey: string): void {
        this.chartsConfig[chartKey].percentageMode = !this.chartsConfig[chartKey].percentageMode;
    }

    private calcDataPercentages(): void {
        Object.keys(this.chartsConfig).forEach(chartKey => {
            this.chartsConfig[chartKey].dataPercentages =  this.chartsConfig[chartKey].data.map(number => parseFloat(((number / this.chartsConfig[chartKey].total) * 100).toFixed(2)));
        });
    }
}
