import { Injectable } from '@angular/core';
import scalingServiceConfig from './scaling.service.config';

@Injectable()
export class ScalingService {
    pageZoom: number;
    transformOriginTop: string;
    transformOriginBottom: string;

    constructor() {
        this.defaultSizeInit();
    }

    getScaling() {
        if ((navigator.userAgent.indexOf('Firefox')) !== -1) {
            return `scale(${this.pageZoom})`;
        }
        return this.pageZoom;
    }

    canZoomIn() {
        return this.pageZoom < scalingServiceConfig.maxScalingValues.maxZoom;
    }

    canZoomOut() {
        return this.pageZoom > scalingServiceConfig.minScalingValues.minZoom;
    }

    defaultSizeInit() {
        this.pageZoom = scalingServiceConfig.defaultScalingValues.pageZoom;
        this.transformOriginTop = scalingServiceConfig.defaultScalingValues.transformOriginTop;
        this.transformOriginBottom = scalingServiceConfig.defaultScalingValues.transformOriginBottom;
    }

    zoomInInit() {
        if (this.pageZoom <= scalingServiceConfig.maxScalingValues.maxZoom) {
            this.pageZoom += scalingServiceConfig.zoomDelta;
        }
        this.transformInit();
    }

    zoomOutInit() {
        if (this.pageZoom >= scalingServiceConfig.minScalingValues.minZoom) {
            this.pageZoom -= scalingServiceConfig.zoomDelta;
        }
        this.transformInit();
    }

    transformInit() {
        if (this.pageZoom === scalingServiceConfig.minScalingValues.minZoom) {
            this.transformOriginTop = scalingServiceConfig.minScalingValues.transformOriginTop;
            this.transformOriginBottom = scalingServiceConfig.minScalingValues.transformOriginBottom;
        } else {
            this.transformOriginTop = scalingServiceConfig.defaultScalingValues.transformOriginTop;
            this.transformOriginBottom = scalingServiceConfig.defaultScalingValues.transformOriginBottom;
        }
    }

    getTransformOriginBottom() {
        return this.transformOriginBottom;
    }

    getTransformOriginTop() {
        return this.transformOriginTop;
    }
}
