import { CommonActions } from './common.actions';
import { ICommon } from './common.model';

const INITIAL_STATE: ICommon = {
    currentDate: {}
};

export function createCommonAPIReducer() {
    return function commonReducer(state: ICommon = INITIAL_STATE, action: any): ICommon {
        if (action.type === CommonActions.SET_CURRENT_DATE) {
            return {
                ...state,
                currentDate: action.currentDate
            };
        }

        return state;
    };
}
