import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditUsersComponent } from '../../components/add-edit-users/add-edit-users.component';
import { ContactGroupsInputComponent } from '../../../modules/inputs/contact-groups-input/contact-groups-input.component';
import { TableElementComponent } from '../../components/table-element/table-element.component';
import { DocumentsPageComponent } from '../../components/documents-page/documents-page.component';
import { HistoryTableComponent } from '../../components/history-table/history-table.component';
import { AlertExpirationPopoverComponent } from '../../../modules/alerts/alerts-page/alert-expiration-popover/alert-expiration-popover.component';
import { DynamicFieldComponent } from './dynamic-field/dynamic-field.component';
import { DynamicComponentComponent } from './dynamic-component/dynamic-component.component';
import { InputsModule } from '../../../modules/inputs/inputs.module';
import { UCommonModule } from '@shift/ulib';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TabsModule } from 'ngx-bootstrap';
import { NgHttpLoaderModule } from 'ng-http-loader';

@NgModule({
    imports: [
        CommonModule,
        InputsModule,
        UCommonModule,
        ReactiveFormsModule,
        TranslateModule,
        TabsModule,
        NgHttpLoaderModule
    ],
    declarations: [
        DynamicFieldComponent,
        DynamicComponentComponent
    ],
    entryComponents: [
        AddEditUsersComponent,
        ContactGroupsInputComponent,
        TableElementComponent,
        DocumentsPageComponent,
        HistoryTableComponent,
        AlertExpirationPopoverComponent
    ],
    exports: [
        DynamicFieldComponent,
        DynamicComponentComponent
    ]
})
export class DynamicElementsModule { }
