import { DepartmentsSettingsComponent } from '../../../settings-tabs/departments-settings/departments-settings.component';

const fields = {
    departments: {
        arrayField: true,
        name: 'departments',
        inputType: 'departments',
        bodyComponent: DepartmentsSettingsComponent
    }
};

const settingsDepartmentsConfig = {
    fields,
    showAddNew: true,
    notNestedForm: true,
    title: 'settings.departments.title',
    columns: {
        departments: {
            title: 'settings.departments.title',
            fields: [fields.departments]
        }
    }
};

export default settingsDepartmentsConfig;
