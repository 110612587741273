import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import documentsPageConfig from './documents-page.component.config';
import { BaseService } from '../../services/api/base.service';
import { AddEditModalService } from '../../modules/add-edit-modal/services/add-edit-modal.service';
import { CtrlFormsService } from '../../services/ctrl-forms.service';
import ctrlInputConfig from '../../../modules/inputs/ctrl-inputs.config';

@Component({
    selector: 'documents-page',
    templateUrl: './documents-page.component.html',
    styleUrls: ['./documents-page.component.scss', './documents-page.component.rtl.scss']
})
export class DocumentsPageComponent implements OnInit {
    @Input() config: any = {};
    @Input() formObject: FormArray;

    documentsPageConfig = documentsPageConfig;

    constructor(private ctrlFormsService: CtrlFormsService, private baseService: BaseService, private addEditModalService: AddEditModalService) { }

    ngOnInit(): void {
        if (this.formObject && !this.addEditModalService.isEditMode) {
            this.setDefaultDocuments();
        }
    }

    setDefaultDocuments(): void {
        this.baseService.getDocumentTypes()
          .subscribe(documentTypes => {
              if (documentTypes && Object.keys(documentTypes).length > 0) {
                  documentTypes.forEach(doc => {
                      const defaultDocForm = this.ctrlFormsService.generateFieldsForm(ctrlInputConfig.documents.fields);
                      defaultDocForm.get('name').setValue(doc);
                      defaultDocForm.get('type').setValue(doc);
                      defaultDocForm.get('type').setValue(doc);
                      this.formObject.push(defaultDocForm);
                  });
              }
          });
    }

    addNewDocument(): void {
        this.formObject.insert(0, this.ctrlFormsService.generateFieldsForm(ctrlInputConfig.customDocuments.fields));
    }

    removeCustomFile(index): void {
        this.formObject.removeAt(index);
    }

    resetFileControl(control: FormGroup): void {
        control.patchValue({
            file: null,
            expirationDate: null
        });
    }
}
