const scalingServiceConfig = {
    defaultScalingValues: {
        pageZoom: 1,
        transformOriginTop: '0 0',
        transformOriginBottom: '0 0',
    },
    maxScalingValues: {
        maxZoom: 1.3
    },
    minScalingValues: {
        minZoom: 0.8,
        transformOriginTop: 'center top',
        transformOriginBottom: 'center bottom'
    },
    zoomDelta: 0.1
};

export default scalingServiceConfig;
