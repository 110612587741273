const dictionaryPath = 'settings.generalDetails';

const fields = {
    name: {
        name: 'name',
        required: true,
        inputType: 'text',
        title: `${dictionaryPath}.name`
    },
    address: {
        name: 'address',
        required: true,
        inputType: 'address',
        title: `${dictionaryPath}.address`
    },
    accountNumber: {
        name: 'accountNumber',
        inputType: 'number',
        valueType: 'string',
        minLength: 8,
        maxLength: 9,
        title: `${dictionaryPath}.businessIdentity`
    },
    language: {
        name: 'language',
        required: true,
        inputType: 'select',
        defaultValue: 'he',
        inputOptionsArr: [
            {
                name: 'עברית',
                value: 'he'
            },
            {
                name: 'English',
                value: 'en'
            }
        ],
        title: `${dictionaryPath}.emailNotifications.language`
    }
};

const settingsGeneralDetailsConfig = {
    fields,
    title: `${dictionaryPath}.title`,
    columns: {
        general: {
            title: `${dictionaryPath}.title`,
            fields: [fields.name, fields.address, fields.accountNumber]
        },
        emailNotifications: {
            title: `${dictionaryPath}.emailNotifications.title`,
            fields: [fields.language]
        }
    }
};

export default settingsGeneralDetailsConfig;
