<div class="table-page-container">
  <div class="table-meta-data" *ngIf="alteredRows && displayTableMetaData">
    <span class="num-rows" *ngIf="tableConfig['counterLabel']">{{alteredRows.length}} {{tableConfig['counterLabel'] | translate}}</span>
    <span class="num-selected-rows"
          *ngIf="alteredRows.length > 0">{{selectedRows.length}} {{'table.selected' | translate}}</span>
  </div>
  <u-grid #tableElement
          class="u-grid_app-table" *ngIf="userId && !isLoading"
          [rows]="alteredRows || []"
          [columns]="tableConfig.columns"
          (selectRows)="setSelectedRows($event);"
          [columnMode]="'force'"
          [headerHeight]="45"
          [headerOffset]="10"
          [footerHeight]="0"
          [rowHeight]="65"
          [reorderable]="true"
          [scrollbarV]="true"
          [scrollbarH]="true"
          [isRtl]="isRtl()"
          [editMode]="'cell'"
          [selectionType]="'checkbox'"
          [showColumnsFilter]="true"
          [messages]="'uGrid' | translate"
          [selected]="selectedRows"
          [tableName]="tableConfig['tableName']"
          [rowClassObjects]="specificRowClassObjects"
          [userId]="userId"
          [saveTableProps]="true">
  </u-grid>
</div>

<!-- ****** Cell Templates ****** -->
<ng-template #normalCell ngx-datatable-cell-template let-cell="value" let-column="column" let-row="row">
  <span class="mockup-cell-value" *ngIf="column['mockUpOptions']">
    {{cell.value}}
  </span>
  <div class="cell-value-container" *ngIf="!column['mockUpOptions'] && !column['noValue']">
    <span class="cell-value" *ngIf="!column['dateFormat']" title="{{column['translate'] ? (cell | translate) : cell}}">
      {{column['translate'] ? (cell | translate) : cell}}
    </span>
    <span class="cell-value" *ngIf="column['dateFormat']">
      {{cell | date:column['dateFormat']}}
    </span>
  </div>
</ng-template>

<ng-template #editRowCell ngx-datatable-cell-template let-row="row" let-column="column" let-cell="value">
  <div class="edit-row-cell">
    <span class="cell-value" *ngIf="!column['dateFormat']">{{cell.value ? cell.value : cell}}</span>
    <span class="cell-value" *ngIf="column['dateFormat']">
      {{cell | date:column['dateFormat']}}
    </span>
    <button type="button" class="cell-edit-btn u-grid-button" (click)="tablePageService.editRow(row, pageConfig['modalComponent'])" [disabled]="tableConfig.disableEditing" [ngClass]="{'u-btn-disabled': tableConfig.disableEditing}">
      {{'table.edit' | translate}}
    </button>
  </div>
</ng-template>

<ng-template #statusCell ngx-datatable-cell-template let-cell="value" let-column="column">
  <div class="status-cell">
    <!-- TODO remove when done with mockUps-->
    <span *ngIf="column['mockUpOptions']" class="cell-value" [ngClass]="{'red-status': !cell.value.valid}">{{cell && cell.value && cell.value.value}}</span>
    <!-- TODO remove *ngIf when done with mockUps-->
    <span *ngIf="!column['mockUpOptions']" class="cell-value" [ngClass]="{'red-status': cell === column.statusOptions.invalid}">{{column['translate'] ? (cell | translate): cell}}</span>
  </div>
</ng-template>

<ng-template #durationCell ngx-datatable-cell-template let-cell="value" let-column="column">
  <div class="duration-cell">
    <span class="cell-value">{{cell | duration: column['durationUnit']: localizationService.getLanguage()}}</span>
  </div>
</ng-template>

<ng-template #popoverCell ngx-datatable-cell-template let-row="row" let-cell="value" let-column="column">
  <popover-cell [row]="row" [cell]="cell" [col]="column" [config]="column['popoverConfig']"></popover-cell>
</ng-template>

<ng-template #selectCell ngx-datatable-cell-template let-row="row" let-cell="value" let-column="column">
  <u-select-s [items]="categories[column.inputOptionsVar]"
              [container]="'body'"
              [formControl]="cell" [valid]="cell.valid"
              [emptyName]="'general.select' | translate"
              [emptyHidden]="true"
              [emptyValue]="null"
              [emptyRemove]="null"
              [messages]="'uSelectS' | translate"
              [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']">
  </u-select-s>
</ng-template>

