import * as pluginDataLabels from 'chartjs-plugin-datalabels';

const dictionaryPath = 'reports';

const reportsPageComponentConfig = {
    pieChart: {
        chartType: 'doughnut',
        plugins: [pluginDataLabels],
        colors: [
            {
                backgroundColor: ['#009245', '#40AD73', '#98D3B4']
            }
        ],
        charts: {
            vehicleByOwnershipType: {
                data: [],
                labels: [],
                percentageMode: true,
                title: 'reports.charts.vehicleByOwnershipType.title'
            },
            driversByDepartment: {
                data: [],
                labels: [],
                percentageMode: true,
                title: 'reports.charts.driversByDepartment.title'
            },
            vehicleByDepartment: {
                data: [],
                labels: [],
                percentageMode: true,
                title: 'reports.charts.vehicleByDepartment.title',
            }
        }
    },
    barChart: {
        items: [`${dictionaryPath}.gas`, `${dictionaryPath}.tires`, `${dictionaryPath}.fuel`]
    },
    vehicleTable: {
        timePeriods: [],
        columns: [
            {
                prop: 'licenseNumber',
                name: `${dictionaryPath}.table.licenseNumber`,
                filterType: 'text',
                mockUpOptions: {
                    type: 'licenseNumber'
                }
            },
            {
                prop: 'type',
                name: `${dictionaryPath}.table.type`,
                filterType: 'text',
                translate: true,
                mockUpOptions: {
                    type: 'manufacturer'
                }
            },
            {
                prop: 'involvedDriver',
                name: `${dictionaryPath}.table.involvedDriver`,
                filterType: 'text',
                mockUpOptions: {
                    type: 'fullName'
                }
            },
            {
                prop: 'exceptions',
                name: `${dictionaryPath}.table.exceptions`,
                filterType: 'text',
                mockUpOptions: {
                    type: 'number'
                }
            }
        ]
    }
};

export default reportsPageComponentConfig;
