<div class="payment-terms-input-container" *ngIf="paymentTypes">
  <u-select-s class="payment-terms-input-payment-type"
              [items]="paymentTypes"
              [formControl]="paymentTypeControl"
              [valid]="paymentTypeControl.valid"
              [placeholder]="'general.select' | translate"
              [emptyName]="'general.select' | translate"
              [emptyHidden]="true"
              [emptyValue]="null"
              [emptyRemove]="null"
              [messages]="'uSelectS' | translate"
              [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']">
  </u-select-s>
  <u-select-s *ngIf="paymentTermControl"
      class="payment-terms-input-payment-term disabled-input" [ngClass]="{'disabled-input' : paymentTypeControl.value !== 'paymentType.current'}"
              [attr.disabled]="paymentTypeControl.value !== 'paymentType.current'"
              [items]="paymentTerms"
              [formControl]="paymentTermControl"
              [valid]="paymentTermControl.valid"
              [placeholder]="'general.select' | translate"
              [emptyName]="'general.select' | translate"
              [emptyHidden]="true"
              [emptyValue]="null"
              [emptyRemove]="null"
              [messages]="'uSelectS' | translate"
              [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']">
  </u-select-s>
</div>
