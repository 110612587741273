import { Subject } from 'rxjs';
import { Component, Input, OnChanges } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { LocalizationService } from '../../../services';
import { MetadataService } from '../../../services/metadata.service';

@Component({
    selector: 'dynamic-field',
    templateUrl: './dynamic-field.component.html',
    styleUrls: ['./dynamic-field.component.scss', 'dynamic-field.component.rtl.scss']
})
export class DynamicFieldComponent implements OnChanges {
    @Input() public editData: any;
    @Input() public metadata: any = {};
    @Input() public field: Object;
    @Input() public formObject: any;
    @Input() addNewFieldObs: Subject<any> = new Subject();

    public isRtl: boolean;
    public fieldConfig: any;
    public formControl: FormControl | FormArray;

    constructor(public localizationService: LocalizationService, private metadataService: MetadataService) {
        this.isRtl = this.localizationService.isRtl();
    }

    ngOnChanges(): void {
        this.fieldConfig = this.field['config'];
        this.setFormControl();
        this.fetchMetaDataItem();
    }

    setFormControl(): void {
        if (this.formObject) {
            if (this.field['name'] && !this.field['noParent']) {
                this.formControl = this.formObject.controls[this.field['name']];
            }
            if (!this.formControl) {
                this.formControl = this.formObject;
            }
        }
    }

    fetchMetaDataItem(): void {
        if (this.field['inputOptionsVar'] && this.metadataService.metadataObsObj[this.field['inputOptionsVar']]) {
            this.metadataService.metadataObsObj[this.field['inputOptionsVar']].subscribe(item => this.metadata[this.field['inputOptionsVar']] = item);
        }
    }
}
