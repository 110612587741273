import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationService, LocalizedToastrService, StatusesService } from '../../../shared/services';
import { FormsService, AuthService } from '../../../shared/services';
import { UPopupService } from '@shift/ulib';
import loginPageConfig from './login-page.component.config';
import { environment } from '../../../../environments/environment';
import appConfig from '../../../app.config';

@Component({
    selector: 'app-login',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss', './login-page.component.rtl.scss']
})

export class LoginPageComponent implements OnInit {
    error: any;
    loginForm: FormGroup;
    newPasswordForm: FormGroup;
    isSubmitting = false;
    logoText: string = appConfig.appName;

    public steps: any = loginPageConfig.steps;
    public errors: any = loginPageConfig.errors;
    currentStep = this.steps.loginForm;

    constructor(
      private formsService: FormsService,
      private authService: AuthService,
      private fb: FormBuilder,
      public translate: TranslateService,
      public localizationService: LocalizationService,
      private toastr: LocalizedToastrService,
      private popupService: UPopupService,
      private statusesService: StatusesService
    ) {
    }

    ngOnInit(): void {
        this.initLoginForm();
        this.updateForm('loginForm');
        this.loginForm.valueChanges.subscribe(() => this.updateForm('loginForm'));
        this.translate.onLangChange.subscribe(() => this.updateForm('loginForm'));
    }

    initLoginForm(): void {
        this.loginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            clientId: [environment.clientId]
        });
    }

    initNewPasswordForm(): void {
        this.newPasswordForm = this.fb.group({
            username: [this.loginForm.value.username, Validators.required],
            password: ['', Validators.required],
            passwordRepeat: ['', Validators.required],
            code: ['', Validators.required],
            clientId: [environment.clientId]
        }, {validator: this.passwordMatchValidator});
    }

    newUserRequest(): void {
        this.isSubmitting = true;
        this.authService.newUserLogin(this.loginForm.value.username).subscribe(
            data => {
                if (data.newPasswordRequired) {
                    this.showNewPasswordForm();
                }
            },
            err => this.showError(err.errors),
            () => this.isSubmitting = false);
    }

    forgotPassword(): void {
        this.isSubmitting = true;
        this.authService.forgotPassword(this.loginForm.value.username).subscribe(
            () => this.showNewPasswordForm(),
            err => this.showError(err.errors),
            () => this.isSubmitting = false);
    }

    checkPassword(credentials: any = this.loginForm.getRawValue()): void {
        this.isSubmitting = true;

        this.authService.login(credentials).subscribe(
            () => ({}),
            err => this.showError(err.errors),
            () => this.isSubmitting = false);
    }

    setPassword(): void {
        this.updateForm('newPasswordForm');
        if (this.newPasswordForm.valid) {
            this.isSubmitting = true;
            const newPasswordFormValues = this.newPasswordForm.getRawValue();

            this.authService.changePassword({
                password: newPasswordFormValues.password,
                username: newPasswordFormValues.username,
                code: newPasswordFormValues.code
            }).subscribe(
                () => this.checkPassword(newPasswordFormValues),
                err => this.showError(err.errors),
                () => this.isSubmitting = false);
        }
    }

    showNewPasswordForm(): void {
        this.initNewPasswordForm();
        this.showForm('newPasswordForm');
    }

    showForm(formName): void {
        this.initNewPasswordForm();
        this.updateForm(formName);
        this[formName].valueChanges.subscribe(() => this.updateForm(formName));
        this.translate.onLangChange.subscribe(() => this.updateForm(formName));
        this.currentStep = this.steps[formName];
    }

    updateForm(formName): void {
        this.onValueChanged(this[formName], this.errors[formName]);
        this.error = '';
    }

    //TODO: old code - check and improve
    onValueChanged(form: any, formErrors: any): void {
        if (!form) {
            return;
        }

        for (const field of Object.keys(formErrors)) {
            const control: any = form.get(field);
            formErrors[field] = '';

            if (control && control.invalid && control.touched && control.errors) {
                formErrors[field] = this.statusesService.getMessageByName(Object.keys(control.errors)[0]);
            }
        }
    }

    showError(errors): void {
        this.popupService.showErrors({
            title: 'general.error',
            ok: 'Ok',
            errors
        });
    }

    stepBackToLogin(): void {
        this.currentStep = this.steps.loginForm;
    }

    passwordMatchValidator(group: FormGroup): any {
        return (group && group.get('password').value !== group.get('passwordRepeat').value) ? {notMatching: true} : null;
    }
}
