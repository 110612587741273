import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/shared/services/api/base.service';
import eventsPageConfig from './events-page.component.config';

@Component({
    selector: 'events-page',
    templateUrl: './events-page.component.html',
    styleUrls: ['./events-page.component.scss', './events-page.component.rtl.scss']
})
export class EventsPageComponent implements OnInit {
    rows: any[] = [];
    eventsPageConfig: any = eventsPageConfig;

    constructor(private baseService: BaseService) { }

    ngOnInit(): void {
        this.baseService.setPaths('events');
        this.fetchEvents();
    }

    fetchEvents(): void {
        this.baseService.getAll().subscribe(events => this.rows = events);
    }
}
