import { Injectable } from '@angular/core';
import { ConstantsService } from '../services';
import { BaseService } from './api/base.service';
import { BehaviorSubject } from 'rxjs';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';

@Injectable()
export class MetadataService {
    public isLoading: boolean;
    public metadataObsObj: {} = {};

    private progressRef: NgProgressRef;
    private isItemsLoading: boolean;
    private isCategoriesLoading: boolean;

    constructor(private baseService: BaseService, private progress: NgProgress, private constantsService: ConstantsService) {
        this.progressRef = this.progress.ref('progressBar');
    }

    setMetadataObs(modalConfig: any): void {
        if (modalConfig.itemsToFetch) {
            modalConfig.itemsToFetch.forEach(item => item.fetchItems.forEach(fetchItem => this.setMetadataItemValue(fetchItem.name)));
        }

        const keysToFetch = [].concat(modalConfig.metadataItemsKeys || [], modalConfig.categoriesToFetch || []);
        keysToFetch.forEach(item => this.setMetadataItemValue(item));

        this.isLoading = false;
    }

    setMetadataItemValue(itemKey: string, value?: any): void {
        if (!this.metadataObsObj[itemKey]) {
            this.metadataObsObj[itemKey] = new BehaviorSubject<any>(null);
        }
        if (value) {
            this.metadataObsObj[itemKey].next(value);
        }
    }

    fetchCategories(categoriesToFetch: string[]): void {
        this.isLoading = true;
        this.progressRef.start();
        this.constantsService.fetchCategories(categoriesToFetch);

        this.constantsService.categories
      .subscribe(categories => {
          categoriesToFetch.forEach(category => {
              this.setMetadataItemValue(category, categories[category]);
              this.isCategoriesLoading = false;
              this.setLoading();
          });
      });
    }

    fetchItems(itemsToFetch: any[]): void {
        this.isLoading = true;
        this.progressRef.start();

        itemsToFetch.forEach(item => {
            item.fetchItems.forEach(fetchItem =>
        this.baseService.getAllSlimByTypes(fetchItem.types, item.basePath)
          .subscribe(list => {
              this.setMetadataItemValue(fetchItem.name, list);
              this.isItemsLoading = false;
              this.setLoading();
          })
      );
        });
    }

    private setLoading(): void {
        this.isLoading = this.isItemsLoading && !this.isCategoriesLoading;
        if (!this.isLoading) {
            this.progressRef.complete();
        }
    }
}
