import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditModalComponent } from './add-edit-modal.component';
import { TabsModule, BsModalRef } from 'ngx-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { InputsModule } from '../../../modules/inputs/inputs.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UCommonModule } from '@shift/ulib';
import { AddEditModalService } from './services/add-edit-modal.service';
import { DynamicElementsModule } from '../dynamic-elements/dynamic-elements.module';

@NgModule({
    imports: [
        CommonModule,
        TabsModule,
        TranslateModule,
        InputsModule,
        NgHttpLoaderModule,
        ReactiveFormsModule,
        UCommonModule,
        DynamicElementsModule
    ],
    declarations: [
        AddEditModalComponent
    ],
    providers: [
        AddEditModalService,
        BsModalRef
    ],
    exports: [
        AddEditModalComponent
    ]
})
export class AddEditModalModule {
}
