import { Injectable } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import validationServiceConfig from './validation.service.config';

@Injectable()
export class ValidationService {
    static email(control: FormControl) {
        if (!control.value) {
            return null;
        }

        return validationServiceConfig.regexp.email.test(control.value) ? null : {
            email: true
        };
    }

    static number(control: FormControl) {
        if (!control.value) {
            return null;
        }

        return validationServiceConfig.regexp.number.test(control.value) ? null : {
            number: true
        };
    }

    static time(startTime: any, endTime: any) {
        return (group: FormGroup) => {
            const timeStart = group.controls[startTime];
            const timeEnd = group.controls[endTime];

            if (timeStart.value && timeEnd.value && timeStart.value.substring(0, 2) >= timeEnd.value.substring(0, 2)) {
                timeStart.setErrors({required: true});
            } else {
                timeStart.setErrors(null);
            }
        }
    }

    checkNewPasswordRepeat = (newPassword: FormControl, control: FormControl) => {
        return newPassword.value === control.value ? null : {invalidNewPasswordRepeat: true};
    };

    checkContactDataContacts = (users: FormArray, name: FormControl, contactType: FormControl, control: FormControl) => {
        const count = users.value
            .reduce((flat, current) => flat.concat(current.personContacts), [])
            .filter((ob: any) =>
                ob.contact === control.value &&
                ob.name !== name.value
            )
            .length;

        return count === 0 ? null : {unique: true};
    };

    checkAddressLatitudeLongitude = (latitude: FormControl, longitude: FormControl, control: FormControl) => {
        if (control.value !== '' && (latitude.value === '' || longitude.value === '')) {
            return {required: true};
        }
        return null;
    };
}
