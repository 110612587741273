const dictionaryPath = 'contactDetails';

const contactsInputConfig = {
    buttons: {
        addNewContact: `${dictionaryPath}.addNewContact`,
        deleteContact: `${dictionaryPath}.deleteContact`,
    },
    fields: [
        {
            name: 'type',
            inputType: 'select',
            required: true,
            defaultValue: 'contactType.mobile',
            optionsVar: 'contactType',
            placeholder: `${dictionaryPath}.roles.title`
        },
        {
            name: 'contact',
            inputType: 'text',
            required: true,
            translatePath: `${dictionaryPath}.contacts`,
        }
    ]
};

export default contactsInputConfig;
