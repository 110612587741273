import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import apiServiceConfig from '../../shared/services/api/api.service.config';
import { ApiService } from '../../shared/services';

@Injectable()
export class AlertsService {
    private paths: any = apiServiceConfig.paths.alerts;

    constructor(private apiService: ApiService) {}

    getAlerts(filterType?: string): Observable<any> {
        if (filterType) {
            return this.apiService.get(this.paths.basePath, {type: filterType});
        }
        return this.apiService.get(this.paths.basePath);
    }

    getFeed(): Observable<any> {
        return this.apiService.get(this.paths.basePath + this.paths.feed);
    }

    updateExpiration(body, type: 'date' | 'mileage' | 'documentDate'): Observable<any> {
        return this.apiService.put(this.paths.basePath + this.paths[type], body);
    }
}
