import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersPageComponent } from './customers-page/customers-page.component';
import { UCommonModule, UGridModule, USplitLayoutModule } from '@shift/ulib';
import { TranslateModule } from '@ngx-translate/core';
import { CustomerAddEditComponent } from './customer-add-edit/customer-add-edit.component';
import { TabsModule, TooltipModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorsTranslatePipe } from '../../shared/pipes';
import { CustomersService } from './services/customers.service';
import { PersonService } from '../../shared/services/api/person.service';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { HttpClientModule } from '@angular/common/http';
import { InputsModule } from '../inputs/inputs.module';
import { AddEditModalModule } from '../../shared/modules/add-edit-modal/add-edit-modal.module';
import { SharedModule } from '../../shared/shared.module';
import { BaseService } from 'src/app/shared/services/api/base.service';

@NgModule({
    imports: [
        CommonModule,
        USplitLayoutModule,
        UGridModule,
        TranslateModule,
        TabsModule,
        ReactiveFormsModule,
        UCommonModule,
        FormsModule,
        TooltipModule,
        HttpClientModule,
        NgHttpLoaderModule,
        InputsModule,
        AddEditModalModule,
        SharedModule
    ],
    declarations: [
        CustomersPageComponent,
        CustomerAddEditComponent,
        ErrorsTranslatePipe
    ],
    entryComponents: [
        CustomerAddEditComponent
    ],
    providers: [
        CustomersService,
        PersonService,
        BaseService
    ],
    exports: [
        ErrorsTranslatePipe
    ]
})

export class CustomersModule {
}
