const dictionaryPath = 'settings.vehicles';

const fields = {
    nextInspectionDate: {
        name: 'nextInspectionDate',
        inputType: 'alert',
        title: `${dictionaryPath}.nextInspection`
    },
    nextInspectionMileage: {
        name: 'nextInspectionMileage',
        inputType: 'number',
        valueType: 'number',
        title: `${dictionaryPath}.nextInspectionKm`
    },
    documents: {
        name: 'documents',
        inputType: 'alert',
        noParent: true,
        arrayField: true
    }
};

const settingsAlertsVehiclesConfig = {
    fields,
    title: `${dictionaryPath}.pageTitle`,
    columns: {
        documents: {
            isArray: true,
            showCounter: true,
            fields: [fields.documents],
            title: `${dictionaryPath}.documentAlerts`
        },
        inspection: {
            showCounter: true,
            title: `${dictionaryPath}.technicalInspections`,
            fields: [fields.nextInspectionDate, fields.nextInspectionMileage]
        }
    }
};

export default settingsAlertsVehiclesConfig;
