import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { UCommonModule, USplitLayoutModule } from '@shift/ulib';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsTabComponent } from './settings-page/settings-tab/settings-tab.component';
import { TabsModule } from 'ngx-bootstrap';
import { DepartmentsSettingsComponent } from './settings-page/settings-tabs/departments-settings/departments-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicElementsModule } from '../../shared/modules/dynamic-elements/dynamic-elements.module';

@NgModule({
    imports: [
        CommonModule,
        USplitLayoutModule,
        TranslateModule,
        TabsModule,
        UCommonModule,
        ReactiveFormsModule,
        DynamicElementsModule
    ],
    declarations: [
        SettingsPageComponent,
        SettingsTabComponent,
        DepartmentsSettingsComponent
    ],
    entryComponents: [
        SettingsPageComponent,
        DepartmentsSettingsComponent
    ]
})
export class SettingsModule { }
