import { Component, Input, OnChanges } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import {
    ConstantsService,
    LocalizationService,
    PersonService,
    ValidationService,
    FormsService
} from '../../../../shared/services';
import contactsInputConfig from './contacts-input.component.config';
import { UPopupService } from '@shift/ulib';
import { AddEditUsersService } from '../../../../shared/components/add-edit-users/add-edit-users.service';
import { ExistingEntityService } from '../../../../shared/services/existing-entity/existing-entity.service';

@Component({
    selector: 'contacts-input',
    templateUrl: './contacts-input.component.html',
    styleUrls: ['./contacts-input.component.scss', './contacts-input.component.rtl.scss']
})
export class ContactsInputComponent implements OnChanges {
    @Input() public field: any;
    @Input() public config: any;
    @Input() public editData: any;
    @Input() public usersForm: FormArray;
    @Input() public entityForm: FormArray | FormGroup;
    @Input() public contactsForm: FormArray;

    isRtl: boolean;
    contactTypes: string[];

    constructor(
        private constantsService: ConstantsService,
        private personService: PersonService,
        private popupService: UPopupService,
        private usersService: AddEditUsersService,
        private localizationService: LocalizationService,
        private formsService: FormsService,
        private existingEntityService: ExistingEntityService
    ) {
        this.fetchContactTypes();
        this.isRtl = this.localizationService.isRtl();
    }

    ngOnChanges(): void {
        this.initContactsForm();
    }

    initContactsForm(): void {
        if (this.getContactsForm().controls.length === 0) {
            if (this.contactsData && this.contactsData.length) {
                this.updateContacts();
            } else if (this.field && this.field.required) {
                this.getContactsForm().push(this.getContactForm());
            }
        }
    }

    getContactsForm(): FormArray {
        return this.contactsForm || (this.entityForm && this.entityForm.get('contacts')) as FormArray;
    }

    updateContacts(): void {
        this.contactsData.forEach(contact =>
            this.getContactsForm().push(this.getContactForm(contact))
        );
    }

    get contactsData(): Array<any> {
        return this.editData ? this.editData.contacts : null;
    }

    fetchContactTypes(): void {
        this.constantsService.categories
            .subscribe(categories => this.contactTypes = categories.contactType);
    }

    getContactForm(contact?: any): FormGroup {
        const contactForm = this.formsService.generateForm(contactsInputConfig.fields);
        if (contact) {
            contactForm.patchValue({
                type: contact.type,
                contact: contact.contact
            });
        }
        const formIndex = this.getContactsForm().length;

        contactForm.get('type').valueChanges.subscribe(value => {
            this.updateContactValidators(value, contactForm.get('contact'));
            this.checkIfExistingContact(contactForm, formIndex);
        });
        return contactForm;
    }

    checkIfExistingContact(contactForm, contactFormIndex): void {
        const contactValues = contactForm.getRawValue();
        if (contactValues.contact && contactValues.type) {
            if (this.getContactsForm().length >= 2) {
                if (this.checkExistingContactWithinUser(contactValues, contactFormIndex)) {
                    return;
                }
                this.checkForDuplicateContactData();
            }
            if (this.entityForm && this.usersForm) {
                if (this.usersForm.length >= 1) {
                    const existingContactOnFEIndex = this.usersService.getExistingContactIndexFE(contactValues, this.usersForm, this.entityForm);
                    if (existingContactOnFEIndex >= 0) {
                        this.enforceExistingContactFE(existingContactOnFEIndex, contactFormIndex);
                    } else {
                        this.checkExistingContactBE(contactValues, contactFormIndex);
                    }
                } else {
                    this.checkExistingContactBE(contactValues, contactFormIndex);
                }
            } else if (this.field && this.field.config && this.field.config.fetchExistingDetails) {
                this.existingEntityService.init(this.field, contactForm, this.entityForm as FormGroup);
                this.existingEntityService.checkIfExistingEntityByContact();
            }
        }
    }

    checkExistingContactWithinUser(contactValues, contactFormIndex): boolean {
        const prevContactValues = [...this.getContactsForm().value];
        prevContactValues.splice(contactFormIndex, 1);
        if (this.usersService.isExistingContactWithinUser(contactValues, prevContactValues)) {
            this.getContactsForm().at(contactFormIndex).get('contact').setErrors({ 'existingContactData': true });
            return true;
        }
        this.getContactsForm().at(contactFormIndex).get('contact').setErrors(null);
        return false;
    }

    enforceExistingContactFE(existingContactOnFEIndex, contactFormIndex: number): void {
        this.popupService.showMessage({
            message: 'addEditUsers.messages.existingPersonOnFE',
            yes: 'general.yes',
            no: 'general.no',
        }, () => {
            this.usersService.selectedUserIndex.subscribe(index => {
                if (index !== existingContactOnFEIndex) {
                    this.usersForm.removeAt(index);
                    this.usersService.selectedUserIndex.next(existingContactOnFEIndex);
                }
            });
        },
            () => this.resetContactForm(contactFormIndex),
            () => this.resetContactForm(contactFormIndex));
    }

    checkExistingContactBE(contactValues, contactFormIndex: number): void {
        this.personService.findByContact(contactValues)
            .subscribe(user => {
                if (user) {
                    this.popupService.showMessage({
                        message: 'addEditUsers.messages.useExistingPerson',
                        yes: 'general.yes',
                        no: 'general.no',
                    },
                        () => {
                            this.entityForm.controls['firstName'].setValue(user.firstName);
                            this.entityForm.controls['lastName'].setValue(user.lastName);
                            this.entityForm.controls['id'].setValue(user.id);
                        },
                        () => this.resetContactForm(contactFormIndex),
                        () => this.resetContactForm(contactFormIndex)
                    );
                }
            });
    }

    resetContactForm(contactFormIndex): void {
        this.getContactsForm().removeAt(contactFormIndex);
        this.getContactsForm().push(this.getContactForm());
    }

    addContact(): void {
        this.getContactsForm().push(this.getContactForm());
    }

    removeContact(index: number): void {
        this.getContactsForm().removeAt(index);
        this.checkForDuplicateContactData();
    }

    updateContactValidators(value, contactDataForm): void {
        contactDataForm.setValidators([
            Validators.required,
            value === 'contactType.email' ? ValidationService.email : ValidationService.number
        ]);
        contactDataForm.updateValueAndValidity();
    }

    checkForDuplicateContactData(): void {
        this.getContactsForm().controls.forEach((contact, contactFormIndex) =>
            this.checkExistingContactWithinUser(contact.value, contactFormIndex));
    }
}
