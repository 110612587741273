<div class="add-edit-modal-container" *ngIf="!metadataService.isLoading">
    <div class="u-modal__close" (click)="closeForm()"></div>
    <h3 class="add-edit-modal-title" [ngClass]="{'long-title': modalConfig.longTitle}">{{modalConfig.title | translate}}</h3>
    <tabset class="add-edit-modal__tabs u-tabset-pills" type="pills" [ngClass]="{'long-title': modalConfig.longTitle}">
        <tab *ngFor="let tab of modalConfig.tabs;" id="{{tab['id']}}" heading="{{tab['title'] | translate}}">
            <div class="modal-tab-body modal-tab-body-columns" *ngIf="tab['columns']">
                <div class="modal-col modal-col-{{colIndex}}" *ngFor="let col of tab['columns']; let colIndex=index;">
                    <div class="section" *ngFor="let section of col">
                        <div class="u-text-line u-text-line_border-off ctrl-section-title" *ngIf="section.title">
                            {{section.title | translate}}
                        </div>
                        <div class="field" *ngFor="let field of section['fields']" [ngClass]="field['bodyComponent'] ? 'full-height-field':''">
                            <dynamic-field *ngIf="!field.showIf || field.showIf && formObject.controls[field.showIf.formControl].value === field.showIf.equals" [field]="field"
                                           [formObject]="tab.notNestedForm ? formObject : formObject && formObject.controls[tab.id]" [editData]="editData"></dynamic-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-tab-body modal-tab-body-rows" *ngIf="tab['rows']">
                <div class="modal-row modal-row-{{rowIndex}}" *ngFor="let row of tab['rows']; let rowIndex=index;">
                    <div class="u-text-line u-text-line_border-off ctrl-section-title" *ngIf="row.title">
                        {{row.title | translate}}
                    </div>
                    <div class="field" *ngFor="let field of row['fields']" [ngClass]="field['bodyComponent'] ? 'full-height-field':''">
                        <dynamic-field [field]="field" [formObject]="tab.notNestedForm ? formObject : formObject && formObject.controls[tab.id]" [editData]="editData"></dynamic-field>
                    </div>
                </div>
            </div>
        </tab>
    </tabset>
    <div class="add-edit-modal__actions">
        <span class="add-edit-modal__actions__shadow"></span>
        <div class="add-edit-modal__actions__btns">
            <!--TODO remove disable when api ready; -->
            <button *ngIf="editData || modalConfig.enableDelete" class="u-btn" (click)="delete()" [disabled]="modalConfig.apiNotReady"
                [ngClass]="{'u-btn-disabled': modalConfig.apiNotReady}">{{modalConfig.deleteLabel | translate}}</button>
            <button class="u-btn" (click)="submitForm()" [disabled]="!canSubmit()" *ngIf="!modalConfig['readonly']"
                [ngClass]="{'u-btn-disabled': !canSubmit()}">{{'general.saveAndClose' | translate}}</button>
        </div>
    </div>
</div>
