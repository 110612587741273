import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ScalingService } from '../services/scaling.service';
import { AppSideMenuService } from '../app-side-menu/app-side-menu.service';

@Component({
    selector: 'app-layout',
    templateUrl: './app-layout.component.html',
    styleUrls: ['./app-layout.component.scss', './app-layout.component.rtl.scss']
})

export class AppLayoutComponent implements OnInit {
    constructor(public scalingService: ScalingService, private cdRef: ChangeDetectorRef, public appSideMenuService: AppSideMenuService) {
    }

    ngOnInit(): void {
        this.appSideMenuService.isMenuCollapsedObs.subscribe(() => this.cdRef.detectChanges());
    }
}
