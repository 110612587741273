import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgReduxModule } from '@angular-redux/store';
import { NgReduxRouterModule } from '@angular-redux/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ToastrModule } from 'ngx-toastr';
import { ClickOutsideModule } from 'ng-click-outside';
import { PopoverModule } from 'ngx-bootstrap/popover';

import 'hammerjs';

import { StoreModule } from './store/store.module';
import {
    UGridModule,
    USplitLayoutModule,
    USidebarMenuModule,
    UCommonModule, UTooltipModule,
} from '@shift/ulib';

import { AppComponent } from './app.component';

import {
    AuthGuard,
    AuthGuardNo
} from './shared';
import { appRoutes } from './routes/app.routes';
import { CommonModule } from '@angular/common';
import { NgReduxFormModule } from '@angular-redux/form';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AgmCoreModule, GoogleMapsAPIWrapper, MarkerManager } from '@agm/core';
import { DndModule } from 'ng2-dnd';
import { TooltipModule } from 'ngx-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RolesGuard } from './routes/roles-guard.service';
import { CustomersModule } from './modules/customers/customers.module';
import { AuthModule } from './modules/auth/auth.module';
import { AlertsModule } from './modules/alerts/alerts.module';
import { ReportsModule } from './modules/reports/reports.module';
import { AppLayoutModule } from './modules/layout/app-layout.module';
import { SharedModule } from './shared/shared.module';
import { SuppliersModule } from './modules/suppliers/suppliers.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { DriversModule } from './modules/drivers/drivers.module';
import { SettingsModule } from './modules/settings/settings.module';
import { VehiclesModule } from './modules/vehicles/vehicles.module';
import { ExpensesModule } from './modules/expenses/expenses.module';
import { InspectionsModule } from './modules/inspections/inspections.module';
import { EventsModule } from './modules/events/events.module';
import { SafetyOfficersModule } from './modules/safety-officers/safety-officers.module';
import { translateLoader } from './multi-translate-loader';
import { NgProgressModule } from '@ngx-progressbar/core';
import { environment } from '../environments/environment';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        RouterModule.forRoot(appRoutes),
        NgProgressModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgReduxFormModule,
        TranslateModule,
        NgxDatatableModule,
        AgmCoreModule.forRoot({
            apiKey: environment.googleApiKey,
            libraries: ['places']
        }),
        DndModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        ClickOutsideModule,
        UGridModule,
        UCommonModule,
        USplitLayoutModule,
        USidebarMenuModule,
        UTooltipModule,
        NgxChartsModule,
        PopoverModule.forRoot(),
        TranslateModule.forRoot(
            {
                loader: {
                    provide: TranslateLoader,
                    useFactory: translateLoader,
                    deps: [HttpClient]
                }
            }
        ),
        VirtualScrollerModule,
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        BrowserModule,
        FormsModule,
        HttpClientModule,
        NgReduxModule,
        NgReduxRouterModule.forRoot(),
        StoreModule,
        BrowserAnimationsModule,
        UGridModule,
        UCommonModule,
        USplitLayoutModule,
        USidebarMenuModule,
        MatButtonModule,
        MatFormFieldModule,
        MatCheckboxModule,
        ToastrModule.forRoot(),
        ClickOutsideModule,
        PopoverModule.forRoot(),
        AuthModule,
        SharedModule,
        AppLayoutModule,
        CustomersModule,
        SuppliersModule,
        DriversModule,
        AlertsModule,
        ReportsModule,
        NgHttpLoaderModule,
        SettingsModule,
        VehiclesModule,
        ExpensesModule,
        InspectionsModule,
        EventsModule,
        SafetyOfficersModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        AuthGuard,
        AuthGuardNo,
        RolesGuard,
        GoogleMapsAPIWrapper,
        MarkerManager
    ],
    exports: [
        BrowserAnimationsModule
    ]
})

export class AppModule {
}
