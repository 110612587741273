import { InspectionSummaryComponent } from '../inspection-summary/inspection-summary.component';
import { InspectionCategoriesComponent } from '../inspection-categories/inspection-categories.component';

const dictionaryPath = 'inspections.addEditInspection';

const fields = {
    id: {
        name: 'id',
        defaultValue: 0,
        required: true,
        inputType: 'label'
    },
    date: {
        name: 'date',
        required: true,
        defaultValue: new Date(),
        inputType: 'datepicker'
    },
    safetyOfficerName: {
        readonly: true,
        required: true,
        name: 'safetyOfficerName',
        inputType: 'text'
    },
    vehicleId: {
        required: true,
        name: 'vehicleId',
        inputType: 'select',
        inputOptionsVar: 'vehicles'
    },
    driverId: {
        name: 'driverId',
        inputType: 'select',
        inputOptionsVar: 'drivers'
    },
    currentMileage: {
        name: 'currentMileage',
        inputType: 'number',
        valueType: 'number'
    },
    nextInspectionMileage: {
        name: 'nextInspectionMileage'
    },
    nextInspectionDate: {
        name: 'nextInspectionDate'
    },
    categories: {
        name: 'categories',
        inputType: 'categories',
        bodyComponent: InspectionCategoriesComponent
    },
    inspectionSummary: {
        name: 'inspectionSummary',
        bodyComponent: InspectionSummaryComponent
    },
    status: {
        name: 'status',
        required: true,
        translate: true,
        inputType: 'select',
        inputOptionsVar: 'inspectionStatus',
        placeholder: `${dictionaryPath}.vehicleStatus.placeholder`
    },
    notes: {
        name: 'notes',
        inputType: 'textarea'
    }
};

const tabs = [
    {
        notNestedForm: true,
        columns: [
            [
                {
                    title: `${dictionaryPath}.generalDetails.title`,
                    fields: [
                        {
                            ...fields.id,
                            title: `${dictionaryPath}.generalDetails.id`
                        },
                        {
                            ...fields.date,
                            title: `${dictionaryPath}.generalDetails.date`
                        },
                        {
                            ...fields.safetyOfficerName,
                            title: `${dictionaryPath}.generalDetails.safetyOfficer`
                        },
                        {
                            ...fields.vehicleId,
                            title: `${dictionaryPath}.generalDetails.licenseNumber`
                        },
                        {
                            ...fields.driverId,
                            title:  `${dictionaryPath}.generalDetails.driver`
                        },
                        {
                            ...fields.currentMileage,
                            title: `${dictionaryPath}.generalDetails.currentMileage`
                        },
                        {
                            inputType: 'nextInspection',
                            title: `${dictionaryPath}.generalDetails.nextInspection`
                        }
                    ]
                },
                {
                    name: 'categories',
                    fields: [fields.categories]
                },
                {
                    title: `${dictionaryPath}.summary.title`,
                    fields: [fields.inspectionSummary]
                },
                {
                    title: `${dictionaryPath}.vehicleStatus.title`,
                    fields: [
                        {
                            ...fields.status,
                            title: `${dictionaryPath}.vehicleStatus.vehicleStatus`
                        },
                        {
                            ...fields.notes,
                            title: `${dictionaryPath}.vehicleStatus.notes`
                        }
                    ]
                }
            ]
        ]
    }
];

const inspectionAddEditConfig = {
    fields,
    tabs,
    id: 'inspection',
    hiddenFields: {nextInspectionMileage: fields.nextInspectionMileage, nextInspectionDate: fields.nextInspectionDate},
    title: `${dictionaryPath}.title`,
    deleteLabel: `${dictionaryPath}.deleteInspection`,
    categoriesToFetch: ['inspectionStatus'],
    itemsToFetch: [
        {
            basePath: 'branches', fetchItems: [{name: 'branches'}]
        }
    ]
};

export default inspectionAddEditConfig;
