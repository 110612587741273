import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ExpenseOrderService } from '../expense-order.service';
import expenseAddEditOrderConfig from '../expense-add-edit-order-details.component.config';

@Component({
    selector: 'expense-order-item',
    templateUrl: './expense-order-item.component.html',
    styleUrls: ['./expense-order-item.component.scss', './expense-order-item.component.rtl.scss']
})
export class ExpenseOrderItemComponent implements OnInit {
    @Input() orderItemForm: FormGroup;
    @Output() addItemEvent = new EventEmitter();
    @Output() removeItemEvent = new EventEmitter();

    expenseOrderItemConfig = expenseAddEditOrderConfig.expenseOrderItemConfig;
    totalRowPrice: number;

    constructor(public expenseOrderService: ExpenseOrderService) { }

    ngOnInit(): void {
        this.subscribeToTotalRowPriceChange();
    }

    subscribeToTotalRowPriceChange(): void {
        this.quantity.valueChanges.subscribe(() => this.setTotalRowPrice());
        this.pricePerUnit.valueChanges.subscribe(() => this.setTotalRowPrice());
    }

    setTotalRowPrice() {
        this.totalRowPrice = this.expenseOrderService.calculateTotalRowPrice(this.quantity.value, this.pricePerUnit.value);
        this.expenseOrderService.recalculateSumRows();
    }

    public get pricePerUnit(): FormControl {
        return this.orderItemForm.get('pricePerUnit') as FormControl;
    }

    public get quantity(): FormControl {
        return this.orderItemForm.get('quantity') as FormControl;
    }

    addItem(): void {
        this.addItemEvent.emit();
    }

    removeItem(): void {
        this.removeItemEvent.emit();
        this.expenseOrderService.recalculateSumRows();
    }
}
