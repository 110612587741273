import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../../shared/services/api/api.service';
import { TokenService } from './token.service';
import { AuthActions } from '../../store/auth/auth.actions';
import apiServiceConfig from '../../shared/services/api/api.service.config';
import appConfig from '../../app.config';
import { environment } from '../../../environments/environment';

class User {
    user = {};
    token = '';
}

@Injectable()
export class AuthService {
    private paths: any = apiServiceConfig.paths.auth;
    private currentUserSubject = new BehaviorSubject<User>(new User());
    private isAuthenticatedSubject = new ReplaySubject<boolean>(1);

    constructor(
        private apiService: ApiService,
        private tokenService: TokenService,
        private router: Router,
        private authActions: AuthActions,
        private bsModalService: BsModalService
    ) {
    }

    init() {
        if (this.tokenService.getToken()) {
            this.apiService.get(this.paths.basePath).subscribe(
                user => this.setAuth(user),
                err => this.purgeAuth()
            );
        } else {
            this.purgeAuth();
        }
    }

    setAuth(user: any) {
        const token = this.tokenService.getToken();
        user.role = user.role.substring(user.role.indexOf('.') + 1);

        this.authActions.authUser({ user, token });
        this.currentUserSubject.next(user);
        this.isAuthenticatedSubject.next(true);
    }

    purgeAuth() {
        this.currentUserSubject.next(new User());
        this.isAuthenticatedSubject.next(false);
    }

    login(credentials: object): Observable<any> {
        return this.apiService.postUrlEncoded(this.paths.basePath + this.paths.login, credentials)
            .pipe(
                map(res => {
                    this.tokenService.saveToken(res.accessToken);
                    this.apiService.get(this.paths.basePath)
                        .subscribe(
                            user => {
                                this.setAuth(user);
                                this.router.navigate([appConfig.defaultRoutes.homePage[user.role.toLowerCase()] || appConfig.defaultRoutes.default]);
                            },
                            err => this.purgeAuth()
                        );

                    return res;
                })
            );
    }

    newUserLogin(username: string) {
        return this.apiService.postUrlEncoded(this.paths.basePath + this.paths.newUserLogin, { username });
    }

    verifyCode(params: any): Observable<any> {
        return this.apiService.postUrlEncoded(this.paths.basePath + this.paths.verifyCode, params);
    }

    forgotPassword(username: string): Observable<any> {
        return this.apiService.postUrlEncoded(this.paths.basePath + this.paths.forgotPassword, { username });
    }

    changePassword(params: any): Observable<any> {
        return this.apiService.postUrlEncoded(this.paths.basePath + this.paths.changePassword, params);
    }

    update(user: string): Observable<User> {
        return this.apiService
            .put(this.paths.update, { user })
            .pipe(map(data => {
                this.currentUserSubject.next(data.user);
                return data.user;
            })
            );
    }

    closeAllModals() {
        for (let i = 1; i <= this.bsModalService.getModalsCount(); i++) {
            this.bsModalService.hide(i);
        }
    }

    logout() {
        this.closeAllModals();
        this.tokenService.destroyToken();
        this.router.navigate([appConfig.defaultRoutes.unauthorized]);
    }

    switchRoleInfo() {
        return this.apiService.get(this.paths.basePath + this.paths.switchRoleInfo);
    }

    switchRole(customerId, role) {
        return this.apiService.postUrlEncoded(this.paths.basePath + this.paths.switchRole,
            {
                CustomerId: customerId,
                Role: role,
                ClientId: environment.clientId
            }).pipe(map(tokenInfo =>
                this.tokenService.saveToken(tokenInfo.accessToken)
            ));
    }
}
