import { DocumentsPageComponent } from '../../../../../../shared/components/documents-page/documents-page.component';

const dictionaryPath = 'settings.licenses';

const fields = {
    documents: {
        name: 'documents',
        inputType: 'documents',
        arrayField: true,
        outsideDelete: true,
        bodyComponent: DocumentsPageComponent
    }
};

const settingsLicensesConfig = {
    fields,
    title: `${dictionaryPath}.title`,
    columns: {
        documents: {
            isArray: true,
            fields: [fields.documents],
            title: `${dictionaryPath}.businessLicenses`
        }
    }
};

export default settingsLicensesConfig;
