import { Component, OnInit, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import alertsPageConfig from './alerts-page.component.config';
import { BaseService } from '../../../shared/services/api/base.service';
import { LocalizationService } from 'src/app/shared';
import { TablePageService } from 'src/app/shared/components/table-page/table-page.service';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-alerts',
    templateUrl: './alerts-page.component.html',
    styleUrls: ['./alerts-page.component.scss', './alerts-page.component.rtl.scss']
})
export class AlertsPageComponent implements OnInit, AfterContentInit {
    rows: any[] = [];
    alertsFeedVisible = true;
    alertsPageConfig: any = alertsPageConfig;

    constructor(private cdRef: ChangeDetectorRef,
        private router: Router,
        private baseService: BaseService,
        public localizationService: LocalizationService,
        private tablePageService: TablePageService, private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.baseService.setPaths('alerts');
        this.fetchAlerts();
        this.onLanguageChange();
        this.subscribeToRouteChange();
    }

    subscribeToRouteChange(): void {
        this.router.events.filter(event => event instanceof NavigationEnd)
          .subscribe(() => this.alertsFeedVisible = true);
    }

    fetchAlerts(): void {
        this.baseService.getAll().subscribe(alerts => this.rows = alerts);
    }

    public get selectedRows(): any[] {
        return this.tablePageService.selectedRows;
    }

    ngAfterContentInit(): void {
        this.cdRef.detectChanges();
    }

    onLanguageChange(): void {
        this.translate.onLangChange.subscribe(() => this.fetchAlerts());
    }
}
