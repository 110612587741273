<header [style.zoom]="scalingService.getScaling()"
        [style.transform]="scalingService.getScaling()"
        [style.transform-origin]="scalingService.getTransformOriginBottom()">
    <app-top-menu></app-top-menu>
</header>

<div class="main-content"
     [style.zoom]="scalingService.getScaling()"
     [style.transform]="scalingService.getScaling()"
     [style.transform-origin]="scalingService.getTransformOriginTop()">
    <app-side-menu class="side-menu" [ngClass]="{'collapsed-menu': appSideMenuService.isMenuCollapsed ? appSideMenuService.isMenuCollapsed : false}"></app-side-menu>
    <div class="router-outlet-container">
        <router-outlet></router-outlet>
    </div>
</div>
