import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FormsService } from '../../../shared/services';
import { InspectionService } from '../inspection.service';
import { CommonService } from '../../../shared/services/common.service';
import { MetadataService } from '../../../shared/services/metadata.service';

@Component({
    selector: 'inspection-categories',
    templateUrl: './inspection-categories.component.html',
    styleUrls: ['./inspection-categories.component.scss']
})
export class InspectionCategoriesComponent implements OnInit {
    @Input() config: any;
    @Input() formObject: FormArray;

    constructor(private formsService: FormsService, private inspectionService: InspectionService, public commonService: CommonService, private metadataService: MetadataService) { }

    ngOnInit(): void {
        this.initCategories();
    }

    initCategories(): void {
        if (!(this.config && this.config.readonly)) {
            this.metadataService.metadataObsObj['inspection'].subscribe(inspection => {
                inspection.categories.forEach(category => {
                    const categoryForm = new FormGroup({});
                    const itemsForm = new FormArray([]);

                    category['items'].forEach(() => itemsForm.push(this.formsService.generateForm([{inputType: 'inspection'}])));
                    itemsForm.patchValue(category['items']);

                    categoryForm.addControl('category', new FormControl(category.category));
                    categoryForm.addControl('items', itemsForm);
                    this.formObject.push(categoryForm);
                });
            })
        }

        this.inspectionService.initCounters(this.formObject);
    }
}
