import { Component, OnInit, Input, ViewChild, TemplateRef, OnChanges } from '@angular/core';
import { ApiService, LocalizationService } from '../../services';
import { BsModalService } from 'ngx-bootstrap';
import { TablePageService } from '../table-page/table-page.service';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { MetadataService } from '../../services/metadata.service';

@Component({
    selector: 'table-element',
    templateUrl: './table-element.component.html',
    styleUrls: ['./table-element.component.scss', './table-element.component.rtl.scss']
})
export class TableElementComponent implements OnInit, OnChanges {
    @Input() rows: any[];
    @Input() config: any;

    @ViewChild('normalCell') public normalCell: TemplateRef<any>;
    @ViewChild('editRowCell') public editRowCell: TemplateRef<any>;
    @ViewChild('openRowModalCell') public openRowModalCell: TemplateRef<any>;

    private progressRef: NgProgressRef;

    constructor(private localizationService: LocalizationService, private apiService: ApiService, private modalService: BsModalService,
                public tablePageService: TablePageService, private progress: NgProgress, private metadataService: MetadataService) {
        this.progressRef = this.progress.ref('progressBar');
    }

    ngOnInit(): void {
        this.setCellTemplates();
        this.setRows();
    }

    ngOnChanges(): void {
        this.setCellTemplates();
        this.setRows();
    }

    setCellTemplates(): void {
        this.config.tableConfig.columns.forEach(col =>
          col['cellTemplate'] = col.cellTemplateName ? this[col.cellTemplateName] : this.normalCell);
    }

    setRows(): void {
        if (this.config.tableConfig.metadataSource) {
            this.metadataService.metadataObsObj[this.config.tableConfig.metadataSource].subscribe(data => this.rows = data);
        }
    }

    isRtl(): boolean {
        return this.localizationService.isRtl();
    }

    openRowModal(row: any, openRowModalComponent: Component, basePath: string): void {
        this.progressRef.start();
        this.apiService.get(basePath, {id: row.id}).subscribe(data => {
            this.progressRef.complete();
            this.modalService.show(
                openRowModalComponent, {
                    class: 'u-modal u-modal_content',
                    animated: true,
                    keyboard: false,
                    ignoreBackdropClick: true,
                    initialState: {data}
                });
        });
    }
}
