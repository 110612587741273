<div class="settings-tab-container">
  <div class="settings-header-container">
    <span class="settings-page-title">{{'settings.pageTitle' | translate}}</span>
    <span class="settings-tab-title" *ngIf="tabConfig['title'] || tabConfig['tabTitle']">{{tabConfig['title'] || tabConfig['tabTitle'] | translate}}</span>
  </div>
  <div class="settings-tab-columns-container" [ngClass]="{'flex-column': tabConfig['direction'] === 'column'}">
    <div class="settings-tab-column-container" [ngClass]="{ 'full-width-column': column.value['fullWidth']}"
         *ngFor="let column of tabConfig.columns | keyvalue:commonService.asIsOrder">
      <div class="settings-body-header-container" *ngIf="column.value['title']">
        <div class="u-text-line u-text-line_border-off settings-body-title">
          <span class="settings-body-title-text">{{column.value['title'] | translate}}</span>
          <span class="settings-body-title-counter"
              *ngIf="column.value.showCounter">({{column.value.controlsLength}})</span>
        </div>
        <span class="u-btn-simple add-new-btn" *ngIf="tabConfig.showAddNew"
              (click)="emitAddNewField()">{{'settings.addNew' | translate}}</span>
      </div>
      <div class *ngIf="!column.value.isArray && tabForm">
        <div class="settings-field" *ngFor="let field of column.value['fields']"
             [ngClass]="{'full-height-field': field['bodyComponent'], 'full-width-field': field['fullWidth']}">
          <dynamic-field [field]="field" [formObject]="tabForm" [addNewFieldObs]="addNewFieldObs"></dynamic-field>
        </div>
      </div> 
      <div class *ngIf="column.value.isArray && tabForm.controls[column.key] && tabForm.controls[column.key].controls">
        <div class="settings-field" *ngFor="let formControl of tabForm.controls[column.key].controls; let i = index;"
             [ngClass]="{'full-height-field': column.value['fields'][i]['bodyComponent'], 'full-width-field':column.value['fields'][i]['fullWidth']}">
          <dynamic-field [field]="column.value['fields'][i]" [formObject]="formControl" [addNewFieldObs]="addNewFieldObs"></dynamic-field>
        </div>
      </div>
    </div>
  </div>
</div>
