<div class="app-add-btn-container" *ngIf="userRoleName">
  <button *ngIf="addBtnCurrentConfig && addBtnCurrentConfig['addEditModal']"
          (click)="openAddEditModal()"
          class="header-add-btn">
  </button>
  <button *ngIf="addBtnCurrentConfig && addBtnCurrentConfig['addPopoverItems']"
          [uPopover]="addPopover"
          [placement]="'bottom'"
          [autoClose]="true"
          [popoverClass]="'u-popover u-popover_rounded add-btn-popover'"
          [container]="'body'"
          class="header-add-btn">
  </button>
</div>

<ng-template #addPopover>
  <div class="add-btn-popover-item-container"
       *ngFor="let popoverItem of addBtnCurrentConfig['addPopoverItems'] | keyvalue:commonService.asIsOrder"
       (click)="openAddEditModal(popoverItem.value['addEditModal'], popoverItem.key)"
       [ngClass]="{'disabled-item': !popoverItem.value['addEditModal']}">
    <div class="add-btn-popover-item" *ngIf="popoverItem.value.permittedUserRoles ? popoverItem.value.permittedUserRoles.indexOf(userRoleName) >= 0 : true">
      <img class="add-btn-popover-item__icon" [src]="popoverItem.value.icon" alt="add"/>
      <span class="add-btn-popover-item__title">{{popoverItem.value.title | translate}}</span>
    </div>
  </div>
</ng-template>
