import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InspectionAddEditComponent } from './inspection-add-edit/inspection-add-edit.component';
import { AddEditModalModule } from '../../shared/modules/add-edit-modal/add-edit-modal.module';
import { InspectionSummaryComponent } from './inspection-summary/inspection-summary.component';
import { TranslateModule } from '@ngx-translate/core';
import { InspectionCategoriesComponent } from './inspection-categories/inspection-categories.component';
import { InputsModule } from '../inputs/inputs.module';
import { UCommonModule } from '@shift/ulib';

@NgModule({
    imports: [
        CommonModule,
        AddEditModalModule,
        TranslateModule,
        InputsModule,
        UCommonModule
    ],
    declarations: [
        InspectionAddEditComponent,
        InspectionSummaryComponent,
        InspectionCategoriesComponent
    ],
    entryComponents: [
        InspectionAddEditComponent,
        InspectionSummaryComponent,
        InspectionCategoriesComponent
    ]
})
export class InspectionsModule { }
