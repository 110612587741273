<!--TODO: fix this on readonly mode-->
<div class="inspection-categories-container" *ngIf="formObject && formObject.controls.length > 0">
  <div class="inspection-category-container" *ngFor="let category of formObject.controls;">
    <div class="inspection-category-title ctrl-section-title u-text-line u-text-line_border-off">{{category.value.category | translate}}</div>
    <div class="inspection-category-input field" *ngFor="let item of category['controls']['items'].controls | keyvalue:commonService.asIsOrder">
      <u-group *ngIf="item" [name]="item.value.controls['type'].value | translate" class="general-field-group">
        <inspection-input [formObject]="item.value" [readonly]="config && config.readonly"></inspection-input>
      </u-group>
    </div>
  </div>
</div>
