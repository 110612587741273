const historyPageConfig = {
    tableConfig: {
        columns: [
            {
                prop: 'type',
                name: 'historyTable.changeType',
                minWidth: 120,
                maxWidth: 240
            },
            {
                prop: 'content',
                name: 'historyTable.changeContent',
                minWidth: 120,
                maxWidth: 240
            },
            {
                prop: 'author',
                name: 'historyTable.changePerformer',
                filter: 'text',
                minWidth: 120,
                maxWidth: 240
            },
            {
                prop: 'changeDate',
                name: 'historyTable.changeDate',
                minWidth: 120,
                maxWidth: 240
            },
            {
                prop: 'changeTime',
                name: 'historyTable.changeTime',
                minWidth: 120,
                maxWidth: 240
            }
        ]
    }
};

export default historyPageConfig;
