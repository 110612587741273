import { AddEditUsersComponent } from '../../../../../../shared/components/add-edit-users/add-edit-users.component';
import inputsConfig from '../../../../../inputs/ctrl-inputs.config';

const fields = {
    managers: {
        name: 'managers',
        fullWidth: true,
        inputConfig: inputsConfig.managers,
        config: {
            userDetailsConfig: {
                fields: [
                    {
                        name: 'firstName',
                        required: true,
                        inputType: 'text',
                        title: `addEditUsers.userDetails.firstName`
                    },
                    {
                        name: 'lastName',
                        required: true,
                        inputType: 'text',
                        title: `addEditUsers.userDetails.lastName`
                    },
                    {
                        name: 'receiveEmails',
                        inputType: 'toggle',
                        defaultValue: true,
                        title: 'settings.managers.receiveEmails'
                    },
                    {
                        name: 'role',
                        required: true,
                        inputType: 'label',
                        translate: true,
                        defaultValue: 'roleType.manager',
                        title: `addEditUsers.userDetails.role`
                    },
                    {
                        name: 'contacts',
                        required: true,
                        arrayField: true,
                        inputType: 'contacts',
                        title: `addEditUsers.userDetails.contacts`
                    }
                ]
            }
        },
        bodyComponent: AddEditUsersComponent
    }
};

const settingsManagersConfig = {
    fields,
    notNestedForm: true,
    tabTitle: 'settings.managers.title',
    columns: {
        managers: {
            fullWidth: true,
            fields: [fields.managers]
        }
    }
};

export default settingsManagersConfig;
