import { ExpenseAddEditOrderDetailsComponent } from './expense-add-edit-order-details/expense-add-edit-order-details.component';

const dictionaryPath = 'expenses.addEditExpense';

const fields = {
    jobOrderDate: {
        name: 'jobOrderDate',
        required: true,
        inputType: 'datepicker'
    },
    orderNumber: {
        name: 'orderNumber',
        required: true,
        inputType: 'number',
        valueType: 'string'
    },
    classification: {
        name: 'classification',
        required: true,
        inputType: 'text'
    },
    name: {
        name: 'name',
        inputType: 'text'
    },
    role: {
        name: 'role',
        inputType: 'select',
        inputOptionsVar: 'roles'
    },
    mobile: {
        name: 'mobile',
        inputType: 'number'
    },
    branchId: {
        name: 'branchId',
        inputType: 'text'
    },
    supplier: {
        name: 'supplier',
        inputType: 'select',
        inputOptionsVar: 'suppliers',
    },
    //TODO: something is wrong here with address input. see errors in console
    address: {
        name: 'address',
        inputType: 'address',
        readonly: true
    },
    phone: {
        name: 'phone',
        inputType: 'number',
        readonly: true
    },
    invoiceNumber: {
        name: 'invoiceNumber',
        inputType: 'number',
        readonly: true
    },
    vehicleLicenseNumber: {
        name: 'vehicleLicenseNumber',
        inputType: 'select',
        inputOptionsVar: 'vehicles'
    },
    manufacturer: {
        name: 'manufacturer',
        inputType: 'text',
        readonly: true
    },
    model: {
        name: 'model',
        inputType: 'text',
        readonly: true
    },
    currentSpeedometer: {
        name: 'currentSpeedometer',
        inputType: 'number',
        readonly: true
    },
    orderDetails: {
        groupField: true,
        name: 'orderDetails',
        bodyComponent: ExpenseAddEditOrderDetailsComponent
    }
};

const tabs = [
    {
        id: 'info',
        notNestedForm: true,
        title: `${dictionaryPath}.info.title`,
        columns: [
            [
                {
                    title: `${dictionaryPath}.info.generalDetails.title`,
                    fields: [
                        {
                            ...fields.jobOrderDate,
                            title: `${dictionaryPath}.info.generalDetails.jobOrderDate`
                        },
                        {
                            ...fields.orderNumber,
                            title: `${dictionaryPath}.info.generalDetails.orderNumber`
                        },
                        {
                            ...fields.classification,
                            title: `${dictionaryPath}.info.generalDetails.classification`
                        }
                    ]
                },
                {
                    title: `${dictionaryPath}.info.invitingInformation.title`,
                    fields: [
                        {
                            ...fields.name,
                            title: `${dictionaryPath}.info.invitingInformation.name`
                        },
                        {
                            ...fields.role,
                            title: `${dictionaryPath}.info.invitingInformation.role`
                        },
                        {
                            ...fields.mobile,
                            title: `${dictionaryPath}.info.invitingInformation.mobile`
                        },
                        {
                            ...fields.branchId,
                            title: `${dictionaryPath}.info.invitingInformation.branchId`
                        }
                    ]
                }
            ],
            [
                {
                    title: `${dictionaryPath}.info.supplierDetails.title`,
                    fields: [
                        {
                            ...fields.supplier,
                            title: `${dictionaryPath}.info.supplierDetails.supplier`
                        },
                        {
                            ...fields.address,
                            title: `${dictionaryPath}.info.supplierDetails.address`
                        },
                        {
                            ...fields.phone,
                            title: `${dictionaryPath}.info.supplierDetails.phone`
                        },
                        {
                            ...fields.invoiceNumber,
                            title: `${dictionaryPath}.info.supplierDetails.invoiceNumber`
                        }
                    ]
                },
                {
                    title: `${dictionaryPath}.info.vehicleDetails.title`,
                    fields: [
                        {
                            ...fields.vehicleLicenseNumber,
                            title: `${dictionaryPath}.info.vehicleDetails.vehicleLicenseNumber`
                        },
                        {
                            ...fields.manufacturer,
                            title: `${dictionaryPath}.info.vehicleDetails.manufacturer`
                        },
                        {
                            ...fields.model,
                            title: `${dictionaryPath}.info.vehicleDetails.model`
                        },
                        {
                            ...fields.currentSpeedometer,
                            title: `${dictionaryPath}.info.vehicleDetails.currentSpeedometer`
                        }
                    ]
                }
            ]
        ],
        //TODO: find a solution for this. current ctrlFormsService does not support rows.
        rows: [
            {
                title: `${dictionaryPath}.info.orderDetails.title`,
                fields: [fields.orderDetails]
            }
        ]
    }
];

const expenseAddEditModalConfig = {
    fields,
    tabs,
    title: 'expenses.addEditExpense.title',
    longTitle: true,
    deleteLabel: 'expenses.addEditExpense.deleteExpense',
    apiNotReady: true, // TODO remove when api ready,
    categoriesToFetch: ['roles', 'manufacturers', 'suppliers', 'vehicles']
};

export default expenseAddEditModalConfig;
