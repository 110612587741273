import { Component, ElementRef, Injectable } from '@angular/core';
import { BaseService } from '../../services/api/base.service';
import { AddEditModalService } from '../../modules/add-edit-modal/services/add-edit-modal.service';
import { ApiService, GlobalSearchService } from '../../services';
import { UGridComponent } from '@shift/ulib';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { FormControl } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class TablePageService {
    public rows: any[];
    public searchRows: any[];
    public visibleRows: any[];
    public tableConfig: any;
    public specificRowClassObjects: any[];
    public selectedRows: any[] = [];
    public tableElement: ElementRef<UGridComponent>;
    private progressRef: NgProgressRef;

    constructor(private baseService: BaseService, private addEditModalService: AddEditModalService,
        private searchService: GlobalSearchService, private apiService: ApiService, private progress: NgProgress) {
        this.searchListener();
        this.progressRef = this.progress.ref('progressBar');
    }

    initRows(rows?: any[]): void {
        this.visibleRows = [];
        this.rows = rows || this.rows || [];
        this.visibleRows = this.rows;
        if (this.tableElement) {
            // @ts-ignore - u-grid function
            this.tableElement.updateRows();
        }
    }

    deleteRow(id: number): void {
        this.rows = this.rows.filter(row => row.id !== id);
        this.initRows();
    }

    addRow(newRow: {}): void {
        this.rows.push(newRow);
        this.initRows();
    }

    replaceRow(id: number, newRow: {}): void {
        const rowsArrayIndex = this.rows.findIndex(row => row.id === id);
        if (rowsArrayIndex >= 0) {
            this.rows[rowsArrayIndex] = Object.assign(this.rows[rowsArrayIndex], newRow);
            this.initRows();
        }
    }

    editRow(row, modalComponent: Component, basePath?: string): void {
        this.progressRef.start();

        this.addEditModalService.isEditMode = true;
        if (basePath) {
            this.apiService.get(basePath, {id: row.id}).subscribe(data => {
                this.openAddEditModal(modalComponent, data);
            });
        } else {
            this.baseService.byId(row.id)
              .subscribe(editData => this.openAddEditModal(modalComponent, editData),
                () => this.progressRef.complete()
        );
        }
    }

    openAddEditModal(modalComponent, editData?, subscribeForChanges: boolean = true): void {
        this.progressRef.complete()
        const modalRef = this.addEditModalService.openModal(modalComponent, editData);

        if (subscribeForChanges) {
            modalRef.content.action.subscribe(({ type, data }) => {
                const newRowObj = {};
                this.tableConfig.columns.forEach(col => {
                    if (col.noValue) {
                        return;
                    }
                    if (col.formControl) {
                        newRowObj[col.prop] = new FormControl(data[col.prop]);
                        return;
                    }
                    newRowObj[col.prop] = data[col.prop];
                });
                newRowObj['id'] = data.id;

                switch (type) {
                    case 'add':
                        this.rows = [newRowObj, ...this.rows];
                        this.highlightRow(newRowObj['id']);
                        break;
                    case 'edit':
                        this.rows = this.rows.map(row => {
                            if (row.id === data.id) {
                                return {
                                    ...row,
                                    ...newRowObj
                                }
                            }
                            return { ...row };
                        });
                        this.highlightRow(newRowObj['id']);
                        break;
                    case 'delete':
                        this.rows = this.rows.filter(row => row.id !== data.id);
                        break;
                }
                this.visibleRows = this.rows;
            });
        }
    }

    private searchListener(): void {
        this.searchService.searchQuery.subscribe(query => {
            if (query) {
                const searchArray = [];
                this.rows.forEach(row => {
                    const searchResult = Object.values(row).filter(val => {
                        let value = val;
                        while (value && value['value']) {
                            value = value['value'];
                        }
                        return value && value.toString().toLowerCase().includes(query.toString().toLowerCase());
                    });
                    if (searchResult.length > 0) {
                        searchArray.push(row);
                    }
                });
                this.searchRows = searchArray;
                this.visibleRows = this.searchRows;
            } else {
                this.searchRows = [];
                this.visibleRows = this.rows;
            }
        });
    }

    highlightRow(rowId: number): void {
        this.specificRowClassObjects = [{
            className: 'highlighted-row',
            rowPropertyName: 'id',
            value: rowId
        }];

        setTimeout(() => this.specificRowClassObjects = [], 3000);
    }
}
