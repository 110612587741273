import { Component, OnInit } from '@angular/core';
import { TablePageService } from '../../../../shared/components/table-page/table-page.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import addBtnConfig from './app-add-btn.component.config';
import { CommonService } from '../../../../shared/services/common.service';

@Component({
    selector: 'app-add-btn',
    templateUrl: './app-add-btn.component.html',
    styleUrls: ['./app-add-btn.component.scss', './app-add-btn.component.rtl.scss']
})
export class AppAddBtnComponent implements OnInit {
    @select(['auth', 'user', 'role'])
    public readonly userRole: Observable<any>;

    private currentUrl: string;

    public userRoleName: any;
    public addBtnCurrentConfig: {};

    constructor(private router: Router, private tablePageService: TablePageService, public commonService: CommonService) { }

    ngOnInit(): void {
        this.setUserRole();
        this.onRouteChange();
        this.setCurrentRoute();
    }

    setUserRole(): void {
        this.userRole.subscribe(role => this.userRoleName = role);
    }

    onRouteChange(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
          .subscribe(() => this.setCurrentRoute());
    }

    setCurrentRoute(): void {
        this.currentUrl = this.router.url.substr(1);
        this.setCurrentRouteAddConfig(this.currentUrl);
    }

    openAddEditModal(addEditModal = this.addBtnCurrentConfig['addEditModal'], popoverItemKey?: string): void {
        if (addEditModal) {
            this.tablePageService.openAddEditModal(addEditModal, undefined, popoverItemKey ? popoverItemKey === this.currentUrl : true);
        }
    }

    setCurrentRouteAddConfig(route: string): void {
        this.addBtnCurrentConfig = addBtnConfig[route] || {};
    }
}
