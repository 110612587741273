export default {
    alert: {
        fields: {
            type: {
                name: 'type'
            },
            periodUnit: {
                name: 'periodUnit'
            },
            amount: {
                name: 'amount'
            }
        }
    },
    address: {
        generateFormControls: false,
        fields: {
            fullAddress: {
                name: 'fullAddress'
            },
            latitude: {
                name: 'latitude'
            },
            longitude: {
                name: 'longitude'
            }
        }
    },
    categories: {
        arrayField: true,
        fields: {
            items: {
                name: 'items',
                inputType: 'inspection'
            },
            category: {
                name: 'category'
            }
        }
    },
    nextInspection: {
        noParent: true,
        fields: {
            nextInspectionMileage: {
                name: 'nextInspectionMileage'
            },
            nextInspectionDate: {
                name: 'nextInspectionDate'
            }
        }
    },
    inspection: {
        arrayField: true,
        fields: {
            type: {
                name: 'type'
            },
            ok: {
                name: 'ok',
                defaultValue: true
            },
            comment: {
                name: 'comment'
            },
            images: {
                name: 'images',
                arrayField: true,
                inputType: 'file'
            },
            documents: {
                name: 'documents',
                arrayField: true,
                inputType: 'file'
            }
        }
    },
    contactGroupsNoRole: {
        arrayField: true,
        generateFormControls: false,
        fields: {
            name: {
                name: 'name',
                required: true
            },
            id: {
                name: 'id',
                defaultValue: 0
            },
            contacts: {
                name: 'contacts',
                arrayField: true,
                inputType: 'contacts'
            }
        }
    },
    contactGroups: {
        arrayField: true,
        generateFormControls: false,
        fields: {
            name: {
                name: 'name',
                required: true
            },
            role: {
                name: 'role',
                required: true
            },
            id: {
                name: 'id',
                defaultValue: 0
            },
            contacts: {
                name: 'contacts',
                arrayField: true,
                inputType: 'contacts'
            }
        }
    },
    contacts: {
        arrayField: true,
        fields: {
            type: {
                name: 'type'
            },
            contact: {
                name: 'contact'
            }
        }
    },
    file: {
        fields: {
            fileSrc: {
                name: 'fileSrc'
            },
            fileId: {
                name: 'fileId'
            },
            fileName: {
                name: 'fileName'
            }
        }
    },
    fileExpiration: {
        fields: {
            fileSrc: {
                name: 'fileSrc'
            },
            fileId: {
                name: 'fileId'
            },
            fileName: {
                name: 'fileName'
            },
            expirationDate: {
                name: 'expirationDate'
            }
        }
    },
    customDocuments: {
        fields: {
            name: {
                name: 'name'
            },
            fileId: {
                name: 'fileId'
            },
            fileName: {
                name: 'fileName'
            },
            expirationDate: {
                name: 'expirationDate'
            }
        }
    },
    documents: {
        arrayField: true,
        fields: {
            name: {
                name: 'name'
            },
            type: {
                name: 'type'
            },
            fileId: {
                name: 'fileId'
            },
            fileName: {
                name: 'fileName'
            },
            expirationDate: {
                name: 'expirationDate'
            }
        }
    },
    users: {
        arrayField: true,
        fields: {
            id: {
                name: 'id'
            },
            firstName: {
                name: 'firstName'
            },
            lastName: {
                name: 'lastName'
            },
            role: {
                name: 'role'
            },
            deletable: {
                name: 'deletable'
            },
            contacts: {
                name: 'contacts',
                arrayField: true,
                inputType: 'contacts'
            }
        }
    },
    managers: {
        arrayField: true,
        fields: {
            id: {
                name: 'id'
            },
            firstName: {
                name: 'firstName'
            },
            lastName: {
                name: 'lastName'
            },
            role: {
                name: 'role'
            },
            deletable: {
                name: 'deletable'
            },
            contacts: {
                name: 'contacts',
                arrayField: true,
                inputType: 'contacts'
            },
            receiveEmails: {
                name: 'receiveEmails',
                inputType: 'toggle',
                defaultValue: true
            }
        }
    },
    departments: {
        arrayField: true,
        fields: {
            id: {
                name: 'id',
                defaultValue: 0
            },
            name: {
                name: 'name'
            }
        }
    }
};
