import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsPageComponent } from './alerts-page/alerts-page.component';
import { UCommonModule, UGridModule, USplitLayoutModule, UTooltipModule } from '@shift/ulib';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { AlertsFeedComponent } from './alerts-page/alerts-feed/alerts-feed.component';
import { AlertsService } from './alerts.service';
import { AlertExpirationPopoverComponent } from './alerts-page/alert-expiration-popover/alert-expiration-popover.component';
import { DynamicElementsModule } from '../../shared/modules/dynamic-elements/dynamic-elements.module';

@NgModule({
    imports: [
        CommonModule,
        USplitLayoutModule,
        UGridModule,
        UCommonModule,
        TranslateModule,
        UTooltipModule,
        SharedModule,
        DynamicElementsModule
    ],
    declarations: [
        AlertsPageComponent,
        AlertsFeedComponent,
        AlertExpirationPopoverComponent
    ],
    exports: [
        AlertExpirationPopoverComponent
    ],
    providers: [
        AlertsService
    ]
})
export class AlertsModule {
}
