<div class="popover-cell">
    <span [uPopover]="pendingPopover || !popoverCellService.isAnyPopoverOpen ? popover : ''"
          [placement]="isRtl() ? [ 'right-top', 'right-center', 'right-bottom' ] : [ 'left-top', 'left-center', 'left-bottom' ]"
          [autoClose]="false"
          [container]="'body'"
          [popoverClass]="'u-popover u-popover_rounded'"
          #popoverElement="uPopover"
          class="cell-value">{{cell.value || cell}}</span>
</div>
<ng-template #popover>
  <div class="popover-container" (clickOutside)="closePopover(popoverElement)" [delayClickOutsideInit]="true">
    <span class="popover-close-btn" (click)="closePopover(popoverElement)"></span>
    <dynamic-component [formObject]="popoverForm" [component]="config.popoverComponent" [data]="data" [onSubmit]="submitEvent"></dynamic-component>
    <button class="u-btn u-btn-simple popover-save" (click)="savePopover(popoverElement)" [disabled]="!popoverForm.valid" [ngClass]="{'u-btn-disabled': !popoverForm.valid}">{{'general.save' | translate}}</button>
  </div>
</ng-template>
