const dictionaryPath = 'vehicles.addEditVehicle.generalDetails.documents';

const vehicleAddEditDocumentsConfig = {
    title: `${dictionaryPath}.title`,
    addNewDocument: `${dictionaryPath}.addNewDocument`,
    fields: [
        {
            name: 'vehicleLicense',
            inputType: 'fileExpiration',
            title: `${dictionaryPath}.vehicleLicense`
        },
        {
            name: 'vehicleInsurance',
            inputType: 'fileExpiration',
            title: `${dictionaryPath}.vehicleInsurance`
        },
        {
            name: 'brakesApproval',
            inputType: 'fileExpiration',
            title: `${dictionaryPath}.brakesApproval`
        },
        {
            name: 'winterInspection',
            inputType: 'fileExpiration',
            title: `${dictionaryPath}.winterInspection`
        },
        {
            name: 'certifiedForStudentsTransportation',
            inputType: 'fileExpiration',
            title: `${dictionaryPath}.certifiedForStudentsTransportation`
        }
    ]
};

export default vehicleAddEditDocumentsConfig;
