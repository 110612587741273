import { CustomersPageComponent } from '../modules/customers/customers-page/customers-page.component';
import { AlertsPageComponent } from '../modules/alerts/alerts-page/alerts-page.component';
import { NotFoundPageComponent } from '../shared/components/not-found-page/not-found-page.component';
import { ReportsPageComponent } from '../modules/reports/reports-page/reports-page.component';
import { LoginPageComponent } from '../modules/auth/login-page/login-page.component';
import { AuthGuardNo } from './auth-guard-no.service';
import { SuppliersPageComponent } from '../modules/suppliers/suppliers-page/suppliers-page.component';
import { DriversPageComponent } from '../modules/drivers/drivers-page/drivers-page.component';
import { SettingsPageComponent } from '../modules/settings/settings-page/settings-page.component';
import { VehiclesPageComponent } from '../modules/vehicles/vehicles-page/vehicles-page.component';
import { ExpensesPageComponent } from '../modules/expenses/expenses-page/expenses-page.component';
import { EventsPageComponent } from '../modules/events/events-page/events-page.component';
import { SafetyOfficersPageComponent } from '../modules/safety-officers/safety-officers-page/safety-officers-page.component';
import appConfig from '../app.config';

const defaultRoutes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/alerts'
    },
    {
        path: 'alerts',
        component: AlertsPageComponent,
        data: {
            title: 'alerts.pageTitle'
        }
    },
    // {
    //     path: 'expenses',
    //     component: ExpensesPageComponent,
    //     data: {
    //         title: 'expenses.pageTitle'
    //     }
    // },
    {
        path: 'events',
        component: EventsPageComponent,
        data: {
            title: 'events.pageTitle'
        }
    },
    {
        path: 'drivers',
        component: DriversPageComponent,
        data: {
            title: 'drivers.pageTitle'
        }
    },
    {
        path: 'vehicles',
        component: VehiclesPageComponent,
        data: {
            title: 'vehicles.pageTitle'
        }
    },
    {
        path: 'suppliers',
        component: SuppliersPageComponent,
        data: {
            title: 'suppliers.pageTitle'
        }
    },
    {
        path: 'safetyOfficers',
        component: SafetyOfficersPageComponent,
        data: {
            title: 'safetyOfficers.pageTitle'
        }
    },
    {
        path: 'reports',
        component: ReportsPageComponent,
        data: {
            showSearch: false,
            title: 'reports.pageTitle'
        }
    }
];

export const routesConfig = {
    admin: [
        {
            path: '',
            pathMatch: 'full',
            redirectTo: '/customers'
        },
        {
            path: 'customers',
            component: CustomersPageComponent,
            data: {
                title: 'customers.pageTitle'
            }
        }
    ],
    common: [{
        path: 'settings',
        component: SettingsPageComponent,
        data: {
            showSearch: false,
            showFeed: false,
            title: 'settings.pageTitle',
            allowedRoles: appConfig.settings.allowedRoles
        }
    }
    ],
    manager: defaultRoutes,
    trainer: defaultRoutes,
    customer: defaultRoutes,
    safetyOfficer: defaultRoutes,
    login: {
        path: 'login',
        component: LoginPageComponent,
        canActivate: [AuthGuardNo],
        data: {
            title: 'loginPage.pageTitle'
        }
    },
    notFound: {
        path: '**',
        component: NotFoundPageComponent,
        data: {
            title: 'notFoundPage.pageTitle'
        }
    }
};
