import { Component, OnInit } from '@angular/core';
import driversPageConfig from './drivers-page.component.config';
import { BaseService } from 'src/app/shared/services/api/base.service';

@Component({
    selector: 'drivers-page',
    templateUrl: './drivers-page.component.html',
    styleUrls: ['./drivers-page.component.scss', './drivers-page.component.rtl.scss']
})
export class DriversPageComponent implements OnInit {
    rows: any[] = [];
    editData: any = null;
    driversPageConfig: any = driversPageConfig;

    constructor(private baseService: BaseService) { }

    ngOnInit(): void {
        this.baseService.setPaths('drivers');
        this.fetchDrivers();
    }

    fetchDrivers(): void {
        this.baseService.getAll().subscribe(drivers => this.rows = drivers);
    }
}
