import { AddEditUsersComponent } from '../../../../../shared/components/add-edit-users/add-edit-users.component';
import inputsConfig from '../../../../inputs/ctrl-inputs.config';

const dictionaryPath = 'settings.admins';

const fields = {
    admins: {
        name: 'admins',
        arrayField: true,
        fullWidth: true,
        inputConfig: inputsConfig.users,
        bodyComponent: AddEditUsersComponent,
        config: {
            dictionary: {
                name: `${dictionaryPath}.name`,
                title: `${dictionaryPath}.title`,
                addNew: `${dictionaryPath}.addNew`,
                userDetailsTitle: `${dictionaryPath}.adminDetails`,
            },
            userDetailsConfig: {
                id: 'userDetails',
                categoriesToFetch: ['contactType'],
                fields: [
                    {
                        name: 'firstName',
                        required: true,
                        inputType: 'text',
                        title: 'addEditUsers.userDetails.firstName'
                    },
                    {
                        name: 'lastName',
                        required: true,
                        inputType: 'text',
                        title: 'addEditUsers.userDetails.lastName'
                    },
                    {
                        name: 'contacts',
                        required: true,
                        arrayField: true,
                        inputType: 'contacts',
                        title: 'addEditUsers.userDetails.contacts'
                    }
                ]
            },
        }
    }
};

const settingsAdminsConfig = {
    fields,
    noParent: true,
    notNestedForm: true,
    tabTitle: `${dictionaryPath}.title`,
    columns: {
        admins: {
            fullWidth: true,
            fields: [fields.admins]
        }
    }
};

export default settingsAdminsConfig;
