import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import driverAddEditModalConfig from './driver-add-edit.component.config';
import { BaseService } from 'src/app/shared/services/api/base.service';
import cloneDeep from 'lodash/cloneDeep'
import { AddEditModalService } from '../../../shared/modules/add-edit-modal/services/add-edit-modal.service';
import { MetadataService } from '../../../shared/services/metadata.service';
import { CtrlFormsService } from '../../../shared/services/ctrl-forms.service';

@Component({
    selector: 'driver-add-edit',
    templateUrl: './driver-add-edit.component.html',
    styleUrls: ['./driver-add-edit.component.scss', './driver-add-edit.component.rtl.scss']
})
export class DriverAddEditComponent implements OnInit {
    editData: any;
    modalConfig: any;
    addEditForm: FormGroup;

    @Output() action = new EventEmitter();

    constructor(
        private baseService: BaseService,
        private ctrlFormsService: CtrlFormsService,
        private addEditModalService: AddEditModalService,
        private metadataService: MetadataService) {
    }

    ngOnInit(): void {
        this.setModalConfig();
        this.initAddEditForm();
        this.metadataService.setMetadataObs(this.modalConfig);
        this.baseService.setPaths('drivers');

        if (this.editData) {
            this.ctrlFormsService.patchValueNested(this.addEditForm, this.editData, this.modalConfig.fields);
        }
    }

    setModalConfig(): void {
        this.modalConfig = cloneDeep(driverAddEditModalConfig);
        this.modalConfig.tabs = this.editData ? this.modalConfig.editTabs : this.modalConfig.tabs;
    }

    initAddEditForm(): void {
        this.addEditForm = this.ctrlFormsService.generateModalTabsForm(this.modalConfig);
    }

    deleteDriver(): void {
        this.addEditModalService.deleteEntity('drivers.messages.deleteConfirm', this.editData, this.action);
    }

    submitForm = (): void => {
        if (this.addEditModalService.canSubmit(this.addEditForm)) {
            this.addEditModalService.submitForm(this.addEditForm.value, this.action, this.editData);
        }
    };
}
