import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from '../modules/auth/token.service';

@Injectable()
export class AuthGuardNo implements CanActivate {
    constructor(private router: Router, private tokenService: TokenService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (!!this.tokenService.getToken()) {
            this.router.navigate(['/']);
        }

        return true;
    }
}
