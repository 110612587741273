import { AlertExpirationPopoverComponent } from './alert-expiration-popover/alert-expiration-popover.component';

const dictionaryPath = 'alerts';

const alertsPageConfig = {
    alertsFeed: {
        title: `${dictionaryPath}.alertsFeed.title`
    },
    tableConfig: {
        counterLabel: `${dictionaryPath}.table.alerts`,
        tableName: 'alerts',
        columns: [
            {
                prop: 'check',
                name: '',
                width: 50,
                sortable: false,
                canAutoResize: false,
                draggable: false,
                resizeable: false,
                headerCheckboxable: true,
                checkboxable: true,
                custom: true,
                hideable: false,
                hideDivider: true,
                noValue: true
            },
            {
                prop: 'entity',
                translate: true,
                name: `${dictionaryPath}.table.entity`,
                minWidth: 100,
                filterType: 'select'
            },
            {
                prop: 'entityName',
                name: `${dictionaryPath}.table.nameOrLicense`,
                minWidth: 180,
                filterType: 'text'
            },
            {
                prop: 'name',
                translate: true,
                name: `${dictionaryPath}.table.type`,
                minWidth: 120,
                filterType: 'text'
            },
            {
                prop: 'expirationText',
                name: `${dictionaryPath}.table.expirationDateMileage`,
                minWidth: 120
            },
            {
                prop: 'differenceText',
                name: `${dictionaryPath}.table.timeOrDistance`,
                minWidth: 140,
                filterType: 'text',
                popoverConfig: {
                    popoverComponent: AlertExpirationPopoverComponent
                },
                cellTemplateName: 'popoverCell'
            },
            {
                prop: 'status',
                name: `${dictionaryPath}.table.status`,
                minWidth: 150,
                translate: true,
                filterType: 'text',
                statusOptions: {
                    invalid: 'alertStatus.expired'
                },
                cellTemplateName: 'statusCell'
            },
            {
                prop: 'notes',
                name: `${dictionaryPath}.table.notes`,
                minWidth: 120,
                filterType: 'text'
            },
            {
                prop: 'fileStatus',
                translate: true,
                name: `${dictionaryPath}.table.file`,
                filterType: 'select'
            }
        ]
    }
};

export default alertsPageConfig;
