import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LocalizationService } from 'src/app/shared';
import { FormGroup } from '@angular/forms';
import { BaseService } from 'src/app/shared/services/api/base.service';
import { AddEditModalService } from '../../../shared/modules/add-edit-modal/services/add-edit-modal.service';
import safetyOfficerAddEditModalConfig from './safety-officer-add-edit.component.config';
import cloneDeep from 'lodash/cloneDeep';
import { CtrlFormsService } from '../../../shared/services/ctrl-forms.service';

@Component({
    selector: 'safety-officer-add-edit',
    templateUrl: './safety-officer-add-edit.component.html',
    styleUrls: ['./safety-officer-add-edit.component.scss', './safety-officer-add-edit.component.rtl.scss']
})
export class SafetyOfficerAddEditComponent implements OnInit {
    addEditForm: FormGroup;
    editData: any;
    modalConfig: any;

    @Output() action = new EventEmitter();

    constructor(
        private baseService: BaseService,
        private ctrlFormsService: CtrlFormsService,
        private addEditModalService: AddEditModalService,
        public localizationService: LocalizationService
    ) {
    }

    ngOnInit(): void {
        this.setModalConfig();
        this.initAddEditForm();
        this.baseService.setPaths('safetyOfficers');
        if (this.editData) {
            this.ctrlFormsService.patchValueNested(this.addEditForm, this.editData, this.modalConfig.fields);
        }
    }

    initAddEditForm(): void {
        this.addEditForm = this.ctrlFormsService.generateModalTabsForm(safetyOfficerAddEditModalConfig);
    }

    setModalConfig(): void {
        this.modalConfig = cloneDeep(safetyOfficerAddEditModalConfig);
        this.modalConfig.tabs = this.editData ? this.modalConfig.editTabs : this.modalConfig.tabs;
    }

    deleteSafetyOfficer(): void {
        this.addEditModalService.deleteEntity('safetyOfficers.addEditSafetyOfficer.messages.deleteConfirm', this.editData, this.action);
    }

    submitForm = (): void => {
        if (this.addEditModalService.canSubmit(this.addEditForm)) {
            this.addEditModalService.submitForm(this.addEditForm.value, this.action, this.editData);
        }
    };
}
