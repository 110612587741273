import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriversPageComponent } from './drivers-page/drivers-page.component';
import { SharedModule } from '../../shared/shared.module';
import { DriverAddEditComponent } from './driver-add-edit/driver-add-edit.component';
import { UGridModule, USplitLayoutModule, UCommonModule } from '@shift/ulib';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { InputsModule } from '../inputs/inputs.module';
import { AddEditModalModule } from 'src/app/shared/modules/add-edit-modal/add-edit-modal.module';
import { BaseService } from 'src/app/shared/services/api/base.service';

@NgModule({
    imports: [
        CommonModule,
        USplitLayoutModule,
        UGridModule,
        TranslateModule,
        TabsModule,
        ReactiveFormsModule,
        UCommonModule,
        FormsModule,
        HttpClientModule,
        NgHttpLoaderModule,
        InputsModule,
        AddEditModalModule,
        SharedModule
    ],
    declarations: [
        DriversPageComponent,
        DriverAddEditComponent
    ],
    entryComponents: [
        DriverAddEditComponent
    ],
    providers: [
        BaseService
    ]
})
export class DriversModule { }
