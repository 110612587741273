import { DriverAddEditComponent } from '../../../modules/drivers/driver-add-edit/driver-add-edit.component';
import { VehicleAddEditComponent } from '../../../modules/vehicles/vehicle-add-edit/vehicle-add-edit.component';
import { EventAddEditComponent } from '../../../modules/events/event-add-edit/event-add-edit.component';
import { SupplierAddEditComponent } from '../../../modules/suppliers/supplier-add-edit/supplier-add-edit.component';
import { ExpenseAddEditComponent } from '../../../modules/expenses/expense-add-edit/expense-add-edit.component';
import { InspectionAddEditComponent } from '../../../modules/inspections/inspection-add-edit/inspection-add-edit.component';
import { CustomerAddEditComponent } from '../../../modules/customers/customer-add-edit/customer-add-edit.component';
import { SafetyOfficerAddEditComponent } from 'src/app/modules/safety-officers/safety-officer-add-edit/safety-officer-add-edit.component';

const addEditModalConfig = {
    drivers: DriverAddEditComponent,
    vehicles: VehicleAddEditComponent,
    events: EventAddEditComponent,
    suppliers: SupplierAddEditComponent,
    expenses: ExpenseAddEditComponent,
    inspections: InspectionAddEditComponent,
    customers: CustomerAddEditComponent,
    safetyOfficers: SafetyOfficerAddEditComponent
};

export default addEditModalConfig;
