import { Component, OnInit } from '@angular/core';
import suppliersPageConfig from './suppliers-page.component.config';
import { BaseService } from 'src/app/shared/services/api/base.service';

@Component({
    selector: 'suppliers-page',
    templateUrl: './suppliers-page.component.html',
    styleUrls: ['./suppliers-page.component.scss', './suppliers-page.component.rtl.scss']
})
export class SuppliersPageComponent implements OnInit {
    rows: any[] = [];
    editData: any = null;
    suppliersPageConfig: {} = suppliersPageConfig;

    constructor(private baseService: BaseService) {
    }

    ngOnInit(): void {
        this.baseService.setPaths('suppliers');
        this.fetchSuppliers();
    }

    fetchSuppliers(): void {
        this.baseService.getAll().subscribe(suppliers => this.rows = suppliers);
    }
}
