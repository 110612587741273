import { environment } from 'src/environments/environment';

const LANGUAGES = {
    hebrew: {
        code: 'he',
        name: 'עברית'
    },
    english: {
        code: 'en',
        name: 'English'
    }
};

const appConfig = {
    appName: 'Ctrl',
    apiUrl: `${environment.serverUrl}/api`,
    userRoles: {
        customer: 'Customer',
        admin: 'Admin'
    },
    logoutTimeMinutes: 30,
    timeFormat: 'HH:mm',
    dateFormat: 'YYYY-MM-DD',
    shortDateFormat: 'DD/MM/YY',
    languages: LANGUAGES,
    defaultLanguage: LANGUAGES.english,
    rtlLanguage: LANGUAGES.hebrew,
    localStorageKeys: {
        token: 'token',
        language: 'lang'
    },
    defaultRoutes: {
        homePage: {
            customer: 'alerts',
            admin: 'customers'
        },
        unauthorized: 'login',
        default: ''
    },
    settings: {
        allowedRoles: ['trainer', 'manager', 'admin']
    }
};

export default appConfig;
