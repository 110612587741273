<div class="reports">
    <div class="reports__side-panel">
        <div class="reports__title">{{'reports.reports' | translate}}</div>
        <div class="reports__list">
            <div class="reports__list-item">
                <div class="reports__category">{{'reports.financial' | translate}}</div>
                <div class="reports__category-comment">{{'reports.expensesForThePeriod' | translate}}
                </div>
            </div>
        </div>
    </div>
    <div class="reports__content" *ngIf="!isLoading">
        <div class="reports__content__title">{{'reports.reportsChart' | translate}}</div>
        <div class="reports__content__body">
<!--            <div class="reports__panel">-->
<!--                <div class="reports__panel-header">-->
<!--                    <div class="reports__panel-header-title">{{'reports.table.title' | translate}}</div>-->
<!--                    <u-select-s-->
<!--                        [items]="reportsPageComponentConfig.vehicleTable.timePeriods"-->
<!--                        [formControl]="vehicleTableTimePeriodFormControl" [valid]="vehicleTableTimePeriodFormControl.valid"-->
<!--                        [placement]="localizationService.isRtl() ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']">-->
<!--                    </u-select-s>-->
<!--                </div>-->
<!--                <div class="reports__panel-content">-->
<!--                    <div class="table-container">-->
<!--                        <u-grid class="u-grid_app-table" [rows]="vehicleTableRows" [columns]="vehicleTableColumns" [columnMode]="'force'"-->
<!--                            [headerHeight]="35" [headerOffset]="10" [footerHeight]="0" [rowHeight]="65"-->
<!--                            [reorderable]="true" [scrollbarV]="true" [scrollbarH]="false"-->
<!--                            [isRtl]="localizationService.isRtl()" [editMode]="'cell'" [showColumnsFilter]="false"-->
<!--                            [messages]="'uGrid' | translate" [saveTableProps]="true">-->
<!--                        </u-grid>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="reports__panel reports__chart" *ngFor="let pie of chartsConfig | keyvalue:commonService.asIsOrder">
                <div class="reports__panel-header">
                    <div class="reports__panel-header-title">{{pie.value.title | translate}}</div>
                </div>
                <div class="reports__panel-content">
                    <div class="pie-container">
                        <div class="chart pie-chart">
                            <canvas baseChart
                                    width="450px" height="200px"
                                    [legend]="true"
                                    [data]="pie.value.data"
                                    [attr.chartKey]="pie.key"
                                    [options]="pieChartOptions"
                                    [labels]="localizationService.translateArray(pie.value.labels)"
                                    [chartType]="reportsPageComponentConfig.pieChart.chartType"
                                    [plugins]="reportsPageComponentConfig.pieChart.plugins"
                                    [colors]="reportsPageComponentConfig.pieChart.colors"
                                    (chartClick)="chartClicked(pie.key)"
                                    title="{{'reports.charts.switchMode' | translate}}">
                            </canvas>
                        </div>
                    </div>
                </div>
            </div>
<!--            <div class="reports__panel reports__summary full-width">-->
<!--                <monthly-summary-chart [items]="reportsPageComponentConfig.barChart.items"></monthly-summary-chart>-->
<!--            </div>-->
        </div>
    </div>
</div>

<!-- ****** Cell Templates ****** -->
<ng-template #normalCell ngx-datatable-cell-template let-cell="value" let-column="column">
    <span class="cell-value">
        {{column['translate'] ? ((cell && cell.value) || (cell.length > 0 ? cell : '') | translate) :
        (cell && cell.value) || (cell.length > 0 ? cell : '')}}</span>
</ng-template>
