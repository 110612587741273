import { AuthActions } from './auth.actions';
import { IAuth } from './auth.model';

const INITIAL_STATE: IAuth = {
    check: false,
    role: '',
    user: {},
    token: ''
};

export function createAuthAPIReducer() {
    return function authReducer(state: IAuth = INITIAL_STATE, action: any): IAuth {
        if (action.type === AuthActions.AUTH_USER) {
            return {
                ...state,
                check: true,
                user: action.user,
                role: action.user.role,
                token: action.token
            };
        }

        return state;
    };
}
