import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import appConfig from '../../../app.config';
import { BaseService } from '../../services/api/base.service';
import historyPageConfig from './history-table.config';

@Component({
    selector: 'history-table',
    templateUrl: './history-table.component.html',
    styleUrls: ['./history-table.component.scss', 'history-table.component.rtl.scss']
})
export class HistoryTableComponent implements OnInit {
    @Input() id: number = null;

    public rows: any[] = [];
    public historyPageConfig: {} = historyPageConfig;

    constructor(private baseService: BaseService) { }

    ngOnInit(): void {
        this.fetchHistory(this.id);
    }

    private fetchHistory(id: number): void {
        if (id) {
            this.baseService.getHistory(id).subscribe(history =>
                this.rows = this.parseDateTimeUTC(history));
        }
    }

    private parseDateTimeUTC(historyItems: any[]): any[] {
        historyItems.forEach(historyItem => {
            const dateTimeUtc = moment.utc(historyItem.dateTimeUtc).local();

            historyItem.changeTime = dateTimeUtc.format(appConfig.timeFormat);
            historyItem.changeDate = dateTimeUtc.format(appConfig.shortDateFormat);

            delete historyItem.dateTimeUtc;
        });

        return historyItems;
    }
}
