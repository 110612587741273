import { Component, Input, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormsService } from '../../../../../shared/services';
import inputsConfig from '../../../../inputs/inputs.config';

@Component({
    selector: 'departments-settings',
    templateUrl: './departments-settings.component.html',
    styleUrls: ['./departments-settings.component.scss', './departments-settings.component.rtl.scss']
})
export class DepartmentsSettingsComponent implements OnInit {
    @Input() formObject: FormArray;
    @Input() addNewFieldObs: Subject<any> = new Subject();

    constructor(private formsService: FormsService) { }

    ngOnInit(): void {
        this.addNewFieldObs.subscribe(() => this.addNewDepartment());
    }

    removeDepartment(index: number): void {
        this.formObject.removeAt(index);
    }

    addNewDepartment(): void {
        this.formObject.push(this.formsService.generateForm(inputsConfig.departments.fields));
    }
}
