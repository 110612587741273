import { VehicleAddEditComponent } from '../vehicle-add-edit/vehicle-add-edit.component';

const dictionaryPath = 'vehicles.table';

const vehiclesPageConfig = {
    modalComponent: VehicleAddEditComponent,
    tableConfig: {
        counterLabel: `${dictionaryPath}.vehicles`,
        tableName: 'vehicles',
        columns: [
            {
                prop: 'check',
                name: '',
                width: 50,
                sortable: false,
                canAutoResize: false,
                draggable: false,
                resizeable: false,
                headerCheckboxable: true,
                checkboxable: true,
                custom: true,
                hideable: false,
                hideDivider: true,
                noValue: true
            },
            {
                prop: 'ownershipType',
                translate: true,
                name: `${dictionaryPath}.ownershipType`,
                filterType: 'text',
                minWidth: 220
            },
            {
                prop: 'licenseNumber',
                name: `${dictionaryPath}.licenseNumber`,
                cellTemplateName: 'editRowCell',
                hideDivider: false,
                custom: true,
                clickble: true,
                highlight: false,
                filterType: 'text',
                minWidth: 220
            },
            {
                prop: 'manufacturer',
                name: `${dictionaryPath}.manufacturer`,
                filterType: 'text',
                minWidth: 120
            },
            {
                prop: 'type',
                name: `${dictionaryPath}.type`,
                translate: true,
                filterType: 'text'
            },
            {
                prop: 'ageYears',
                name: `${dictionaryPath}.vehicleAge`,
                filterType: 'text',
                minWidth: 120,
                durationUnit: 'years',
                cellTemplateName: 'durationCell'
            },
            {
                prop: 'notes',
                name: `${dictionaryPath}.notes`,
                filterType: 'text',
                minWidth: 220
            },
            {
                prop: 'status',
                name: `${dictionaryPath}.status`,
                translate: true,
                minWidth: 120,
                filterType: 'text',
                cellTemplateName: 'statusCell',
                statusOptions: {
                    invalid: 'vehicleStatus.inactive'
                }
            }
        ]
    }
};

export default vehiclesPageConfig;
