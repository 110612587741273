import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpensesPageComponent } from './expenses-page/expenses-page.component';
import { SharedModule } from '../../shared/shared.module';
import { ExpenseAddEditComponent } from './expense-add-edit/expense-add-edit.component';
import { AddEditModalModule } from 'src/app/shared/modules/add-edit-modal/add-edit-modal.module';
import { ExpenseAddEditOrderDetailsComponent } from './expense-add-edit/expense-add-edit-order-details/expense-add-edit-order-details.component';
import { ExpenseOrderItemComponent } from './expense-add-edit/expense-add-edit-order-details/expense-order-item/expense-order-item.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UCommonModule } from '@shift/ulib';
import { TranslateModule } from '@ngx-translate/core';
import { ExpenseOrderSummaryComponent } from './expense-add-edit/expense-add-edit-order-details/expense-order-summary/expense-order-summary.component';
import { InputsModule } from '../inputs/inputs.module';
import { DynamicElementsModule } from '../../shared/modules/dynamic-elements/dynamic-elements.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AddEditModalModule,
        ReactiveFormsModule,
        FormsModule,
        UCommonModule,
        TranslateModule,
        InputsModule,
        DynamicElementsModule
    ],
    declarations: [
        ExpensesPageComponent,
        ExpenseAddEditComponent,
        ExpenseAddEditOrderDetailsComponent,
        ExpenseOrderItemComponent,
        ExpenseOrderSummaryComponent
    ],
    entryComponents: [
        ExpenseAddEditComponent,
        ExpenseAddEditOrderDetailsComponent
    ]
})
export class ExpensesModule { }
