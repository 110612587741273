<div class="next-inspection-input-container">
  <u-input-number class="next-inspection-input-number"
    [formControl]="kilometerFormControl"
    [valid]="kilometerFormControl.valid"
    [min]="0" 
    [placeholder]="field['placeholder'] | translate"></u-input-number>
  <u-input-date class="next-inspection-input-date"
    [classUInput]="'u-input_text-center'" 
    [formControl]="dateFormControl"
    [valid]="dateFormControl.valid"
    [container]="'body'"
    [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']"
    [messages]="'uInputDate' | translate">
  </u-input-date>
</div>
