const documentsPageConfig = {
    title: 'documents.title',
    addNewDocument: 'documents.addNewDocument',
    customDocumentFieldsConfig: [
        {
            name: 'fileName',
            inputType: 'text',
            title: ''
        },
        {
            name: 'file-expiration',
            inputType: 'fileExpiration'
        }
    ]
};

export default documentsPageConfig;
