<u-split *ngIf="selectedSettingObject" [disabled]="true" class="settings-page-container" [useTransition]="true" [dir]="localizationService.getDirection()" direction="horizontal">
  <u-split-area [size]="10" [minSize]="100" panelName="settingsMenu">
    <div class="settings-menu-container">
      <span class="settings-menu-action" (click)="closeSettings()">{{settingsPageConfig['backLabel'] | translate}}</span>
      <div class="settings-menu-section" *ngFor="let section of settingsPageConfig.menuSections | keyvalue:commonService.asIsOrder">
        <span class="settings-menu-section-title">{{section.value['title'] | translate}}</span>
        <span class="settings-menu-item" *ngFor="let item of section.value['items'] | keyvalue:commonService.asIsOrder"
              [ngClass]="{'selected-setting': item.key === selectedSettingObject.key}"
              (click)="selectSettingsItem(section.key, item.key)">{{item.value.name | translate}}</span>
      </div>
    </div>
  </u-split-area>
  <u-split-area class="u-split-area-layout setting-tab-split-area" [size]="90" [minSize]="500" panelName="settingsTab">
    <settings-tab *ngIf="selectedSettingObject && selectedSettingObject.tabConfig" [tabForm]="tabForm" [tabConfig]="selectedSettingObject.tabConfig"></settings-tab>
    <div class="settings-actions">
      <button class="u-btn u-btn-clear" (click)="save(false)" [disabled]="!canSubmit()"
              [ngClass]="{'u-btn-disabled': !canSubmit()}">{{'general.save' | translate}}</button>
      <button class="u-btn" (click)="save(true)" [disabled]="!canSubmit()"
              [ngClass]="{'u-btn-disabled': !canSubmit()}">{{'general.saveAndClose' | translate}}</button>
    </div>
  </u-split-area>
</u-split>
