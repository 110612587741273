import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ApiService,
    AppTitleService,
    ConstantsService,
    FormsService,
    GlobalSearchService,
    LocalizationService,
    LocalizedToastrService,
    StatusesService,
    ValidationService
} from './services';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { TablePageComponent } from './components/table-page/table-page.component';
import { UGridModule, UPopoverModule, USplitLayoutModule, UCommonModule } from '@shift/ulib';
import { MockUpService } from './services/mockUp.service';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { TablePageService } from './components/table-page/table-page.service';
import { AddEditModalModule } from './modules/add-edit-modal/add-edit-modal.module';
import { TableElementComponent } from './components/table-element/table-element.component';
import { DocumentsPageComponent } from './components/documents-page/documents-page.component';
import { InputsModule } from '../modules/inputs/inputs.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PopoverCellComponent } from './components/table-page/popover-cell/popover-cell.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HistoryTableComponent } from './components/history-table/history-table.component';
import { AddEditUsersComponent } from './components/add-edit-users/add-edit-users.component';
import { AddEditUsersService } from './components/add-edit-users/add-edit-users.service';
import { DurationPipe } from './pipes/duration/duration.pipe';
import { ExistingEntityService } from './services/existing-entity/existing-entity.service';
import { DynamicElementsModule } from './modules/dynamic-elements/dynamic-elements.module';
import { CommonService } from './services/common.service';
import { MetadataService } from './services/metadata.service';
import { ClickOutsideModule } from 'ng-click-outside';
import { PopoverCellService } from './components/table-page/popover-cell/popover-cell.service';
import { CtrlFormsService } from './services/ctrl-forms.service';

@NgModule({
    imports: [
        CommonModule,
        USplitLayoutModule,
        UGridModule,
        TranslateModule,
        NgHttpLoaderModule,
        UPopoverModule,
        AddEditModalModule,
        InputsModule,
        UCommonModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        DynamicElementsModule,
        ClickOutsideModule
    ],
    declarations: [
        NotFoundPageComponent,
        TablePageComponent,
        TableElementComponent,
        AddEditUsersComponent,
        DocumentsPageComponent,
        PopoverCellComponent,
        HistoryTableComponent,
        DurationPipe
    ],
    providers: [
        ApiService,
        FormsService,
        CtrlFormsService,
        AppTitleService,
        ConstantsService,
        GlobalSearchService,
        LocalizationService,
        LocalizedToastrService,
        ValidationService,
        StatusesService,
        MockUpService,
        TablePageService,
        AddEditUsersService,
        ExistingEntityService,
        CommonService,
        MetadataService,
        PopoverCellService
    ],
    exports: [
        NotFoundPageComponent,
        TablePageComponent,
        AddEditUsersComponent,
        DocumentsPageComponent
    ]
})
export class SharedModule {
}
