import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class GlobalSearchService {
    public searchQuery = new Subject<string>();

    updateFilter(searchQuery: string): void {
        this.searchQuery.next(searchQuery);
    }
}
