import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, ConstantsService, LocalizationService } from '../../../shared/services';
import settingsPageConfig from './settings-page.component.config';
import { AppSideMenuService } from '../../layout/app-side-menu/app-side-menu.service';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { BaseService } from '../../../shared/services/api/base.service';
import { UPopupService } from '@shift/ulib';
import cloneDeep from 'lodash/cloneDeep';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from '../../../shared/services/common.service';
import { CtrlFormsService } from '../../../shared/services/ctrl-forms.service';
import pick from 'lodash/pick';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';

@Component({
    selector: 'settings-modal',
    templateUrl: './settings-page.component.html',
    styleUrls: ['./settings-page.component.scss', './settings-page.component.rtl.scss']
})
export class SettingsPageComponent implements OnInit, OnDestroy {
    @select(['auth', 'user', 'role'])
    readonly userRole: Observable<any>;

    private previousUrl: string;

    public tabForm: FormGroup;
    public tabsForm: FormGroup = new FormGroup({});
    public settingsPageConfig: any;
    public selectedSettingObject: any;
    private userRoleSubscription: Subscription;
    private progressRef: NgProgressRef;

    constructor(private router: Router, public localizationService: LocalizationService, private baseService: BaseService,
                private sideMenuService: AppSideMenuService, private constantsService: ConstantsService,
                private popupService: UPopupService, private authService: AuthService, public commonService: CommonService, private ctrlFormsService: CtrlFormsService, private progress: NgProgress) {
    }

    ngOnInit(): void {
        this.setPreviousUrl();
        this.initSettingsRole();
        this.sideMenuService.collapseMenu();
        this.progressRef = this.progress.ref('progressBar');
    }

    ngOnDestroy(): void {
        this.userRoleSubscription.unsubscribe();
    }

    initSettingsRole(): void {
        this.settingsPageConfig = cloneDeep(settingsPageConfig);

        this.userRoleSubscription = this.userRole.subscribe(userRole => {
            if (userRole) {
                if (userRole === 'admin') {
                    this.initAdminSettings();
                } else {
                    this.initSettings();
                }
            }
        })
    }

    initSettings(): void {
        this.baseService.setPaths('settings');
        this.settingsPageConfig.menuSections = this.settingsPageConfig.menuSections['customer'];

        this.constantsService.fetchCategories(['alertPeriodUnit']);
        this.getSettings();
    }

    getSettings(): void {
        this.baseService.getAll().subscribe(settings => {
            this.initSettingsForm(settings);
            this.initAlertFields(settings.alerts);
            this.initLicenseFields(settings.main.licenses);
            this.selectSettingsItem('main', 'general');
        })
    }

    initAdminSettings(): void {
        this.baseService.setPaths('adminSettings');
        this.settingsPageConfig.menuSections = this.settingsPageConfig.menuSections['admin'];
        this.getAdminSettings();
    }

    getAdminSettings(): void {
        this.baseService.getAll().subscribe(settings => {
            this.initSettingsForm(settings);
            this.selectSettingsItem('main', 'admins');
        });
    }

    initAlertFields(alertsSettings): void {
        Object.keys(alertsSettings).forEach((alertKey: string) => {
            const alertColumnsConfig = this.settingsPageConfig.menuSections.alerts.items[alertKey]['tabConfig']['columns'];

            Object.keys(alertsSettings[alertKey]).forEach(alertItemKey => {
                if (alertsSettings[alertKey][alertItemKey] instanceof Array) {
                    alertColumnsConfig[alertItemKey].fields = alertsSettings[alertKey][alertItemKey].map(field => {
                        return {title: field.type, inputType: 'alert'};
                    });
                }
            });
        });
    }

    initLicenseFields(licenseSettings): void {
        const licensesColumnsConfig = this.settingsPageConfig.menuSections.main.items.licenses['tabConfig']['columns'];

        licensesColumnsConfig['documents'].fields = licenseSettings.documents.map(field => {
            return {title: field.type, inputType: 'fileExpiration', outsideDelete: true};
        });
    }

    initSettingsForm(settings): void {
        this.tabsForm = this.ctrlFormsService.generateSettingsTabsForm(this.settingsPageConfig.menuSections);
        Object.keys(this.settingsPageConfig.menuSections).forEach(sectionKey => {
            const sectionNotNestedForm = this.settingsPageConfig.menuSections[sectionKey].notNestedForm;
            const sectionForm = sectionNotNestedForm ? this.tabsForm : this.tabsForm.get(sectionKey) as FormGroup;
            Object.keys(this.settingsPageConfig.menuSections[sectionKey].items).forEach(itemKey => {
                const itemTabConfig = this.settingsPageConfig.menuSections[sectionKey].items[itemKey].tabConfig;
                if (itemTabConfig.fields) {
                    if (itemTabConfig.notNestedForm) {
                        this.ctrlFormsService.patchValueNested(sectionForm, pick(sectionNotNestedForm ? settings : settings[sectionKey], Object.keys(itemTabConfig.fields)), itemTabConfig.fields);
                    } else {
                        this.ctrlFormsService.patchValueNested(sectionForm.get(itemKey) as FormGroup, sectionNotNestedForm ? settings[itemKey] : settings[sectionKey][itemKey], itemTabConfig.fields);
                    }
                }
            });
        });
    }

    setPreviousUrl(): void {
        const route = this.router.config[0].children.find(child => child.path === 'settings');
        this.previousUrl = route.data['previousUrl'];
    }

    closeSettings(): void {
        this.sideMenuService.expandMenu();
        this.router.navigate([this.previousUrl || '/']);
    }

    selectSettingsItem(sectionKey: string, itemKey: string): void {
        this.selectedSettingObject = this.settingsPageConfig.menuSections[sectionKey] && this.settingsPageConfig.menuSections[sectionKey].items[itemKey];
        this.selectedSettingObject.key = itemKey;
        if (this.selectedSettingObject.tabConfig.noParent) {
            this.tabForm = this.tabsForm.get(itemKey) as FormGroup;
        } else {
            this.tabForm = this.tabsForm.get(sectionKey).get(itemKey) as FormGroup;
        }
    }

    save(close: boolean = false): void {
        this.progressRef.start();
        this.baseService.update(this.tabsForm.value).subscribe(() => {
            this.progressRef.complete();
            if (close) {
                this.closeSettings();
            }
            this.popupService.showMessage({message: 'settings.savedSuccess', yes: 'general.ok'},
              () => this.authService.init());
        })
    }

    canSubmit(): boolean {
        return this.tabsForm && this.tabsForm.valid && this.tabsForm.dirty;
    }
}
