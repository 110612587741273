import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NavigationEnd, Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import { CustomerRoleSwitchComponent } from '../../auth/customer-role-switch/customer-role-switch.component';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { AuthService, ApiService } from 'src/app/shared';
import appSideMenuConfig from './app-side-menu.component.config';
import apiServiceConfig from '../../../shared/services/api/api.service.config';
import { UPopupService } from '@shift/ulib';
import { AppSideMenuService } from './app-side-menu.service';
import appConfig from '../../../app.config';

@Component({
    selector: 'app-side-menu',
    providers: [BsModalRef, Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    templateUrl: './app-side-menu.component.html',
    styleUrls: ['./app-side-menu.component.scss', './app-side-menu.component.rtl.scss'],
})
export class AppSideMenuComponent implements OnInit, OnDestroy {
    @select(['auth', 'user', 'fullName'])
    readonly fullName: Observable<any>;

    @select(['auth', 'user', 'customerName'])
    readonly customerName: Observable<any>;

    @select(['auth', 'user', 'role'])
    readonly userRole: Observable<any>;

    showLogout: boolean;
    showSettings: boolean;
    isMenuCollapsed: boolean;
    menuItems: any[] = [];
    subscriptions: any[] = [];
    helpLink: string = appSideMenuConfig.links.help;
    remoteLink: string = appSideMenuConfig.links.remote;

    private showMenuEvt: any;

    constructor(
        private authService: AuthService,
        private location: Location,
        private modalService: BsModalService,
        private modalRef: BsModalRef,
        private router: Router,
        private popupService: UPopupService,
        private apiService: ApiService,
        private sideMenuService: AppSideMenuService
    ) {
    }

    ngOnInit(): void {
        this.setMenuItems();
        this.sideMenuService.expandMenu();
        this.setMenuState();
        this.subscribeToRouteChange();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    setMenuState(): void {
        this.sideMenuService.isMenuCollapsedObs.subscribe(isMenuCollapsed => this.isMenuCollapsed = isMenuCollapsed);
    }

    setMenuItems(): void {
        this.userRole.subscribe(userRole => {
            if (userRole) {
                this.menuItems = appSideMenuConfig.menu[userRole.toLowerCase()] || appSideMenuConfig.menu.customer;
                this.setActiveItem(this.location.path().substr(1));
                this.showSettings = appConfig.settings.allowedRoles.includes(userRole);
            }
        });
    }

    setActiveItem(path: string): void {
        this.menuItems.forEach(item => {
            item.active = item.link === path;
            if (item.children) {
                item.children.forEach(child => {
                    item.active = item.active || child.link === path;
                    child.active = child.link === path;
                });
            }
        });
    }

    subscribeToRouteChange(): void {
        this.router.events.filter(event => event instanceof NavigationEnd)
          .subscribe(() => this.setActiveItem(this.router.url.substr(1)));
    }

    onNavigate(link: any): void {
        this.sideMenuService.expandMenu();
        this.router.navigate([link]);
    }

    logout(): void {
        this.authService.logout();
    }

    toggleSidebar(): void {
        this.sideMenuService.toggleMenu(!this.isMenuCollapsed);
    }

    openSettings(): void {
        const route = this.router.config[0].children.find(child => child.path === 'settings');
        route.data['previousUrl'] = this.router.url;
        this.router.navigateByUrl('/settings');
    }

    onMenuToggle(event): void {
        this.showMenuEvt = event;
        this.sideMenuService.toggleMenu(event);
    }

    showCustomerRoleSwitch(event: any): void {
        event.preventDefault();
        this.apiService.get(apiServiceConfig.paths.auth.basePath + apiServiceConfig.paths.auth.switchRoleInfo).subscribe((switchUserLists: any) => {
            const modalConfig = appSideMenuConfig.switchRoleModalConfig;
            modalConfig.initialState = {switchUserLists};
            this.modalRef = this.modalService.show(CustomerRoleSwitchComponent, modalConfig);
            this.modalRef.content.action.subscribe(({result, customerId, role}) => {
                if (result === 'Ok') {
                    this.authService.switchRole(customerId, role).subscribe(
                        () => window.location.reload(),
                        err => {
                            this.popupService.showErrors({
                                title: 'general.error',
                                ok: 'Ok',
                                errors: err.errors
                            });
                        },
                        () => {
                        });
                }
            }
            );
        });
    }
}
