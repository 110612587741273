<div class="alerts-feed-container">
  <div class="alerts-feed-items-container">
    <div class="alerts-feed-item" *ngFor="let feedItem of feed | keyvalue:commonService.asIsOrder">
      <div class="alerts-feed-item-header" (click)="feedItem.value['collapseTasks'] = !feedItem.value['collapseTasks']">
        <div class="alerts-feed-item-header-title">
          <span class="icon list-icon"></span>
          <span class="title-text">{{alertsFeedConfig.feedItems[feedItem.key] | translate}}</span>
        </div>
        <div class="alerts-feed-item-header-tasks">
          <div class="alerts-feed-item-header-tasks-align-icon">
            <div class="alerts-feed-item-header-tasks-total">
              <span class="alerts-feed-item-header-tasks-number">{{feedItem.value['total']}}</span>
              <span class="alerts-feed-item-header-tasks-label">{{alertsFeedConfig.totalTasksLabel | translate}}</span>
            </div>
            <span class="icon open-icon alerts-feed-item-header-tasks-toggle-icon"
                  *ngIf="!feedItem.value['collapseTasks']"></span>
            <span class="icon close-icon alerts-feed-item-header-tasks-toggle-icon"
                  *ngIf="feedItem.value['collapseTasks']"></span>
          </div>
        </div>
      </div>
      <div class="alerts-feed-item-body" *ngIf="!feedItem.value['collapseTasks']">
        <div class="feed-task" *ngFor="let feedTask of feedItem.value.items | keyvalue:commonService.asIsOrder" (click)="filterAlertsTable(feedTask.key)"
        [ngClass]="{'feed-task-selected': selectedAlertFilterType === feedTask.key}">
          <span class="feed-task-counter">{{feedTask.value}}</span>
          <span class="feed-task-title">{{feedTask.key | translate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="alerts-feed-footer">
    <button class="clear-alerts-filter-btn u-btn-simple" (click)="clearAlertsFilter()" *ngIf="selectedAlertFilterType">{{'general.clearFilter' | translate}}</button>
  </div>
</div>
