import addEditModalConfig from '../../../../shared/modules/add-edit-modal/add-edit-modal.config';

const addPopoverItems = {
    drivers: {
        title: 'alerts.addPopover.addDriver',
        icon: '../../../../../assets/images/icons/app-side-menu/driver.svg',
        addEditModal: addEditModalConfig.drivers
    },
    vehicles: {
        title: 'alerts.addPopover.addVehicle',
        icon: '../../../../../assets/images/icons/app-side-menu/vehicle2.svg',
        addEditModal: addEditModalConfig.vehicles
    },
    events: {
        title: 'alerts.addPopover.addEvent',
        icon: '../../../../../assets/images/icons/app-side-menu/events.svg',
        addEditModal: addEditModalConfig.events
    },
    suppliers: {
        title: 'alerts.addPopover.addSupplier',
        icon: '../../../../../assets/images/icons/app-side-menu/supplier2.svg',
        addEditModal: addEditModalConfig.suppliers
    },
    // expenses: {
    //     title: 'alerts.addPopover.addExpense',
    //     icon: '../../../../../assets/images/icons/app-side-menu/wallet.svg',
    //     addEditModal: addEditModalConfig.expenses
    // },
    inspections: {
        title: 'alerts.addPopover.addInspection',
        icon: '../../../../../assets/images/icons/icons-header/search.svg',
        addEditModal: addEditModalConfig.inspections,
        permittedUserRoles: ['safetyOfficer']
    },
    safetyOfficers: {
        title: 'alerts.addPopover.addSafetyOfficer',
        icon: '../../../../../assets/images/icons/app-side-menu/safety-officer.svg',
        addEditModal: addEditModalConfig.safetyOfficers
    }
};

const addBtnConfig = {
    customers: {
        addEditModal: addEditModalConfig.customers
    },
    alerts: { addPopoverItems },
    expenses: { addPopoverItems },
    events: { addPopoverItems },
    drivers: { addPopoverItems },
    vehicles: { addPopoverItems },
    suppliers: { addPopoverItems },
    reports: { addPopoverItems },
    safetyOfficers: { addPopoverItems },
    settings: {}
};

export default addBtnConfig;
