import { Injectable } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import expenseAddEditOrderConfig from './expense-add-edit-order-details.component.config';

@Injectable({
    providedIn: 'root'
})
export class ExpenseOrderService {
    orderItemListForm: FormArray;
    totalRowsPriceSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    totalRowsPrice = 0;

    constructor(private fb: FormBuilder) {
        this.initOrderItemListForm();
    }

    initOrderItemListForm() {
        this.orderItemListForm = this.fb.array([]);
        this.recalculateSumRows();
    }

    recalculateSumRows(): void {
        this.totalRowsPrice = 0;
        this.orderItemListForm.controls.forEach((control) => this.totalRowsPrice += (control.get('quantity').value * control.get('pricePerUnit').value));
        this.totalRowsPriceSubject.next(this.totalRowsPrice);
    }

    calculateTotalRowPrice(quantity: number, pricePerUnit: number): number {
        return quantity * pricePerUnit;
    }

    calculateDiscountPercent(discountNis: number): number {
        return parseInt(((discountNis / this.totalRowsPrice) * 100).toFixed(2), 10);
    }

    calculateDiscountNis(discountPercent: number): number {
        return parseFloat((this.totalRowsPrice * (discountPercent / 100)).toFixed(2));
    }

    calculateSumVat(): number {
        return this.totalRowsPrice * (expenseAddEditOrderConfig.VAT / 100);
    }

    calculateTotalPayment(sumVat: number, discountNis: number): number {
        return this.totalRowsPrice + sumVat - discountNis;
    }
}
