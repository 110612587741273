import { EventAddEditComponent } from '../../../events/event-add-edit/event-add-edit.component';
import apiServiceConfig from '../../../../shared/services/api/api.service.config';

const dictionaryPath = 'vehicles.addEditVehicle.generalDetails.events.table';

const eventsPageConfig = {
    tableConfig: {
        metadataSource: 'events',
        tableName: `${dictionaryPath}.title`,
        columns: [
            {
                prop: 'date',
                dateFormat: 'dd/MM/yy',
                name: `${dictionaryPath}.date`,
                filterType: 'date',
                minWidth: 120,
                maxWidth: 250
            },
            {
                prop: 'driverName',
                name: `${dictionaryPath}.involvedDriver`,
                filterType: 'text',
                minWidth: 120,
                maxWidth: 250
            },
            {
                prop: 'description',
                name: `${dictionaryPath}.description`,
                filterType: 'text',
                minWidth: 120,
                maxWidth: 250
            },
            {
                prop: 'severity',
                name: `${dictionaryPath}.severity`,
                filterType: 'text',
                translate: true,
                minWidth: 120,
                maxWidth: 250
            },
            {
                prop: 'prosecutionStatus',
                name: `${dictionaryPath}.status`,
                filterType: 'text',
                translate: true,
                minWidth: 120,
                maxWidth: 250
            },
            {
                prop: 'notes',
                name: `${dictionaryPath}.notes`,
                filterType: 'text',
                minWidth: 120
            },
            {
                prop: 'editRow',
                name: '',
                sortable: false,
                canAutoResize: false,
                draggable: false,
                resizeable: false,
                custom: true,
                hideDivider: true,
                noValue: true,
                minWidth: 80,
                cellTemplateName: 'editRowCell',
                basePath: apiServiceConfig.paths.events.basePath,
                editRowModalComponent: EventAddEditComponent
            }
        ]
    }
};

export default eventsPageConfig;
