const dictionaryPath = 'settings.safetyOfficers';

const fields = {
    certification: {
        name: 'certification',
        inputType: 'alert',
        title: `${dictionaryPath}.certification`
    },
    license: {
        name: 'license',
        inputType: 'alert',
        title: `${dictionaryPath}.license`
    }
};

const settingsAlertsSafetyOfficersConfig = {
    fields,
    title: `${dictionaryPath}.pageTitle`,
    columns: {
        safetyOfficer: {
            showCounter: true,
            title: `${dictionaryPath}.pageTitle`,
            fields: [fields.certification, fields.license]
        }
    }
};

export default settingsAlertsSafetyOfficersConfig;
