import { HistoryTableComponent } from '../../../shared/components/history-table/history-table.component';

const dictionaryPath = 'suppliers.addEditSupplier.generalDetails';

const fields = {
    id: {
        name: 'id',
        required: true,
        defaultValue: 0
    },
    name: {
        name: 'name',
        required: true,
        inputType: 'text',
    },
    businessIdentity: {
        name: 'businessIdentity',
        required: true,
        inputType: 'id',
        valueType: 'string',
        config: {
            existingRoleMessage: `${dictionaryPath}.generalDetails.existingSupplier`
        }
    },
    address: {
        name: 'address',
        inputType: 'address'
    },
    type: {
        name: 'type',
        inputType: 'select-if-null',
        inputOptionsVar: 'supplierType'
    },
    bankName: {
        name: 'bankName',
        inputType: 'select',
        inputOptionsArr: [
            {
                name: 'Poalim',
                value: 'Bank HaPoalim'
            },
            {
                name: `Leomi`,
                value: 'Leomi'
            },
            {
                name: `Discont`,
                value: 'Discont'
            }
        ]
    },
    bankBranchNumber: {
        name: 'bankBranchNumber',
        inputType: 'number',
        valueType: 'string'
    },
    bankAccountNumber: {
        name: 'bankAccountNumber',
        inputType: 'number',
        valueType: 'string'
    },
    paymentTerms: {
        name: 'paymentTerms',
        inputType: 'select',
        inputOptionsVar: 'paymentTerms',
        defaultValue: 'paymentTerms.immediate'
    },
    notes: {
        name: 'notes',
        inputType: 'textarea'
    },
    contactGroups: {
        name: 'contactGroups',
        arrayField: true,
        inputType: 'contactGroups'
    }
};

fields.businessIdentity.config['formFields'] = fields;

const tabs = [
    {
        id: 'info',
        notNestedForm: true,
        title: `${dictionaryPath}.title`,
        columns: [
            [
                {
                    title: `${dictionaryPath}.generalDetails.title`,
                    fields: [
                        {
                            ...fields.name,
                            title: `${dictionaryPath}.generalDetails.name`
                        },
                        {
                            ...fields.businessIdentity,
                            title: `${dictionaryPath}.generalDetails.businessIdentity`
                        },
                        {
                            ...fields.address,
                            title: `${dictionaryPath}.generalDetails.address`
                        },
                        {
                            ...fields.type,
                            title: `${dictionaryPath}.generalDetails.type`
                        }
                    ]
                },
                {
                    title: `${dictionaryPath}.bankAccount.title`,
                    fields: [
                        {
                            ...fields.bankName,
                            title: `${dictionaryPath}.bankAccount.bankName`
                        },
                        {
                            ...fields.bankBranchNumber,
                            title: `${dictionaryPath}.bankAccount.branchId`
                        },
                        {
                            ...fields.bankAccountNumber,
                            title: `${dictionaryPath}.bankAccount.accountNumber`
                        },
                        {
                            ...fields.paymentTerms,
                            title: `${dictionaryPath}.bankAccount.paymentTerms`
                        },
                        {
                            ...fields.notes,
                            title: `${dictionaryPath}.bankAccount.notes`
                        }
                    ]
                }
            ],
            [
                {
                    fields: [fields.contactGroups]
                }
            ]
        ]
    }
];

const historyTab = {
    id: 'history',
    title: 'suppliers.addEditSupplier.history.title',
    columns: [
        [{
            fields: [
                {
                    bodyComponent: HistoryTableComponent
                }
            ]
        }]
    ]
};

const supplierAddEditModalConfig = {
    fields,
    title: 'suppliers.addEditSupplier.title',
    tabs,
    editTabs: [...tabs, historyTab],
    hiddenFields: {id: fields.id},
    deleteLabel: 'suppliers.addEditSupplier.deleteSupplier',
    categoriesToFetch: ['supplierType', 'paymentTerms', 'contactType']
};

export default supplierAddEditModalConfig;
