const apiServiceConfig = {
    headers: {
        plain: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        urlEncoded: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/x-www-form-urlencoded'
        },
        formData: {
            'Accept': 'multipart/form-data',
        },
        blob: {
            'Content-Type': 'application/json'
        }
    },
    paths: {
        constants: {
            basePath: '/Constants'
        },
        alerts: {
            basePath: '/Alerts',
            getAll: '',
            feed: '/Feed',
            date: '/Date',
            mileage: '/Mileage',
            documentDate: '/Date'
        },
        person: {
            basePath: '/Person',
            findByContact: '/FindByContact',
            findByIdentity: '/FindByIdentity'
        },
        auth: {
            basePath: '/Account',
            login: '/Login',
            verifyCode: '/VerifyCode',
            forgotPassword: '/ForgotPassword',
            changePassword: '/ChangePassword',
            newUserLogin: '/New',
            update: '/user',
            switchRoleInfo: '/Roles',
            switchRole: '/Switch'
        },
        customers: {
            basePath: '/Admin/Customer',
            getAll: '/List',
            create: '',
            update: '',
            delete: '',
            byId: ''
        },
        suppliers: {
            basePath: '/Supplier',
            getAll: '/List',
            getAllSlim: '/List/Slim',
            create: '',
            update: '',
            delete: '',
            byId: '',
            history: '/History',
            findByIdentity: '/FindByIdentity'
        },
        drivers: {
            basePath: '/Driver',
            getAll: '/List',
            getAllSlim: '/List/Slim',
            create: '',
            update: '',
            delete: '',
            byId: '',
            history: '/History',
            documents: '/Document',
            documentTypes: '/DocumentTypes',
            findByIdentity: '/FindByIdentity'
        },
        vehicles: {
            basePath: '/Vehicle',
            getAll: '/List',
            getAllSlim: '/List/Slim',
            create: '',
            update: '',
            delete: '',
            byId: '',
            events: '/Events',
            history: '/History',
            documents: '/Document',
            inspections: '/Inspections',
            documentTypes: '/DocumentTypes',
            findByIdentity: '/FindByLicenseNumber'
        },
        safetyOfficers: {
            basePath: '/SafetyOfficer',
            getAll: '/List',
            create: '',
            update: '',
            delete: '',
            byId: '',
            history: '/History',
            documents: '/Document',
            findByIdentity: '/FindByIdentity'
        },
        inspection: {
            basePath: '/Inspection',
            create: '',
            delete: '',
            start: '/Start',
            images: '/Image',
            documents: '/Document',
            thumbnail: '/Thumbnail',
            attachment: '/Attachment',
            availableVehicles: '/AvailableVehicles'
        },
        reports: {
            basePath: '/Report',
            driversByDepartment: '/Dashboard/DriversByDepartment',
            vehicleByDepartment: '/Dashboard/VehicleByDepartment',
            vehicleByOwnershipType: '/Dashboard/VehicleByOwnershipType'
        },
        events: {
            basePath: '/Event',
            create: '',
            update: '',
            delete: '',
            byId: '',
            getAll: '/List',
            history: '/History',
            documents: '/Document',
            availableVehicles: '/AvailableVehicles',
            prosecutionStatus: '/ProsecutionStatus',
            safetyOfficerStatus: '/safetyOfficerStatus'
        },
        values: {
            basePath: '/Values',
            checkCustomerID: '/CheckCustomerID'
        },
        branches: {
            basePath: '/Branch',
            getAllSlim: '/List/Slim'
        },
        departments: {
            basePath: '/Department',
            getAllSlim: '/List/Slim'
        },
        language: '/Language',
        settings: {
            basePath: '/Settings',
            getAll: '',
            update: '',
            documents: '/Document'
        },
        adminSettings: {
            basePath: '/Admin/Settings',
            getAll: '',
            update: ''
        },
    }
};

export default apiServiceConfig;
