import { Component, Input, OnInit } from '@angular/core';
import { ExistingEntityService } from '../../../shared/services/existing-entity/existing-entity.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'id-input',
    templateUrl: './id-input.component.html',
    styleUrls: ['./id-input.component.scss']
})
export class IdInputComponent implements OnInit {
    @Input() field: {};
    @Input() formObject: FormGroup;
    @Input() formControl: FormControl;

    constructor(public existingEntityService: ExistingEntityService) { }

    ngOnInit(): void {
        this.existingEntityService.init(this.field, this.formControl, this.formObject);
    }
}
