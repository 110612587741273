<div class="department-control" *ngFor="let departmentForm of formObject.controls; let i=index;">
  <u-group [name]="'settings.departments.department' | translate">
    <div class="department-control-container">
      <input uInput class="department-name" [formControl]="departmentForm.controls['name']"/>
      <div class="department__actions">
        <button class="u-btn-simple u-btn-small icon-btn" (click)="removeDepartment(i)">
          <span class="icon delete-icon"></span>
        </button>
      </div>
    </div>
  </u-group>
</div>
