<div class="order-item-container">
  <div class="order-item order-item-{{field['size']}}" *ngFor="let field of expenseOrderItemConfig['fields']">
    <div class="order-item-input-container">
      <input uInput type="{{field['inputType']}}" *ngIf="field['inputType'] === 'text'"
        [formControl]="orderItemForm.controls[field['name']]" [valid]="orderItemForm.controls[field['name']].valid">
      <u-input-number *ngIf="field['inputType'] === 'number'" [formControl]="orderItemForm.controls[field['name']]"
        [valid]="orderItemForm.controls[field['name']].valid" [min]="field['min']" [max]="field['max']">
      </u-input-number>
    </div>
  </div>
  <div class="order-item order-item-large">
    <div class="order-item-total-row-price">
      <label>{{totalRowPrice}}</label>
      <div class="order-item-actions">
        <button class="u-btn-simple u-btn-small icon-btn" (click)="removeItem()"
          *ngIf="expenseOrderService?.orderItemListForm?.length > 1">
          <span class="icon delete-icon"></span>
        </button>
        <button class="u-btn-simple u-btn-small icon-btn" (click)="addItem()">
          <span class="icon add-icon"></span>
        </button>
      </div>
    </div>
  </div>
</div>