import settingsGeneralDetailsConfig from './settings-configs/customer/main/settings.general-details.config';
import settingsDepartmentsConfig from './settings-configs/customer/main/settings.departments.config';
import settingsManagersConfig from './settings-configs/customer/main/settings.managers.config';
import settingsAlertsCustomerConfig from './settings-configs/customer/alerts/settings.alerts.customer';
import settingsAlertsDriversConfig from './settings-configs/customer/alerts/settings.alerts.drivers';
import settingsAlertsVehiclesConfig from './settings-configs/customer/alerts/settings.alerts.vehicles';
import settingsLicensesConfig from './settings-configs/customer/main/settings.licenses.config';
import settingsAlertsSafetyOfficersConfig from './settings-configs/customer/alerts/settings.alerts.safetyOfficer';
import settingsGeneralDetails from './settings-configs/admin/settings.general-details.config';
import settingsAdminsConfig from './settings-configs/admin/settings.admins.config';

const dictionaryPath = 'settings';

const settingsPageConfig = {
    title: 'settings.pageTitle',
    backLabel: `${dictionaryPath}.back`,
    menuSections: {
        customer: {
            main: {
                title: `${dictionaryPath}.main.title`,
                items: {
                    general: {
                        component: 'generalDetailsSettings',
                        tabConfig: settingsGeneralDetailsConfig,
                        name: `${dictionaryPath}.generalDetails.title`
                    },
                    managers: {
                        component: 'managerSettings',
                        tabConfig: settingsManagersConfig,
                        name: `${dictionaryPath}.managers.title`
                    },
                    departments: {
                        component: 'departmentsSettings',
                        tabConfig: settingsDepartmentsConfig,
                        name: `${dictionaryPath}.departments.title`
                    },
                    licenses: {
                        component: 'licensesSettings',
                        tabConfig: settingsLicensesConfig,
                        name: `${dictionaryPath}.licenses.title`
                    }
                }
            },
            alerts: {
                title: `${dictionaryPath}.alerts.title`,
                items: {
                    customer: {
                        name: `${dictionaryPath}.customer.title`,
                        tabConfig: settingsAlertsCustomerConfig
                    },
                    driver: {
                        name: `${dictionaryPath}.drivers.title`,
                        tabConfig: settingsAlertsDriversConfig
                    },
                    vehicle: {
                        name: `${dictionaryPath}.vehicles.title`,
                        tabConfig: settingsAlertsVehiclesConfig
                    },
                    safetyOfficer: {
                        name: `${dictionaryPath}.safetyOfficers.title`,
                        tabConfig: settingsAlertsSafetyOfficersConfig
                    }
                }
            }
        },
        admin: {
            main: {
                notNestedForm: true,
                items: {
                    general: {
                        component: 'generalDetailsSettings',
                        tabConfig: settingsGeneralDetails,
                        name: `${dictionaryPath}.generalDetails.title`
                    },
                    admins: {
                        component: 'adminsSettings',
                        tabConfig: settingsAdminsConfig,
                        name: `${dictionaryPath}.admins.title`
                    }
                }
            }
        }
    }
};

export default settingsPageConfig;
