<div class="add-edit-info__info-item add-edit-contacts-item">
    <div class="add-edit-info__contacts">
        <div class="add-edit-info__actions add-edit-contacts-header">
            <div class="u-text-line u-text-line_border-off ctrl-section-title">{{contactGroupsInputConfig['title'] | translate}}</div>
            <button class="u-btn-simple"
                    (click)="addContact()">{{contactGroupsInputConfig['buttons']['addNewContact'] | translate}}</button>
        </div>
        <div class="add-edit-info__contacts-list">
            <div class="add-edit-info__contacts-item"
                 *ngFor="let contact of contactsForm.controls; let i = index;">
                <div class="add-edit-info__contacts-groups">
                    <u-group [name]="contactGroupsInputConfig.fields['contactInfo'].title | translate">
                        <div class="add-edit-info__contacts-inputs-container">
                            <input uInput *ngFor="let input of contactGroupsInputConfig.fields['contactInfo'].inputs"
                                   type="{{input['inputType']}}" placeholder="{{input['placeholder'] | translate}}"
                                   [formControl]="contact.controls[input['name']]"
                                   [valid]="contact.controls[input['name']].valid">
                        </div>
                        <div class="add-edit-info__contacts-actions-container">
                            <button class="u-btn-simple u-btn-small"
                                        *ngIf="contactRequired() ? contactsForm.controls.length > 1 : true"
                                        (click)="removeContact(i)">{{contactGroupsInputConfig.dictionaryPath + '.removeContact' | translate}}</button>
                        </div>
                    </u-group>
                    <u-group class="contacts-group u-group-line {{contactGroupsInputConfig['contactsClass']}}">
                        <contacts-input [contactsForm]="getContacts(i)" [editData]="contactGroupsData && contactGroupsData.length ? contactGroupsData[i] : null" [field]="{required: true}"></contacts-input>
                    </u-group>
                </div>
            </div>
        </div>
    </div>
</div>
