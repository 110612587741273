import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AddEditModalModule } from '../../shared/modules/add-edit-modal/add-edit-modal.module';
import { BaseService } from 'src/app/shared/services/api/base.service';
import { SafetyOfficersPageComponent } from './safety-officers-page/safety-officers-page.component';
import { SafetyOfficerAddEditComponent } from './safety-officer-add-edit/safety-officer-add-edit.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AddEditModalModule
    ],
    declarations: [
        SafetyOfficersPageComponent,
        SafetyOfficerAddEditComponent
    ],
    entryComponents: [
        SafetyOfficerAddEditComponent
    ],
    providers: [
        BaseService
    ]
})

export class SafetyOfficersModule {
}
