import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found-page.component.html',
    styleUrls: ['./not-found-page.component.scss', './not-found-page.component.rtl.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundPageComponent {
}
