const dictionaryPath = 'settings.drivers';

const fields = {
    license: {
        name: 'license',
        inputType: 'alert',
        title: `${dictionaryPath}.drivingLicense`
    },
    withholdingTax: {
        name: 'withholdingTax',
        inputType: 'alert',
        title: `${dictionaryPath}.withholdingTax`
    },
    documents: {
        name: 'documents',
        inputType: 'alert',
        noParent: true,
        arrayField: true
    }
};

const settingsAlertsDriversConfig = {
    fields,
    title: `${dictionaryPath}.pageTitle`,
    direction: 'column',
    columns: {
        others: {
            fields: [fields.license, fields.withholdingTax]
        },
        documents: {
            isArray: true,
            showCounter: true,
            fields: [fields.documents],
            title: `${dictionaryPath}.documentAlerts`
        }
    }
};

export default settingsAlertsDriversConfig;
