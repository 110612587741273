import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseService } from '../api/base.service';
import { UPopupService } from '@shift/ulib';
import { AddEditModalService } from '../../modules/add-edit-modal/services/add-edit-modal.service';
import { PersonService } from '../index';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { CtrlFormsService } from '../ctrl-forms.service';

@Injectable({
    providedIn: 'root'
})
export class ExistingEntityService {
    private field: {};
    private mainForm: FormGroup;
    private inputControl: FormControl;
    private progressRef: NgProgressRef;

    constructor(private baseService: BaseService, private personService: PersonService,
                private popupService: UPopupService, private addEditModalService: AddEditModalService,
                private progress: NgProgress, private ctrlFormsService: CtrlFormsService) {
        this.progressRef = this.progress.ref('progressBar');
    }

    init(field: {}, inputControl: FormControl, mainForm: FormGroup): void {
        this.field = field;
        this.mainForm = mainForm;
        this.inputControl = inputControl;
    }

    checkIfExistingEntityById(): void {
        const id = this.inputControl.value;
        if (id) {
            if (this.field['config'].isPerson) {
                this.personService.findByIdentity(id).subscribe(entity => this.handleExistingEntity(entity));
            } else {
                this.baseService.findByIdentity(id, this.field['config'].idParameter).subscribe(entity => this.handleExistingEntity(entity));
            }
        }
    }

    checkIfExistingEntityByContact(): void {
        const contact = this.inputControl.value;
        if (contact) {
            this.personService.findByContact(contact).subscribe(entity => this.handleExistingEntity(entity));
        }
    }

    handleExistingEntity(entity: {}): void {
        if (entity && entity['id']) {
            if (this.addEditModalService.isEditMode) {
                this.existingEntityNotAllowed();
            } else {
                let isExistingRole = false;
                entity['roles'].forEach(role => isExistingRole = role.substring(role.indexOf('.') + 1) === this.field['config']['role']);
                if (isExistingRole) {
                    this.existingRole(entity);
                } else {
                    this.existingPerson(entity);
                }
            }
        }
    }

    existingEntityNotAllowed(): void {
        this.popupService.showErrorMessage(
          'person.existingNotAllowedPerson',
          () => this.resetInput());
    }

    existingRole(entity: {}): void {
        this.popupService.showMessage({
            message: this.field['config']['existingRoleMessage'],
            yes: 'general.yes',
            no: 'general.no',
        }, () => {
            this.editRole(entity);
        },
          () => this.resetInput(),
          () => this.resetInput());
    }

    existingPerson(entity: {}): void {
        this.popupService.showMessage({
            message: 'person.existingPersonMessage',
            yes: 'general.yes',
            no: 'general.no',
        }, () => {
            this.updateFormWithExistingEntityDetails(entity);
        },
          () => this.resetInput(),
          () => this.resetInput());
    }

    resetInput(): void {
        if (this.inputControl.get('contact')) {
            this.inputControl.get('contact').reset();
        } else {
            this.inputControl.reset();
        }
    }

    editRole(entity: {}): void {
        this.progressRef.start();
        this.addEditModalService.isEditMode = true;
        this.baseService.byId(entity['id'])
          .subscribe(entityData => {
              this.updateFormData(entityData);
              this.progressRef.complete();
          },
            () => this.progressRef.complete());
    }

    updateFormWithExistingEntityDetails(entityData: {}): void {
        this.addEditModalService.isEditMode = false;
        this.updateFormData(entityData);
    }

    updateFormData(entityData): void {
        this.ctrlFormsService.patchValueNested(this.mainForm, entityData, this.field['config'].formFields, true);
    }
}
