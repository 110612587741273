const statusesServiceConfig = {
    codes: {
        en: {
            10: 'Invalid username or password',
            404: 'Not found',
            500: 'Internal server error',
            8100: 'Unknown error',
            8101: 'User with such login hasn\'t been found',
            8102: 'User with such login has been disabled',
            8103: 'Invalid password',
            8104: 'Customer with this user login has been disabled',
            8105: 'Invalid refresh_token',
            8106: 'User doesn\'t authenticated',
            8108: 'Invalid ClientId',
            1009: 'Authentication failed: wrong contact data or password.',
            1010: 'Confirmation code has been sent',
            1011: 'Confirmation code failed to be sent to the specified email or phone.',
            1012: 'Wrong confirmation code',
            1013: 'Confirmation code has expired',
            1014: 'PersonContact has ContactType which is not supported for authentication.',
            1015: 'User already exists',
            1100: 'SMS sending failed'
        },
        he: {
            10: 'שם משתמש או סיסמה לא חוקיים',
            404: 'לא נמצא',
            500: 'שגיאת שרת פנימית',
            8100: 'שגיאה לא ידועה',
            8101: 'משתמש לא קיים',
            8102: 'משתמש לא פעיל',
            8103: 'סיסמה שגוייה',
            8104: 'לקוח לא פעיל',
            8105: 'בעיית תוקן',
            8106: 'משתמש לא אומת, אנא אשר את המייל/הודעה שקיבלת',
            8108: 'שגיאה לא ידועה',
            1009: 'התחברות נכשלה: שם משתמש או סיסמא לא נכונים',
            1010: 'קוד אימות נשלח',
            1011: 'שליחת קוד האימות לטלפון או המייל נכשלה',
            1012: 'קוד אימות שגוי',
            1013: 'פג תוקף קוד האימות',
            1014: 'סוג משתמש לא נתמך',
            1015: 'משתמש קיים',
            1100: 'שליחת אסמס נכשלה'
        }
    },
    messages: {
        en: {
            'required': 'Required field',
            'incorrect': 'Incorrect',
            'expired': 'Expired',
            'notMatching': 'Passwords should match'
        },
        he: {
            'required': 'שדה נדרש',
            'incorrect': 'לא נכון',
            'expired': 'לא בתוקף',
            'notMatching': 'הסיסמאות אינן זהות'
        }
    }
};

export default statusesServiceConfig;
