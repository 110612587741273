<div class="order-summary">
  <div class="extra-payments">
    <div class="order-summary-row order-discount">
      <div class="label-section discount-label">
        <span>{{'expenses.addEditExpense.info.orderDetails.discount'| translate}}</span>
      </div>
      <div class="value-section">
        <percent-input [formObject]="discountPercentControl" [field]="{minValue: 0}" (change)="updateDiscountNISValue()"></percent-input>
        <div class="nis-input">
          <input uInput type="number" [formControl]="discountNISControl" (change)="updateDiscountPercentValue()"
            [valid]="discountNISControl.valid">
        </div>
      </div>
    </div>
    <div class="order-summary-row order-vat">
      <div class="label-section discount-label">
        <span>{{'expenses.addEditExpense.info.orderDetails.vat'| translate}}({{expenseAddEditOrderConfig.VAT}}%)</span>
      </div>
      <div class="value-section">
        <span>{{sumVat| number : '1.2-2'}} {{'expenses.addEditExpense.info.orderDetails.NIS'| translate}}</span>
      </div>
    </div>
  </div>
  <div class="order-summary-row order-total-payment">
    <div class="label-section discount-label">
      <span>{{'expenses.addEditExpense.info.orderDetails.totalPayment'| translate}}</span>
    </div>
    <div class="value-section">
      <span>{{totalPayment| number : '1.2-2'}} {{'expenses.addEditExpense.info.orderDetails.NIS'| translate}}</span>
    </div>
  </div>
</div>
