import { AfterContentInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import settingsPageConfig from '../settings-page.component.config';
import { CommonService } from '../../../../shared/services/common.service';

@Component({
    selector: 'settings-tab',
    templateUrl: './settings-tab.component.html',
    styleUrls: ['./settings-tab.component.scss', './settings-tab.component.rtl.scss']
})
export class SettingsTabComponent implements OnInit, AfterContentInit, OnChanges {
    @Input() public tabConfig;
    @Input() public tabForm: FormGroup;

    addNewFieldObs: Subject<any> = new Subject();

    constructor(private cdRef: ChangeDetectorRef, public commonService: CommonService) { }

    ngOnInit(): void {
        this.tabConfig.title = settingsPageConfig.title;
        this.setFormControlsLengths();
    }

    ngOnChanges(): void {
        this.tabConfig.title = settingsPageConfig.title;
        this.setFormControlsLengths();
    }

    ngAfterContentInit(): void {
        this.cdRef.detectChanges();
    }

    emitAddNewField(): void {
        this.addNewFieldObs.next();
    }

    setFormControlsLengths(): void {
        if (this.tabForm) {
            Object.keys(this.tabConfig.columns).forEach(columnKey =>
              this.tabConfig.columns[columnKey].controlsLength = this.tabConfig.columns[columnKey].fields.length);
        }
    }
}
