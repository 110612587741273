import { Component, OnInit } from '@angular/core';
import alertsFeedConfig from './alerts-feed.component.config';
import { AlertsService } from '../../alerts.service';
import { TablePageService } from '../../../../shared/components/table-page/table-page.service';
import { CommonService } from '../../../../shared/services/common.service';

@Component({
    selector: 'alerts-feed',
    templateUrl: './alerts-feed.component.html',
    styleUrls: ['./alerts-feed.component.scss', 'alerts-feed.component.rtl.scss']
})
export class AlertsFeedComponent implements OnInit {
    feed: {} = {};
    selectedAlertFilterType: string;
    alertsFeedConfig = alertsFeedConfig;

    constructor(private alertsService: AlertsService, private tablePageService: TablePageService, public commonService: CommonService) { }

    ngOnInit(): void {
        this.fetchFeed();
    }

    fetchFeed(): void {
        this.alertsService.getFeed().subscribe(feed => {
            Object.keys(feed).forEach(feedItemKey => feed[feedItemKey]['collapseTasks'] = false);
            this.feed = feed;
        });
    }

    filterAlertsTable(feedTaskKey: string): void {
        this.alertsService.getAlerts(feedTaskKey).subscribe(filteredAlerts => {
            this.selectedAlertFilterType = feedTaskKey;
            this.tablePageService.initRows(filteredAlerts);
        });
    }

    clearAlertsFilter(): void {
        this.selectedAlertFilterType = '';
        this.alertsService.getAlerts().subscribe(alerts => this.tablePageService.initRows(alerts))
    }
}
