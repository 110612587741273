import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AddEditModalModule } from '../../shared/modules/add-edit-modal/add-edit-modal.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { BaseService } from 'src/app/shared/services/api/base.service';
import { VehicleAddEditComponent } from './vehicle-add-edit/vehicle-add-edit.component';
import { VehicleAddEditAuthorizedDriversComponent } from './vehicle-add-edit/vehicle-add-edit-authorized-drivers/vehicle-add-edit-authorized-drivers.component';
import { UCommonModule, UGridModule } from '@shift/ulib';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { VehicleAddEditSuppliersComponent } from './vehicle-add-edit/vehicle-add-edit-suppliers/vehicle-add-edit-suppliers.component';
import { VehiclesPageComponent } from './vehicles-page/vehicles-page.component';
import { VehiclesService } from './vehicles.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AddEditModalModule,
        NgHttpLoaderModule,
        UCommonModule,
        TranslateModule,
        ReactiveFormsModule,
        UGridModule,
        NgxDatatableModule
    ],
    declarations: [
        VehicleAddEditComponent,
        VehicleAddEditAuthorizedDriversComponent,
        VehiclesPageComponent,
        VehicleAddEditSuppliersComponent
    ],
    entryComponents: [
        VehicleAddEditComponent,
        VehicleAddEditAuthorizedDriversComponent,
        VehicleAddEditSuppliersComponent
    ],
    providers: [
        BaseService,
        VehiclesService
    ]
})

export class VehiclesModule {
}
