import { NgModule } from '@angular/core';
import { NgReduxModule, NgRedux, DevToolsExtension } from '@angular-redux/store';
import { NgReduxRouterModule, NgReduxRouter } from '@angular-redux/router';
import { provideReduxForms } from '@angular-redux/form';

import { IAppState } from './store.model';
import { rootReducer } from './store.reducers';
import { AuthActions } from './auth/auth.actions';
import { LanguagesActions } from './languages/languages.actions';
import { CommonActions } from './common/common.actions';

@NgModule({
    imports: [
        NgReduxModule,
        NgReduxRouterModule
    ],
    providers: [
        AuthActions,
        LanguagesActions,
        CommonActions
    ]
})
export class StoreModule {
    constructor(
        public store: NgRedux<IAppState>,
        private devTools: DevToolsExtension,
        private ngReduxRouter: NgReduxRouter,
    ) {
        store.configureStore(
            rootReducer,
            {},
            [],
            devTools.isEnabled() ? [devTools.enhancer()] : []);

        if (ngReduxRouter) {
            ngReduxRouter.initialize();
        }

        provideReduxForms(store);
    }
}
