import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import customerAddEditModalConfig from './customer-add-edit.component.config';
import { BaseService } from 'src/app/shared/services/api/base.service';
import { AddEditModalService } from '../../../shared/modules/add-edit-modal/services/add-edit-modal.service';
import { MetadataService } from '../../../shared/services/metadata.service';
import { CtrlFormsService } from '../../../shared/services/ctrl-forms.service';

@Component({
    selector: 'add-edit-customers',
    templateUrl: './customer-add-edit.component.html',
    styleUrls: ['./customer-add-edit.component.scss', './customer-add-edit.component.rtl.scss']
})
export class CustomerAddEditComponent implements OnInit {
    editData: any;
    addEditForm: FormGroup;
    modalConfig: any = customerAddEditModalConfig;

    @Output() action = new EventEmitter();

    constructor(
        private baseService: BaseService,
        private ctrlFormsService: CtrlFormsService,
        private addEditModalService: AddEditModalService,
        private metadataService: MetadataService
    ) {}

    ngOnInit(): void {
        this.initAddEditForm();
        this.baseService.setPaths('customers');
        this.metadataService.setMetadataObs(this.modalConfig);
        if (this.editData) {
            this.ctrlFormsService.patchValueNested(this.addEditForm, this.editData, this.modalConfig.fields);
        }
    }

    initAddEditForm(): void {
        this.addEditForm = this.ctrlFormsService.generateModalTabsForm(this.modalConfig);
    }

    deleteCustomer(): void {
        this.addEditModalService.deleteEntity('customers.addEditCustomer.messages.deleteConfirm', this.editData, this.action);
    }

    submitForm = (): void => {
        if (this.addEditModalService.canSubmit(this.addEditForm)) {
            this.addEditModalService.submitForm(this.addEditForm.value, this.action, this.editData);
        }
    };
}
