import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import { environment } from '../environments/environment';
import apiServiceConfig from './shared/services/api/api.service.config';

export class MultiTranslateHttpLoader implements TranslateLoader {

    constructor(private http: HttpClient) {}

    public getTranslation(lang: string): Observable<any> {
        const resources = [`/assets/i18n/${lang}.json`, `${environment.serverUrl}/api${apiServiceConfig.paths.language}`];
        const headers = new HttpHeaders({'language': lang});

        return Observable.forkJoin(resources.map(res => this.http.get(res, {headers})))
          .map(response => response.reduce((a, b) => Object.assign(a, b)));
    }
}

export function translateLoader(http: HttpClient): MultiTranslateHttpLoader {
    return new MultiTranslateHttpLoader(http);
}
