import { EventEmitter, Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LocalizedToastrService } from '../../../services';
import { UPopupService } from '@shift/ulib';
import { BaseService } from '../../../services/api/base.service';
import { FormGroup } from '@angular/forms';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';

@Injectable()
export class AddEditModalService {
    public isEditMode: boolean;
    public isSubmitting: boolean;

    private progressRef: NgProgressRef;

    constructor(private modalService: BsModalService, private toastr: LocalizedToastrService,
                private popupService: UPopupService, private baseService: BaseService, private progress: NgProgress) {
        this.progressRef = this.progress.ref('progressBar');
    }

    openModal(modalComponent, editData?): BsModalRef {
        this.progressRef.complete();
        if (modalComponent) {
            return this.modalService.show(
              modalComponent, {
                  class: 'u-modal u-modal_content',
                  animated: true,
                  keyboard: false,
                  ignoreBackdropClick: true,
                  initialState: {editData}
              });
        }
    }

    submitForm(requestBody: {}, action: EventEmitter<any>, editData: boolean): void {
        this.progressRef.start();
        this.isSubmitting = true;

        if (!editData && !this.isEditMode) {
            this.baseService
          .create(requestBody)
          .subscribe(
            data => {
                action.emit({type: 'add', data});
                this.handleSubmitSuccess();
            },
            err => this.handleSubmitError(err ? err.errors : [])
          );
        } else {
            this.baseService
          .update(requestBody)
          .subscribe(
            data => {
                action.emit({type: 'edit', data});
                this.handleSubmitSuccess();
            },
            err => this.handleSubmitError(err ? err.errors : [])
          );
        }
    }

    canSubmit(addEditForm: FormGroup): boolean {
        return addEditForm.valid && !this.isSubmitting;
    }

    deleteEntity(message: string, editData, action?: EventEmitter<any>): void {
        this.popupService.showMessage({message, yes: 'general.yes', no: 'general.no'},
        () => {
            this.baseService.delete(editData.id).subscribe(
            () => {
                this.toastr.success('general.successful');
                this.modalService.hide(1);
                if (action) {
                    action.emit({ type: 'delete', data: editData });
                }
            },
            err => {
                this.popupService.showErrors({
                    title: 'general.error',
                    ok: 'Ok',
                    errors: err.errors
                }, () => {
                    this.modalService.hide(1);
                });
            });
        });
    }

    handleSubmitSuccess(): void {
        this.progressRef.complete();
        this.modalService.hide(1);
        this.isSubmitting = false;
        this.toastr.success('general.successful');
    }

    handleSubmitError(errors): void {
        this.progressRef.complete();
        this.isSubmitting = false;
        this.popupService.showErrors({
            title: 'general.formValidationError',
            ok: 'Ok',
            errors
        });
    }
}
