import { Injectable } from '@angular/core';
import appConfig from '../../app.config';

@Injectable()
export class TokenService {
    getToken(): String {
        return window.localStorage[appConfig.localStorageKeys.token];
    }

    saveToken(token: String) {
        window.localStorage[appConfig.localStorageKeys.token] = token;
    }

    destroyToken() {
        window.localStorage.removeItem(appConfig.localStorageKeys.token);
    }
}
