import { HistoryTableComponent } from 'src/app/shared/components/history-table/history-table.component';

const dictionaryPath = 'safetyOfficers.addEditSafetyOfficer';

const fields = {
    id: {
        name: 'id',
        required: true,
        defaultValue: 0
    },
    firstName: {
        name: 'firstName',
        required: true,
        inputType: 'text'
    },
    lastName: {
        name: 'lastName',
        required: true,
        inputType: 'text'
    },
    address: {
        name: 'address',
        inputType: 'address'
    },
    identity: {
        name: 'identity',
        inputType: 'id',
        required: true,
        config: {
            isPerson: true,
            role: 'safetyOfficer',
            existingRoleMessage: `${dictionaryPath}.info.generalDetails.existingSafetyOfficer`
        }
    },
    receiveEmails: {
        name: 'receiveEmails',
        inputType: 'toggle',
        defaultValue: true
    },
    certification: {
        name: 'certification',
        inputType: 'fileExpiration',
        innerDelete: true
    },
    license: {
        name: 'license',
        inputType: 'fileExpiration',
        innerDelete: true
    },
    contacts: {
        name: 'contacts',
        required: true,
        arrayField: true,
        inputType: 'contacts',
        config: {
            role: 'safetyOfficer',
            fetchExistingDetails: true,
            existingRoleMessage: `${dictionaryPath}.info.generalDetails.existingSafetyOfficer`
        }
    }
};

fields.contacts.config['formFields'] = fields;
fields.identity.config['formFields'] = fields;

const tabs = [
    {
        id: 'info',
        notNestedForm: true,
        title: `${dictionaryPath}.info.title`,
        columns: [
            [
                {
                    title: `${dictionaryPath}.info.generalDetails.title`,
                    fields: [
                        {
                            ...fields.firstName,
                            title: `${dictionaryPath}.info.generalDetails.firstName`
                        },
                        {
                            ...fields.lastName,
                            title: `${dictionaryPath}.info.generalDetails.lastName`
                        },
                        {
                            ...fields.address,
                            title: `${dictionaryPath}.info.generalDetails.address`
                        },
                        {
                            ...fields.identity,
                            title: `${dictionaryPath}.info.generalDetails.identity`
                        },
                        {
                            ...fields.receiveEmails,
                            title: `${dictionaryPath}.info.generalDetails.receiveEmails`
                        },
                        {
                            ...fields.certification,
                            title: `${dictionaryPath}.info.generalDetails.certification`
                        },
                        {
                            ...fields.license,
                            title: `${dictionaryPath}.info.generalDetails.license`
                        }
                    ]
                }
            ],
            [
                {
                    title: `${dictionaryPath}.info.contactDetails.title`,
                    fields: [fields.contacts]
                }
            ]
        ]
    }
];

const historyTab = {
    id: 'history',
    title: 'suppliers.addEditSupplier.history.title',
    columns: [
        [{
            fields: [
                {
                    bodyComponent: HistoryTableComponent
                }
            ]
        }]
    ]
};

const safetyOfficerAddEditModalConfig = {
    fields,
    tabs,
    editTabs: [...tabs, historyTab],
    hiddenFields: {id: fields.id},
    title: 'safetyOfficers.addEditSafetyOfficer.title',
    longTitle: true,
    deleteLabel: 'safetyOfficers.addEditSafetyOfficer.deleteSafetyOfficer',
    categoriesToFetch: ['contactType']
};

export default safetyOfficerAddEditModalConfig;
