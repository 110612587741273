const appSideMenuConfig = {
    menu: {
        admin: [
            {
                link: 'customers',
                name: 'sidebar.menu.customers',
                icon: '../../assets/images/icons/app-side-menu/driver.svg'
            }
        ],
        customer: [
            {
                icon: '../../assets/images/icons/app-side-menu/alerts.svg',
                link: 'alerts',
                name: 'sidebar.menu.alerts',
            },
            // {
            //     icon: '../../assets/images/icons/app-side-menu/wallet.svg',
            //     link: 'expenses',
            //     name: 'sidebar.menu.expenses',
            // },
            {
                icon: '../../assets/images/icons/app-side-menu/events.svg',
                link: 'events',
                name: 'sidebar.menu.events',
            },
            {
                icon: '../../assets/images/icons/app-side-menu/driver.svg',
                link: 'drivers',
                name: 'sidebar.menu.drivers',
            },
            {
                icon: '../../assets/images/icons/app-side-menu/vehicle2.svg',
                link: 'vehicles',
                name: 'sidebar.menu.vehicles',
            },
            {
                icon: '../../assets/images/icons/app-side-menu/supplier2.svg',
                link: 'suppliers',
                name: 'sidebar.menu.suppliers',
            },
            {
                icon: '../../assets/images/icons/app-side-menu/safety-officer.svg',
                link: 'safetyOfficers',
                name: 'sidebar.menu.safetyOfficers',
            },
            {
                icon: '../../assets/images/icons/app-side-menu/reports.svg',
                link: 'reports',
                name: 'sidebar.menu.reports',
            }
        ]
    },
    links: {
        help: 'https://shiftlive.zendesk.com',
        remote: 'http://www.150.co.il/'
    },
    switchRoleModalConfig: {
        class: 'modal-md switch-role-modal',
        animated: true,
        keyboard: false,
        ignoreBackdropClick: true,
        initialState: {}
    }
};

export default appSideMenuConfig;
