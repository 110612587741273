import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsInputComponent } from './contact-groups-input/contacts-input/contacts-input.component';
import { ContactGroupsInputComponent } from './contact-groups-input/contact-groups-input.component';
import { UCommonModule, UPopoverModule, UTooltipModule } from '@shift/ulib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressInputComponent } from './address-input/address-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { FileInputComponent } from './file-input/file-input.component';
import { AlertInputComponent } from './alert-input/alert-input.component';
import { FileExpirationInputComponent } from './file-expiration-input/file-expiration-input.component';
import { IdInputComponent } from './id-input/id-input.component';
import { PaymentTermsInputComponent } from './payment-terms-input/payment-terms-input.component';
import { InspectionInputComponent } from './inspection-input/inspection-input.component';
import { NextInspectionInputComponent } from './next-inspection-input/next-inspection-input.component';
import { PercentInputComponent } from './percent-input/percent-input.component';
import { FilesImagesInputComponent } from './files-images-input/files-images-input.component';
import { ModalModule } from 'ngx-bootstrap';
import { NgHttpLoaderModule } from 'ng-http-loader';

@NgModule({
    imports: [
        CommonModule,
        UCommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        UTooltipModule,
        UPopoverModule,
        ModalModule,
        NgHttpLoaderModule
    ],
    declarations: [
        ContactsInputComponent,
        ContactGroupsInputComponent,
        AddressInputComponent,
        FileInputComponent,
        AlertInputComponent,
        FileExpirationInputComponent,
        IdInputComponent,
        PaymentTermsInputComponent,
        NextInspectionInputComponent,
        InspectionInputComponent,
        PercentInputComponent,
        FilesImagesInputComponent
    ],
    exports: [
        AddressInputComponent,
        ContactGroupsInputComponent,
        ContactsInputComponent,
        FileInputComponent,
        AlertInputComponent,
        FileExpirationInputComponent,
        IdInputComponent,
        PaymentTermsInputComponent,
        NextInspectionInputComponent,
        InspectionInputComponent,
        PercentInputComponent
    ]
})
export class InputsModule {
}
