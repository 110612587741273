import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuppliersPageComponent } from './suppliers-page/suppliers-page.component';
import { SharedModule } from '../../shared/shared.module';
import { SupplierAddEditComponent } from './supplier-add-edit/supplier-add-edit.component';
import { AddEditModalModule } from '../../shared/modules/add-edit-modal/add-edit-modal.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { BaseService } from 'src/app/shared/services/api/base.service';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AddEditModalModule,
        NgHttpLoaderModule
    ],
    declarations: [
        SuppliersPageComponent,
        SupplierAddEditComponent
    ],
    entryComponents: [
        SupplierAddEditComponent
    ],
    providers: [
        BaseService
    ]
})

export class SuppliersModule {
}
