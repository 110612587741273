<!--TODO: split into 3 different components in the future (login, new user, forgot password)-->

<div class="login-page">
    <div class="login-page-form">
        <div class="logo">{{logoText}}</div>
        <form [formGroup]="loginForm" class="login-form"
              *ngIf="currentStep==steps.loginForm"
              (ngSubmit)="checkPassword()">
            <div class="form-group">
                <input formControlName="username"
                       autocomplete="username"
                       [ngClass]="{'validation-input': errors.loginForm.username}"
                       placeholder="{{'loginPage.login' | translate }}"
                       type="text"
                       uInput/>
                <div *ngIf="errors.loginForm.username"
                     class="login-validation">{{errors.loginForm.username}}</div>
            </div>
            <div class="form-group">
                <input formControlName="password"
                       autofocus [ngClass]="{'validation-input': errors.loginForm.password}"
                       placeholder="{{'loginPage.password' | translate }}"
                       type="password"
                       uInput/>
                <div *ngIf="errors.loginForm.password"
                     class="login-validation">{{errors.loginForm.password}}</div>
            </div>
            <a (click)="newUserRequest()" class="login-new-user">{{'loginPage.newUser' | translate }}</a>
            <a (click)="forgotPassword()" class="login-forgot">{{'loginPage.forgotPassword' | translate }}</a>
            <button class="login-btn"
                    [disabled]="!loginForm.valid"
                    type="submit">
                {{'loginPage.login' | translate }}
            </button>
        </form>
        <form [formGroup]="newPasswordForm" class="new-password-form"
              *ngIf="currentStep==steps.newPasswordForm"
              (ngSubmit)="setPassword()">
            <div class="form-group">
                <input readonly
                       formControlName="username"
                       [ngClass]="{'validation-input': errors.loginForm.username}"
                       placeholder="{{'loginPage.login' | translate }}"
                       type="text"
                       uInput/>
                <div *ngIf="errors.loginForm.username"
                     class="login-validation">{{errors.loginForm.username}}</div>
            </div>
            <p>{{'loginPage.specifyValidationCode' | translate }}</p>
            <div class="form-group">
                <input autofocus
                       readonly onfocus="this.removeAttribute('readonly');"
                       formControlName="code"
                       [ngClass]="{'validation-input': errors.codeForm.code}"
                       placeholder="{{'loginPage.code' | translate }}"
                       type="text"
                       uInput/>
                <div *ngIf="errors.codeForm.code" class="login-validation">{{errors.codeForm.code}}</div>
            </div>
            <div class="form-group">
                <input autofocus
                       formControlName="password"
                       autocomplete="new-password"
                       [ngClass]="{'validation-input': errors.newPasswordForm.password}"
                       placeholder="{{'loginPage.newPassword' | translate }}"
                       type="password"
                       uInput/>
                <div *ngIf="errors.newPasswordForm.password"
                     class="login-validation">{{errors.newPasswordForm.password}}</div>
            </div>
            <div class="form-group">
                <input formControlName="passwordRepeat"
                       [ngClass]="{'validation-input': errors.newPasswordForm.passwordRepeat}"
                       placeholder="{{'loginPage.newPasswordRepeat' | translate }}"
                       type="password"
                       uInput/>
                <div *ngIf="errors.newPasswordForm.passwordRepeat"
                     class="login-validation">{{errors.newPasswordForm.passwordRepeat}}</div>
            </div>
            <div *ngIf="(newPasswordForm.errors && newPasswordForm.errors.notMatching)
            && newPasswordForm.controls['password'].value && newPasswordForm.controls['passwordRepeat'].value"
                 class="login-validation">{{'loginPage.notSamePasswords' | translate }}</div>
            <a (click)="stepBackToLogin()" class="login-single-back">{{'loginPage.back' | translate }}</a>
            <button class="login-btn" [disabled]="!newPasswordForm.valid" type="submit">
                {{'loginPage.next' | translate }}
            </button>
        </form>
    </div>
    <div class="login-page-image"></div>
    <select #langSelect (change)="localizationService.setLanguage(langSelect.value)" class="change-localization">
        <option *ngFor="let lang of localizationService.languages | async" [value]="lang.code"
                [selected]="lang.code === translate.currentLang">
            {{ lang.name }}
        </option>
    </select>
</div>
