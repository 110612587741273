import { Component, Input, OnInit } from '@angular/core';
import alertInputConfig from './alert-input.component.config';
import { ConstantsService, LocalizationService } from '../../../shared/services';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'alert-input',
    templateUrl: './alert-input.component.html',
    styleUrls: ['./alert-input.component.scss', './alert-input.component.rtl.scss']
})
export class AlertInputComponent implements OnInit {
    @Input() control;

    isRtl: boolean;
    periodUnitOptions: any[] = [];

    constructor(private localizationService: LocalizationService, private constantsService: ConstantsService) { }

    ngOnInit(): void {
        this.setAlertsCategories();
        this.onPeriodUnitChanged();
        this.setDefaultPeriodUnit();
        this.setAmountControlDisabled();
        this.isRtl = this.localizationService.isRtl();
    }

    setAlertsCategories(): void {
        this.constantsService.categories.subscribe(categories =>
          this.periodUnitOptions = categories['alertPeriodUnit']);
    }

    onPeriodUnitChanged(): void {
        this.periodUnitControl.valueChanges.subscribe(() => this.setAmountControlDisabled());
    }

    setAmountControlDisabled(): void {
        if (this.amountControl.value === alertInputConfig.periodUnitsNoneValue || this.amountControl.value === null) {
            this.amountControl.reset();
            this.amountControl.disable();
            this.amountControl.clearValidators();
            this.amountControl.updateValueAndValidity();
        } else if (this.amountControl.disabled) {
            this.amountControl.enable();
            this.amountControl.setValidators(this.control.required ? [Validators.required] : undefined);
            this.amountControl.updateValueAndValidity();
        }
    }

    setDefaultPeriodUnit(): void {
        if (!this.periodUnitControl.value) {
            this.periodUnitControl.setValue(alertInputConfig.periodUnitsDefaultValue);
        }
    }

    get periodUnitControl(): FormControl {
        return this.control.get('periodUnit');
    }

    get amountControl(): FormControl {
        return this.control.get('amount');
    }

    get minAmount(): number {
        return this.periodUnitControl.value ? alertInputConfig.periodUnits[this.periodUnitControl.value]['minAmount'] : undefined;
    }

    get maxAmount(): number {
        return this.periodUnitControl.value ? alertInputConfig.periodUnits[this.periodUnitControl.value]['maxAmount'] : undefined;
    }
}
