<div class="alert-input-container" *ngIf="periodUnitOptions && periodUnitOptions.length > 0">
  <u-select-s class="alert-period-unit-select"
              [items]="periodUnitOptions"
              [formControl]="periodUnitControl"
              [valid]="periodUnitControl.valid"
              [container]="'body'"
              [placeholder]="'general.select' | translate"
              [emptyName]="'general.select' | translate"
              [emptyValue]="null"
              [emptyHidden]="true"
              [emptyRemove]="null"
              [messages]="'uSelectS' | translate"
              [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']">
  </u-select-s>
  <u-input-number class="alert-time-amount"
                  [formControl]="amountControl"
                  [valid]="amountControl.disabled || amountControl.valid"
                  [min]="minAmount" [max]="maxAmount">
  </u-input-number>
</div>
