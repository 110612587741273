<div class="table-header-container" *ngIf="config?.tableConfig['tableName']">
    <span class="u-text-line u-text-line_border-off table-title">
      {{config?.tableConfig.tableName | translate}}
      ({{rows ? rows.length : 0}})
    </span>
</div>
<u-grid class="u-grid_app-table"
        [rows]="rows || []"
        [columns]="config?.tableConfig?.columns"
        [columnMode]="'force'"
        [headerHeight]="45"
        [headerOffset]="10"
        [footerHeight]="0"
        [rowHeight]="65"
        [isRtl]="isRtl()"
        [reorderable]="true"
        [scrollbarV]="true"
        [scrollbarH]="false"
        [showColumnsFilter]="true"
        [messages]="'uGrid' | translate">
</u-grid>

<!-- ****** Cell Templates ****** -->
<ng-template #normalCell ngx-datatable-cell-template let-cell="value" let-column="column" let-row="row">
  <div class="cell-value-container" *ngIf="!column['noValue']">
    <span class="cell-value" *ngIf="!column['dateFormat']" title="{{column['translate'] ? (cell | translate) : cell}}">
      {{column['translate'] ? (cell | translate) : cell}}
    </span>
    <span class="cell-value" *ngIf="column['dateFormat']">
      {{cell | date:column['dateFormat']}}
    </span>
  </div>
</ng-template>

<ng-template #openRowModalCell ngx-datatable-cell-template let-column="column" let-row="row" let-cell="value">
  <div class="view-row-cell">
    <button type="button" class="cell-edit-btn u-grid-button" (click)="openRowModal(row, column['openRowModalComponent'], column['basePath'])">
      {{'general.view' | translate}}
    </button>
  </div>
</ng-template>

<ng-template #editRowCell ngx-datatable-cell-template let-row="row" let-column="column" let-cell="value">
  <div class="edit-row-cell">
    <span class="cell-value" *ngIf="!column['dateFormat']">{{cell.value ? cell.value : cell}}</span>
    <span class="cell-value" *ngIf="column['dateFormat']">
      {{cell | date:column['dateFormat']}}
    </span>
    <button type="button" class="cell-edit-btn u-grid-button" (click)="tablePageService.editRow(row, column['editRowModalComponent'], column['basePath'])" [disabled]="config.tableConfig.disableEditing" [ngClass]="{'u-btn-disabled': config.tableConfig.disableEditing}">
      {{'table.edit' | translate}}
    </button>
  </div>
</ng-template>
