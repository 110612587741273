import { Component, ElementRef, Input, OnChanges, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ConstantsService, LocalizationService } from '../../services';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { TablePageService } from './table-page.service';
import { FormControl } from '@angular/forms';
import { BaseService } from '../../services/api/base.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'table-page',
    templateUrl: './table-page.component.html',
    styleUrls: ['./table-page.component.scss', './table-page.component.rtl.scss']
})
export class TablePageComponent implements OnInit, OnChanges {
    @Input() rows: any = [];
    @Input() pageConfig: any = [];
    @Input() displayTableMetaData = true;

    @ViewChild('editRowCell') public editRowCell: TemplateRef<any>;
    @ViewChild('popoverCell') public popoverCell: TemplateRef<any>;
    @ViewChild('statusCell') public statusCell: TemplateRef<any>;
    @ViewChild('normalCell') public normalCell: TemplateRef<any>;
    @ViewChild('selectCell') public selectCell: TemplateRef<any>;
    @ViewChild('durationCell') public durationCell: TemplateRef<any>;

    @select(['auth', 'user', 'id'])
    public readonly userIdObs: Observable<any>;

    userId: number;
    categories: any;
    tableConfig: any;
    isLoading = true;

    constructor(
      public localizationService: LocalizationService,
      public tablePageService: TablePageService,
      private constantsService: ConstantsService,
      private baseService: BaseService,
      private translate: TranslateService
    ) {
    }

    @ViewChild('tableElement') set content(element: ElementRef) {
        this.tablePageService.tableElement = element;
    }

    get alteredRows(): any[] {
        return this.tablePageService.visibleRows;
    }

    get specificRowClassObjects(): any[] {
        return this.tablePageService.specificRowClassObjects;
    }

    ngOnInit(): void {
        // this.tableConfig = this.pageConfig.tableConfig;
        // if (this.tableConfig.categoriesToFetch) {
        //     this.fetchCategories(this.tableConfig.categoriesToFetch);
        // } else {
        //     this.isLoading = false;
        // }
        this.userIdObs.subscribe(userId => this.userId = userId);
    }

    ngOnChanges(): void {
        this.tableConfig = this.pageConfig.tableConfig;

        if (this.tableConfig.categoriesToFetch) {
            this.fetchCategories(this.tableConfig.categoriesToFetch);
        } else {
            this.isLoading = false;
        }

        this.addTranslatedValues(this.rows, this.tableConfig.columns);
        this.tablePageService.initRows(this.rows);
        this.tablePageService.selectedRows = [];
        this.tablePageService.tableConfig = this.tableConfig;
        this.tableConfig.columns = this.attachExtraPropsFromComponent(this.tableConfig.columns);
    }

    isRtl(): boolean {
        return this.localizationService.isRtl();
    }

    addTranslatedValues(rows: any[], columns: any[]): void {
        rows.forEach(row => {
            columns.forEach(column => {
                const value = row[column.prop];
                if (value && column['translate']) {
                    this.translate.get(value).subscribe(translatedVal => row[column.prop + 'Translated'] = translatedVal);
                }
            });
        });
    }

    attachExtraPropsFromComponent(columns: any[]): any[] {
        // attach matching cellTemplate for columns who requires it
        // TODO: try and improve this with a generic service in the future
        if (columns) {
            columns.forEach(column => {
                column['cellTemplate'] = column.cellTemplateName ? this[column.cellTemplateName] : this.normalCell;
                if (column.formControl) {
                    this.initFormControls(column.prop);
                }
            });
        }

        return columns;
    }

    initFormControls(columnProp: string): void {
        this.alteredRows.forEach(row => {
            const cellFormControl = new FormControl(row[columnProp]);
            cellFormControl.valueChanges.subscribe(value => this.baseService.patchProperty(columnProp, {value, id: row.id}).subscribe());
            row[columnProp] = cellFormControl;
        });
    }

    setSelectedRows(data: any): void {
        this.tablePageService.selectedRows = [...data.selected];
    }

    public get selectedRows(): any[] {
        return this.tablePageService.selectedRows;
    }

    fetchCategories(categoriesToFetch: string[]): void {
        this.constantsService.fetchCategories(categoriesToFetch);
        this.constantsService.categories.subscribe(categories => {
            this.categories = categories;
            this.isLoading = false;
        })
    }
}
