<div class="contacts-title" *ngIf="config && (config.title || config.addNewBtn)">
    <h3 class="ctrl-section-title">{{config.title | translate}}</h3>
    <span class="u-btn-simple add-new-btn" *ngIf="config.addNewBtn && getContactsForm().value.length === 0" (click)="addContact()">{{config.addNewBtn | translate}}</span>
</div>

<div class="contact-container" *ngFor="let contact of getContactsForm().controls; let controlIndex = index;">
    <div class="contact-input-container">
        <u-select-s [formControl]="contact.controls['type']"
                    [items]="contactTypes"
                    [container]="'body'"
                    [valid]="contact.controls['type'].valid"
                    [placeholder]="'general.select' | translate"
                    [emptyName]="'general.select' | translate"
                    [emptyHidden]="true"
                    [emptyValue]="null"
                    [emptyRemove]="null"
                    [messages]="'uSelectS' | translate"
                    [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']">
        </u-select-s>
        <input uInput *ngIf="contact.controls['type'].value === 'contactType.email'"
               type="text"
               [formControl]="contact.controls['contact']"
               [valid]="contact.controls['contact'].valid"
               (change)="checkIfExistingContact(contact, controlIndex)">
        <u-input-tel *ngIf="contact.controls['type'].value !== 'contactType.email'"
                     [formControl]="contact.controls['contact']"
                     [valid]="contact.controls['contact'].valid"
                     (change)="checkIfExistingContact(contact, controlIndex)">
        </u-input-tel>
    </div>
    <div class="add-edit-info__contacts-actions-container">
        <button class="u-btn-simple u-btn-small icon-btn" (click)="removeContact(controlIndex)"
                *ngIf="getContactsForm().controls.length > 1 || (field && !field.required)">
            <span class="icon remove-contact-icon"></span>
        </button>
        <button class="u-btn-simple u-btn-small icon-btn" (click)="addContact()"
                *ngIf="controlIndex == getContactsForm().controls.length - 1">
            <span class="icon add-contact-icon"></span>
        </button>
    </div>
</div>

