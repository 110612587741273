import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { UPopupService } from '@shift/ulib';
import { AddEditModalService } from './services/add-edit-modal.service';
import { MetadataService } from '../../services/metadata.service';

@Component({
    selector: 'add-edit-modal',
    templateUrl: './add-edit-modal.component.html',
    styleUrls: ['./add-edit-modal.component.scss', './add-edit-modal.component.rtl.scss']
})
export class AddEditModalComponent implements OnInit, OnDestroy {
    @Input() public editData: any = {};
    @Input() public readonly: boolean;
    @Input() public modalConfig: any;
    @Input() public submitForm: Function;
    @Input() public formObject: FormGroup;
    @Output() public deleteEvent = new EventEmitter<any>();

    private isSubmitting: boolean;

    constructor(public modalRef: BsModalRef, private popupService: UPopupService, public metadataService: MetadataService, public addEditModalService: AddEditModalService) {}

    ngOnInit(): void {
        if (this.modalConfig['categoriesToFetch']) {
            this.metadataService.fetchCategories(this.modalConfig['categoriesToFetch']);
        }
        if (this.modalConfig['itemsToFetch']) {
            this.metadataService.fetchItems(this.modalConfig['itemsToFetch']);
        }
    }

    ngOnDestroy(): void {
        this.addEditModalService.isEditMode = false;
    }

    showCloseConfirmMessage(): void {
        this.popupService.showMessage({
            message: 'general.closeConfirm',
            yes: 'general.yes',
            no: 'general.no',
        },
            () => this.submitForm(),
            () => this.modalRef.hide()
        );
    }

    delete(): void {
        this.deleteEvent.emit();
    }

    canSubmit(): boolean {
        return this.formObject.valid && this.formObject.dirty && !this.isSubmitting && !this.modalConfig.apiNotReady;
    }

    closeForm(): void {
        this.formObject.dirty ? this.showCloseConfirmMessage() : this.modalRef.hide();
    }
}
