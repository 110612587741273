import { VehicleAddEditAuthorizedDriversComponent } from './vehicle-add-edit-authorized-drivers/vehicle-add-edit-authorized-drivers.component';
import vehicleAddEditDocumentsConfig from './vehicle-add-edit-documents/vehicle-add-edit-documents.component.config';
import { DocumentsPageComponent } from 'src/app/shared/components/documents-page/documents-page.component';
import { VehicleAddEditSuppliersComponent } from './vehicle-add-edit-suppliers/vehicle-add-edit-suppliers.component';
import { TableElementComponent } from 'src/app/shared/components/table-element/table-element.component';
import expensesPageConfig from './vehicle-add-edit-configs/vehicle-add-edit-expenses.config';
import eventsPageConfig from './vehicle-add-edit-configs/vehicle-add-edit-events.config';
import inspectionsPageConfig from './vehicle-add-edit-configs/vehicle-add-edit-inspections.config';
import { HistoryTableComponent } from '../../../shared/components/history-table/history-table.component';
import vehicleAddEditAccountingConfig from './vehicle-add-edit-configs/vehicle-add-edit-accounting.component.config';

const dictionaryPath = 'vehicles.addEditVehicle.generalDetails';

const fields = {
    id: {
        name: 'id',
        required: true,
        defaultValue: 0
    },
    licenseNumber: {
        name: 'licenseNumber',
        inputType: 'id',
        required: true,
        maxIntegralDigits: 8,
        config: {
            role: 'vehicle',
            isPerson: false,
            idParameter: 'number',
            existingRoleMessage: `${dictionaryPath}.generalDetails.existingVehicle`,
        }
    },
    departmentId: {
        name: 'departmentId',
        inputType: 'select',
        inputOptionsVar: 'departments'
    },
    branchId: {
        name: 'branchId',
        inputType: 'select',
        required: true,
        inputOptionsVar: 'branches'
    },
    insuranceSupplierId: {
        name: 'insuranceSupplierId',
        inputType: 'select',
        inputOptionsVar: 'insuranceSuppliers'
    },
    insurancePolicyNumber: {
        name: 'insurancePolicyNumber',
        inputType: 'number',
        valueType: 'string',
    },
    notes: {
        name: 'notes',
        inputType: 'textarea',
        linkedField: 'notes'
    },
    nextInspectionMileage: {
        name: 'nextInspectionMileage'
    },
    nextInspectionDate: {
        name: 'nextInspectionDate'
    },
    currentMileage: {
        name: 'currentMileage',
        inputType: 'number',
        valueType: 'number'
    },
    driverIds: {
        name: 'driverIds',
        arrayField: true,
        defaultValue: [],
        bodyComponent: VehicleAddEditAuthorizedDriversComponent
    },
    type: {
        name: 'type',
        inputType: 'select',
        inputOptionsVar: 'vehicleType'
    },
    manufacturer: {
        name: 'manufacturer',
        inputType: 'text'
    },
    manufactureDate: {
        name: 'manufactureDate',
        inputType: 'datepicker'
    },
    model: {
        name: 'model',
        inputType: 'text'
    },
    color: {
        name: 'color',
        inputType: 'text'
    },
    fuelType: {
        name: 'fuelType',
        inputType: 'select',
        inputOptionsVar: 'fuelType'
    },
    status: {
        name: 'status',
        inputType: 'select',
        defaultValue: 'vehicleStatus.active',
        inputOptionsVar: 'vehicleStatus'
    },
    vin: {
        name: 'vin',
        inputType: 'number',
        valueType: 'string'
    },
    engineNumber: {
        name: 'engineNumber',
        inputType: 'number',
        valueType: 'string'
    },
    engineVolume: {
        name: 'engineVolume',
        inputType: 'number',
        valueType: 'string'
    },
    tireSize: {
        name: 'tireSize',
        inputType: 'number',
        valueType: 'string'
    },
    documents: {
        name: 'documents',
        inputType: 'documents',
        config: vehicleAddEditDocumentsConfig,
        bodyComponent: DocumentsPageComponent
    },
    supplierIds: {
        name: 'supplierIds',
        defaultValue: [],
        arrayField: true,
        bodyComponent: VehicleAddEditSuppliersComponent
    },
    ...vehicleAddEditAccountingConfig.company.fields
};

fields.licenseNumber.config['formFields'] = fields;

const tabs = [
    {
        id: 'info',
        notNestedForm: true,
        title: `${dictionaryPath}.title`,
        columns: [
            [
                {
                    title: `${dictionaryPath}.generalDetails.title`,
                    fields: [
                        {
                            ...fields.licenseNumber,
                            title: `${dictionaryPath}.generalDetails.licenseNumber`,
                        },
                        {
                            ...fields.departmentId,
                            title: `${dictionaryPath}.generalDetails.department`
                        },
                        {
                            ...fields.branchId,
                            title: `${dictionaryPath}.generalDetails.associationToBranch`
                        },
                        {
                            ...fields.insuranceSupplierId,
                            title: `${dictionaryPath}.generalDetails.insuranceSupplierId`
                        },
                        {
                            ...fields.insurancePolicyNumber,
                            title: `${dictionaryPath}.generalDetails.insurancePolicyNumber`
                        },
                        {
                            ...fields.notes,
                            title: `${dictionaryPath}.generalDetails.notes`
                        }
                    ]
                }
            ],
            [
                {
                    title: `${dictionaryPath}.generalDetails.technicalInspection.title`,
                    fields: [
                        {
                            inputType: 'nextInspection',
                            placeholder: `${dictionaryPath}.generalDetails.technicalInspection.placeholder`,
                            title: `${dictionaryPath}.generalDetails.technicalInspection.nextInspection`
                        },
                        {
                            ...fields.currentMileage,
                            title: `${dictionaryPath}.generalDetails.technicalInspection.currentMileage`
                        }
                    ]
                },
                {
                    fields: [fields.driverIds]
                }
            ]
        ]
    },
    {
        id: 'vehicleDetails',
        notNestedForm: true,
        title: `${dictionaryPath}.vehicleDetails.title`,
        columns: [
            [
                {
                    title: `${dictionaryPath}.vehicleDetails.title`,
                    fields: [
                        {
                            ...fields.type,
                            title: `${dictionaryPath}.vehicleDetails.type`
                        },
                        {
                            ...fields.manufacturer,
                            title: `${dictionaryPath}.vehicleDetails.manufacturer`
                        },
                        {
                            ...fields.manufactureDate,
                            title: `${dictionaryPath}.vehicleDetails.manufactureDate`
                        },
                        {
                            ...fields.model,
                            title: `${dictionaryPath}.vehicleDetails.model`
                        },
                        {
                            ...fields.color,
                            title: `${dictionaryPath}.vehicleDetails.color`
                        },
                        {
                            ...fields.fuelType,
                            title: `${dictionaryPath}.vehicleDetails.fuelType`
                        },
                        {
                            ...fields.status,
                            title: `${dictionaryPath}.vehicleDetails.status`
                        }
                    ]
                }
            ],
            [
                {
                    title: `${dictionaryPath}.vehicleDetails.chassisTiresEngine.title`,
                    fields: [
                        {
                            ...fields.vin,
                            title: `${dictionaryPath}.vehicleDetails.chassisTiresEngine.vin`
                        },
                        {
                            ...fields.engineNumber,
                            title: `${dictionaryPath}.vehicleDetails.chassisTiresEngine.engineNumber`
                        },
                        {
                            ...fields.engineVolume,
                            title: `${dictionaryPath}.vehicleDetails.chassisTiresEngine.engineVolume`
                        },
                        {
                            ...fields.tireSize,
                            title: `${dictionaryPath}.vehicleDetails.chassisTiresEngine.tireSize`
                        }
                    ]
                }
            ]
        ]
    },
    {
        id: 'accounting',
        notNestedForm: true,
        title: `${dictionaryPath}.accounting.title`,
        columns: vehicleAddEditAccountingConfig.company.columns
    },
    {
        id: 'documents',
        notNestedForm: true,
        title: `${dictionaryPath}.documents.title`,
        columns: [
            [
                {
                    fields: [fields.documents]
                }
            ]
        ]
    },
    {
        id: 'suppliers',
        notNestedForm: true,
        title: `${dictionaryPath}.suppliers.title`,
        columns: [
            [
                {
                    fields: [fields.supplierIds]
                }
            ]
        ]
    }
];

const editTabs = [
    {
        id: 'inspections',
        title: `${dictionaryPath}.inspections.title`,
        columns: [
            [
                {
                    fields: [
                        {
                            name: 'inspections',
                            bodyComponent: TableElementComponent,
                            config: inspectionsPageConfig
                        }
                    ]
                }
            ]
        ]
    },
    // {
    //     id: 'expenses',
    //     title: `${dictionaryPath}.expenses.title`,
    //     columns: [
    //         [
    //             {
    //                 fields: [
    //                     {
    //                         bodyComponent: TableElementComponent,
    //                         config: expensesPageConfig
    //                     }
    //                 ]
    //             }
    //         ]
    //     ]
    // },
    {
        id: 'events',
        title: `${dictionaryPath}.events.title`,
        columns: [
            [
                {
                    fields: [
                        {
                            config: eventsPageConfig,
                            bodyComponent: TableElementComponent
                        }
                    ]
                }
            ]
        ]
    },
    {
        id: 'history',
        title: `${dictionaryPath}.history.title`,
        columns: [
            [{
                fields: [
                    {
                        bodyComponent: HistoryTableComponent
                    }
                ]
            }]
        ]
    }
]

const vehicleAddEditModalConfig = {
    fields,
    tabs,
    editTabs: [...tabs, ...editTabs],
    hiddenFields: {id: fields.id, nextInspectionMileage: fields.nextInspectionMileage, nextInspectionDate: fields.nextInspectionDate},
    title: 'vehicles.addEditVehicle.title',
    deleteLabel: 'vehicles.addEditVehicle.deleteVehicle',
    metadataItemsKeys: ['branches', 'departments', 'drivers', 'inspections', 'events'],
    categoriesToFetch: ['vehicleType', 'fuelType', 'vehicleStatus', 'vehicleOwnershipType'],
    itemsToFetch: [
        {
            basePath: 'departments', fetchItems: [{name: 'departments'}]
        },
        {
            basePath: 'branches', fetchItems: [{name: 'branches'}]
        },
        {
            basePath: 'suppliers',
            fetchItems: [{name: 'suppliers'}, {name: 'insuranceSuppliers', types: ['supplierType.insurance']}]
        }
    ]
};

export default vehicleAddEditModalConfig;
