import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import 'rxjs/add/operator/debounceTime';
import inputsConfig from '../inputs.config';
import { ValidationService } from '../../../shared/services';
import { CtrlFormsService } from '../../../shared/services/ctrl-forms.service';

@Component({
    selector: 'address-input',
    templateUrl: './address-input.component.html',
    styleUrls: ['./address-input.component.scss']
})
export class AddressInputComponent implements OnInit {
    @Input() public fieldName: string;
    @Input() public formObject: FormGroup;

    fullAddressFormControl: FormControl;

    constructor(private validationService: ValidationService, private ctrlFormsService: CtrlFormsService) { }

    ngOnInit(): void {
        this.fullAddressFormControl = new FormControl('');
        if (this.getAddressForm().value !== null) {
            this.setFullAddressFormControl();
        }
    }

    setFullAddressFormControl(): void {
        if (this.getFullAddressFormControl()) {
            this.validateAddressForm();
        }
    }

    validateAddressForm(): void {
        this.getFullAddressFormControl().setValidators(this.validationService.checkAddressLatitudeLongitude.bind(this, this.getAddressForm().get('longitude'), this.getAddressForm().get('longitude')));
    }

    getFullAddressFormControl(): FormControl {
        return <FormControl>this.getAddressForm().get('fullAddress');
    }

    getAddressForm(): FormGroup {
        return <FormGroup>this.formObject.get(this.fieldName);
    }

    onGAutocomplete({name, place}): void {
        if (this.getAddressForm().value === null) {
            this.generateAddressFormControls();
        }

        this.getAddressForm().patchValue({
            fullAddress: name,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng()
        });

        this.formObject.markAsDirty();
        this.getAddressForm().updateValueAndValidity();
        this.getAddressForm().setErrors(null);
    }

    generateAddressFormControls(): void {
        const emptyAddressForm = this.ctrlFormsService.generateFieldsForm(inputsConfig.address.fields);
        this.formObject.setControl(this.fieldName, emptyAddressForm);
    }
}
