import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import apiServiceConfig from './api.service.config';

@Injectable()
export class BaseService {
    private paths: any;

    constructor(private apiService: ApiService) {
    }

    setPaths(pageName): void {
        this.paths = apiServiceConfig.paths[pageName];
    }

    getAll(): Observable<any> {
        return this.apiService.get(this.paths.basePath + this.paths.getAll);
    }

    getAllSlim(params?: {}, basePath?: string): Observable<any> {
        const paths = basePath ? apiServiceConfig.paths[basePath] : this.paths;
        return this.apiService.get(paths.basePath + paths.getAllSlim, params);
    }

    getAllSlimByTypes(types?: string[], basePath?: string): Observable<any> {
        const paths = basePath ? apiServiceConfig.paths[basePath] : this.paths;
        return this.apiService.get(paths.basePath + paths.getAllSlim, types && { types });
    }

    getItems(itemsPath: string) {
        return this.apiService.get(this.paths.basePath + this.paths[itemsPath]);
    }

    create(body: object): Observable<any> {
        return this.apiService.post(this.paths.basePath + this.paths.create, body);
    }

    update(body: object): Observable<any> {
        return this.apiService.put(this.paths.basePath + this.paths.update, body);
    }

    delete(id: string): Observable<any> {
        return this.apiService.delete(this.paths.basePath + this.paths.delete, { id });
    }

    byId(id: number): Observable<any> {
        return this.apiService.get(`${this.paths.basePath}${this.paths.byId}`, { id });
    }

    getHistory(id: number): Observable<any> {
        return this.apiService.get(`${this.paths.basePath}${this.paths.history}`, { id });
    }

    uploadFile(file: File, fileType?: 'documents' | 'images'): Observable<any> {
        const fileForm = new FormData();
        fileForm.append('file', file, file.name);

        return this.apiService.postFormData(`${this.paths.basePath}${this.paths[fileType]}`, fileForm);
    }

    downloadFile(fileId: string, fileType?: 'documents' | 'images'): Observable<any> {
        return this.apiService.getBlob(`${this.paths.basePath}${fileType ? this.paths[fileType] : this.paths.attachment}`, {fileId});
    }

    downloadThumbnail(fileId: string): Observable<any> {
        return this.apiService.getBlob(`${this.paths.basePath}${this.paths.thumbnail}`, {fileId});
    }

    getDocumentTypes(): Observable<any> {
        return this.apiService.get(`${this.paths.basePath}${this.paths.documentTypes}`);
    }

    findByIdentity(identity: string, idParameter?: 'string'): Observable<any> {
        const params = {};
        params[idParameter || 'identity'] = identity;

        return this.apiService.get(`${this.paths.basePath}${this.paths.findByIdentity}`, params);
    }

    patchProperty(propertyKey: string, body: Object): Observable<any> {
        return this.apiService.patch(this.paths.basePath + this.paths[propertyKey], body);
    }
}
