import { Pipe, PipeTransform } from '@angular/core';
import durationConfig from './duration.config';
import { TranslateService } from '@ngx-translate/core';
import appConfig from 'src/app/app.config';

@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {

    constructor(private translate: TranslateService) {
    }

    transform(value: number, durationUnit: string, currentLanguage: string): string {
        if (value) {
            let text = '';
            const valueType = value === 1 ? 'singleValue' : 'pluralValue';

            this.translate.get(durationConfig[durationUnit][valueType]).subscribe((translatedText: string) =>
                text = translatedText
            );

            return currentLanguage === appConfig.languages.hebrew.code && valueType === 'singleValue' ? text : `${value} ${text}`;
        }
        return null;
    }
}
