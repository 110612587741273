import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';

@Injectable()
export class LanguagesActions {
    static readonly LANGUAGES_INIT = 'LANGUAGES_INIT';

    @dispatch()
    languagesInit = (payload: any) => ({
        type: LanguagesActions.LANGUAGES_INIT,
        ...payload
    })
}
